import React, { Component } from 'react';
import styles from './RadioButton.module.css';


class TopAnimationAndVideo extends Component {


    constructor() {
        super();
        this.state = {
            Dashbord: false,

        };
    }






    render() {




        const aa = this.props.ShowCommnet ? styles.errBorder : null


        return (


            <>


                <div className={styles.container + ' ' + this.props.className}>
                    <input type="radio" value={this.props.value} checked={this.props.checked} name="approve" />
                    <span className={styles.RadioOnSpan} onClick={this.props.onChange} class={styles.checkmark + ' ' + aa}></span>
                </div>


            </>
        );


    }
}

export default TopAnimationAndVideo;
