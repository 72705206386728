import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import styles from './CarDataSideBySideSearchs.module.css';

import { withRouter } from 'react-router-dom';

import Select from 'react-styled-select';

@inject('cars', 'singleCar', 'singleCar2')
@observer // REMOVE BRACKETS => @observer
class CarDataSideBySideSearchs extends Component {




    componentDidMount() {

    }




    render() {

        const SelectManufacturer = <div className={styles.SelectManufacturer + ' sidebyside'}>
            <Select
                searchable={false}
                options={this.props.ManufacturerList}
                value={this.props.ManufacturerValue}
                onChange={this.props.handleManufacturerChange}
                className={styles.SelectTeam}
                placeholder="בחר יצרן"
            />


            <Select
                searchable={false}
                options={this.props.CarsList}
                value={this.props.CarValue}
                onChange={this.props.handleCarChange}
                className={styles.SelectTeam}
                placeholder="בחר דגם"
            />
        </div>




        return (


            <>


                <div className={styles.GlleryTitleMedolContainer}>

                    <div className={styles.TitleContainer}>
                        <div className={styles.CarTitle}>חפש רכב להשוואה</div>
                    </div>

                    <div className={styles.SelectArea}>
                        {this.props.ShowSelect ? SelectManufacturer : null}

                    </div>
                </div>










            </>
        );


    }
}

export default withRouter(CarDataSideBySideSearchs);
