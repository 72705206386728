import React, { Component } from 'react';

import styles from './HeaderAdmin.module.css';
import './HeaderAdmin.css';

import { slide as Menu } from 'react-burger-menu'

import { inject, observer } from 'mobx-react';
import Slider from 'react-input-slider';

import { withRouter, Link } from 'react-router-dom';
import { TiArrowBack } from 'react-icons/ti';

import Select from 'react-styled-select';
import { BiMenu, BiLogOut } from "react-icons/bi";
import { ImUserPlus, ImUsers } from "react-icons/im";
import { IoMdAddCircle, IoMdRemoveCircle, IoIosListBox } from "react-icons/io";
import { RiArrowGoBackFill } from "react-icons/ri";



@inject("siteLinks", "adminPanel")
class HeaderAdmin extends Component {
    constructor() {
        super();
        this.state = {
            Image: '',
            selectedOption: null
        };
    }






    render() {


        return (

            <>


                <div className={styles.FlexContainer}>
                    <Menu width={'350px'} className={styles.Menu} customBurgerIcon={<BiMenu className={styles.BiMenu} />} left  >

                        <Link
                            to="/admin-panel?tab=car-requests"
                            className={this.props.adminPanel.ActiveCarRequests ? styles.AdminHeaderLinkActive : styles.AdminHeaderLink}>
                            טבלת הזמנות
                            <IoIosListBox style={{ top: '5px', position: "relative", marginLeft: '10px' }} />
                        </Link>
                        <div className={styles.divder}></div>
                        <Link
                            to="/admin-panel?tab=users"
                            className={this.props.adminPanel.ActiveUsers ? styles.AdminHeaderLinkActive : styles.AdminHeaderLink}>
                            טבלת משתמשים
                            <ImUsers style={{ top: '5px', position: "relative", marginLeft: '10px' }} />
                        </Link>
                        <div className={styles.divder}></div>
                        <Link
                            to="/admin-panel?tab=add-user"
                            className={this.props.adminPanel.ActiveAddUser ? styles.AdminHeaderLinkActive : styles.AdminHeaderLink}>
                            הוספת משתמש
                            <ImUserPlus style={{ top: '5px', position: "relative", marginLeft: '10px' }} />
                        </Link>
                        <div className={styles.divder}></div>
                        <Link
                            to="/admin-panel?tab=add-users"
                            className={this.props.adminPanel.ActiveAddUsers ? styles.AdminHeaderLinkActive : styles.AdminHeaderLink}>
                            הוספת משתמשים מקובץ
                            <IoMdAddCircle style={{ top: '5px', position: "relative", marginLeft: '10px' }} />
                        </Link>
                        <div className={styles.divder}></div>
                        <Link
                            to="/admin-panel?tab=del-users"
                            className={this.props.adminPanel.ActiveDelUsers ? styles.AdminHeaderLinkActive : styles.AdminHeaderLink}>
                            מחיקת משתמשים מקובץ
                            <IoMdRemoveCircle style={{ top: '5px', position: "relative", marginLeft: '10px' }} />
                        </Link>
                        <div className={styles.divder}></div>
                        <Link
                            to="/"
                            className={styles.AdminHeaderLink}>
                            חזרה לאתר
                            <RiArrowGoBackFill style={{ top: '5px', position: "relative", marginLeft: '10px' }} />
                        </Link>
                        <div className={styles.divder}></div>
                        <div onClick={this.props.Logout} className={styles.AdminHeaderLink}>
                            התנתק
                            <BiLogOut style={{ top: '5px', position: "relative", marginLeft: '10px' }} />
                        </div>

                    </Menu>
                

                    <div className={styles.Container}>

                        {/* <div className={styles.RightLogo}> <Link to={this.props.MainLink}><img src={this.props.siteLinks.CarShowLogo} /></Link></div> */}


                        <div className={styles.LeftContainer}>

                        </div>

                        <div className={styles.CenterContainer} >

                            <div className={styles.AdminHeaderLinks}>


                            </div>

                        </div>
                        <div className={styles.RightContainer}> <Link to={this.props.MainLink}><img src={this.props.siteLinks.RafaelHebBlue} /></Link></div>



                    </div>


                </div>

            </>
        );


    }
}

export default withRouter(HeaderAdmin);
