import React, { Component } from 'react';
import { Parallax, Background } from 'react-parallax';

import styles from './MainScreenFirstText.module.css';


class MainScreenFirstText extends Component {


    constructor() {
        super();
        this.state = {
            Dashbord: false,

        };
    }






    render() {







        return (


            <>


                <Parallax
                    blur={0}
                    bgImage={this.props.bgImage}
                    bgImageAlt="the cat"
                    strength={400}
                >
                    <div className={styles.FlexContainer} >
                        <div className={styles.FirstText}>
                            <div className={styles.Title} >{this.props.Title}</div>
                            <div className={styles.BodyText} >{this.props.BodyText}</div>
                        </div>
                    </div>

                </Parallax>


            </>
        );


    }
}

export default MainScreenFirstText;
