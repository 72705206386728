import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';


// import Select from 'react-select';

import styles from './AddUser.module.css';
import './AddUser.css';

import { IoIosAddCircle } from 'react-icons/io';

import { TextField, styled, Checkbox, Button, Select, MenuItem, InputLabel } from '@material-ui/core';


import loadingBlue from '../../images/loading_blue.gif'



@inject("adminPanel", "siteLinks")
@observer
class CarRequests2021 extends Component {


    constructor() {
        super();
        this.state = {

            IdNumber: '',
            SiteRelated: '',
            ShowPanel: '0',

            ShowPanelCheck: false,
            Err: ''
        };
    }

    IdTextChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    SelcetChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    CheckboxChange = () => {
        if (this.state.ShowPanel == '1') {
            this.setState({ ShowPanel: '0', ShowPanelCheck: false, ActiveBtUpdate: true })
        }
        if (this.state.ShowPanel == '0') {
            this.setState({ ShowPanel: '1', ShowPanelCheck: true, ActiveBtUpdate: true })
        }
    }

    Submit = () => {
        const IdNumber = this.state.IdNumber
        const SiteRelated = this.state.SiteRelated
        const ShowPanel = this.state.ShowPanel
        if (IdNumber == '') {
            this.props.adminPanel.UpdateErr('נא למלא תעודת זהות')
        }
        else if (SiteRelated == '') {
            this.props.adminPanel.UpdateErr('נא לשייך אתר')
        }
        else {
            this.props.adminPanel.UpdateErr('')
            this.props.adminPanel.AddUser(IdNumber, SiteRelated, ShowPanel)
        }
    }

    AddMore = () => {
        this.props.adminPanel.ChangeApproveAddUser()
        this.setState({ IdNumber: '', SiteRelated: '', ShowPanel: '0', ShowPanelCheck: false })
    }

    render() {

        const SiteRelated = this.state.SiteRelated

        const AddUser = <>
            <div className={styles.Title}>הוספת משתמש חדש</div>
            <TextField
                type="number"
                autoFocus={true}
                label="מספר תעודת זהות"
                variant="outlined"
                className={styles.TextField + ' textField'}
                onChange={this.IdTextChange.bind(this)}
                value={this.state.IdNumber}
                name='IdNumber'
            />

            <div className={styles.site}>
                <InputLabel id="label">שיוך לאתר</InputLabel>
                <Select name='SiteRelated' onChange={this.SelcetChange.bind(this)} variant="outlined" labelId="label" id="select" value={SiteRelated}>
                    <MenuItem value="leassite">ליסינג עובדים</MenuItem>
                    <MenuItem value="tzamotsite">רכבים צמודים</MenuItem>
                    <MenuItem value="managers">סמנכ"לים בכירים וחברי הנהלה</MenuItem>
                    <MenuItem value="management">מנהלים בכירים</MenuItem>
                </Select>
            </div>

            <div className={styles.admin}>
                <div className={styles.TitleSelect}>הצג פאנל ניהול</div>
                <Checkbox
                    value="0"
                    onChange={this.CheckboxChange.bind(this)}
                    checked={this.state.ShowPanelCheck}
                    className={styles.Checkbox}
                />
            </div>

            <div onClick={this.Submit} className={styles.AddUser}>הוסף משתמש</div>
            <div className={styles.Err}>{this.props.adminPanel.Err}</div></>

        const Approve = <>
            <div className={styles.TitleApprove}>משתמש הוסף בהצלחה</div>
            <div className={styles.Title2}>הוסף משתמש נוסף</div>
            <div className={styles.IconAddContainer}><IoIosAddCircle className={styles.IconAdd} onClick={this.AddMore.bind(this)} /></div>
        </>

        return (

            <>


                <div className={styles.FlexContainer}>

                    {this.props.adminPanel.loading ? <img className="loader" src={loadingBlue} alt="Loader" /> :

                        (
                            <>
                                {this.props.adminPanel.ApproveAddUser ? Approve : AddUser}
                            </>
                        )

                    }
                </div>

            </>
        );


    }
}

export default CarRequests2021;
