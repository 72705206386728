import React, { Component } from 'react';
import { inject, observer, computed } from 'mobx-react';

import styles from './CarData.module.css';

import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import { toJS, transaction } from 'mobx';
import { HiCheck } from 'react-icons/hi';
import { AiOutlineClose, AiFillStar } from 'react-icons/ai';
import ImageGallery from 'react-image-gallery';
import Acordion from '../components/Acordion';
import AcordionItem from '../components/AcordionItem';
import AcordionItemB from '../components/AcordionItemB';
import HeaderInside from '../components/HeaderInside';
import Footer from '../components/Footer';
import PollutionSider from '../components/PollutionSider';
import SaftySider from '../components/SaftySider';
import CarDataLoader from '../components/CarDataLoader'
import { useMediaQuery, MediaQuery } from 'react-responsive'

import Test from '../components/test'




@inject('login', 'cars', 'singleCar', 'siteGlobal')
@observer // REMOVE BRACKETS => @observer
class CarData extends Component {

    constructor() {
        super();
        this.state = {
            showIndex: false,
            showBullets: true,
            infinite: true,
            showThumbnails: false,
            showFullscreenButton: true,
            showGalleryFullscreenButton: true,
            showPlayButton: false,
            showGalleryPlayButton: false,
            showNav: true,
            isRTL: false,
            slideDuration: 450,
            slideInterval: 2000,
            slideOnThumbnailOver: false,
            thumbnailPosition: 'bottom',
            showVideo: {},
            isMobile: window.innerWidth < 1101


        };
    }



    updateIsMobile = () => {
        this.setState({
            isMobile: window.innerWidth < 1101
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile);
        const CarId = this.props.CarId
        this.props.singleCar.UpdateCar1(CarId)
        this.props.siteGlobal.ToolTips()
        this.GalleryRender
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }





    // resetVideo() {
    //     this.props.singleCar.resetVideo()
    // }





    onSlide() {

        this.resetVideo()
    }

    resetVideo() {
        this.props.singleCar.TirggerRender()
        this.props.singleCar.resetVideo()
    }

    toggleShowVideo(url) {

        this.props.singleCar.toggleShowVideo(url)
    }



    toggleShowVideo(url) {
        this.showVideo[url] = !Boolean(this.showVideo[url]);
        this.showVideo = this.showVideo

        if (this.showVideo[url]) {
            if (this.showPlayButton) {
                this.showGalleryPlayButton = false
            }

            if (this.showFullscreenButton) {
                this.showGalleryFullscreenButton = false
            }
        }
    }





    render() {
        let taxText = ''
        if (this.props.singleCar.IsHybrid) {
            taxText = <div className={styles.texText}>לאחר הטבת מס</div>
        }

        const Title = <div className={styles.TitleContainer}>
            <div className={styles.CarTitle}>{parse(this.props.singleCar.Car1Title)}</div>
            <div>{this.props.singleCar.TorenderVideo}</div>
            <div className={styles.UnderCarTitle}>{this.props.singleCar.Car1SmallText}</div>
            <div className={styles.Divider}></div>
            <div className={styles.PriceTitle}>שווי שימוש רציף</div>
            {taxText}
            <div className={styles.BigPrice}><span>₪</span>{this.props.singleCar.CarPrice}</div>
        </div>

        // const Price = <div className={styles.PriceContainer}>
        //     <div className={styles.PriceTitle}>שווי שימוש רציף</div>
        //     <div className={styles.BigPrice}>{this.props.singleCar.CarPrice + ' ₪'}</div>
        // </div>
        const Gallery =
            <div className={styles.GallryContainer}>
                <div className={styles.GalleryComment}>התמונות והסירטונים המוצגים הינם ממגוון רמות הגימור של הדגם ולהמחשה בלבד</div>
                <ImageGallery
                    ref={i => this._imageGallery = i}
                    onSlide={this.onSlide.bind(this)}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    items={[...toJS(this.props.singleCar.Car1Gallery)]}
                    infinite={false}
                    useBrowserFullscreen={false}
                    useTranslate3D={true}
                    showBullets={true}
                    // isRTL={true}
                    showThumbnails={false}
                // thumbnailPosition={"left"}
                />
            </div>



        const Car1Model = this.props.singleCar.Car1Model.map((car, index) => {
            if (car.Title == 'שווי שימוש רציף')
                return null


            if (this.props.login.siteRealation == 'tzamotsite') {
                if (car.Title == 'supply')
                    if (car.Value == '') {
                        return null
                    }
                    else return <div className={styles.supply}>{car.Value}</div>
            }
            if (this.props.login.siteRealation == 'managers') {
                if (car.Title == 'supply')
                    if (car.Value == '') {
                        return null
                    }
                    else return <div className={styles.supply}>{car.Value}</div>
            }
            if (this.props.login.siteRealation == 'management') {
                if (car.Title == 'supply')
                    if (car.Value == '') {
                        return null
                    }
                    else return <div className={styles.supply}>{car.Value}</div>
            }
            if (this.props.login.siteRealation == 'leassite') {
                if (car.Title == 'supply')
                    return null
            }


            return <AcordionItem dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Engine = this.props.singleCar.Car1Engine.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemB Title={car.Title} ValueAsIcon={true} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemB Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            if (car.Title == 'דרגת זיהום')
                return <AcordionItemB Title={car.Title} Value={<PollutionSider Degree={car.Value} />} />
            return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Safty = this.props.singleCar.Car1Safty.map((car, index) => {
            if (car.Title == 'ציון אבזור בטיחות משרד התחבורה')
                return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={<SaftySider Degree={car.Value} />} />
            if (car.Value == 'יש')
                return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            if (car.Value == '3stars')
                return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={<div className={styles.Starts}> <AiFillStar /><AiFillStar /><AiFillStar /></div>} />
            if (car.Value == '4stars')
                return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={<div className={styles.Starts}> <AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></div>} />
            if (car.Value == '5stars')
                return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={<div className={styles.Starts}> <AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /> </div>} />
            return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Size = this.props.singleCar.Car1Size.map((car, index) => {
            return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Multimedia = this.props.singleCar.Car1Multimedia.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Comfort = this.props.singleCar.Car1Comfort.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} ValueAsIcon={true} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={car.Value} />
        }

        );

        const Car1Hybrid = this.props.singleCar.Car1Hybrid.map((car, index) => {
            if (car.Title == 'link')
                return <div className={styles.LinkFlexContainer}><div className={styles.LinkContainer}>
                    <div className={styles.LinkText}>{car.Value}</div>
                    <a href="https://www.gov.il/he/departments/guides/radiation_of_electrical_frequency?chapterIndex=2" target="_blank">
                        לחצו כאן
                    </a>
                </div>
                </div>
            return <AcordionItemB dataTip={this.props.siteGlobal.getObs(car.Title)} Title={car.Title} Value={car.Value} />
        }

        );

        if (this.props.singleCar.FatchEnd) {
            this.props.singleCar.UpdateFadeIn()
        }
        let CarDataFadeIn = {
            opacity: this.props.singleCar.FadeIn,
            transition: '1s',
            display: 'flex',
            alignItems: 'center',
            jusitfyContent: 'center',
            flexDirection: 'column'

        }






        // const Desktop = ({ children }) => {
        //     const isDesktop = useMediaQuery({ minWidth: 1100 })
        //     return isDesktop ? children : null
        // }

        // const Mobile = ({ children }) => {
        //     const isDesktop = useMediaQuery({ maxWidth: 1101 })
        //     return isDesktop ? children : null
        // }


        const desktop =

            <div className={styles.GlleryTitleMedolContainer + ' ' + styles.DesktopView}>

                <div className={styles.TitleAndModelContainer}>
                    {Title}

                    <div className={styles.ModelContainer + ' ModelContainer'}>
                        <Acordion Title="דגם" open={true} className={styles.ModelAcordion} triggerDisabled={true} >
                            {Car1Model}
                        </Acordion>
                    </div>
                </div>

                {Gallery}

            </div>


        const mobile =
            <div className={styles.GlleryTitleMedolContainer + ' ' + styles.MobileView}>
                {Title}

                {Gallery}
                <div className={styles.ModelContainer + ' ModelContainer'}>
                    <Acordion Title="דגם" open={true} className={styles.ModelAcordion} triggerDisabled={false} >
                        {Car1Model}
                    </Acordion>
                </div>
            </div>







        const Cardata = <div style={CarDataFadeIn}>

            {this.state.isMobile ? mobile : desktop}
            <div className={styles.ItemsContainer + ' EnginContainer'}>
                <Acordion Title="מנוע וביצועים" open={true} >
                    {Car1Engine}
                </Acordion>
            </div>



            <div className={styles.ItemsContainer + ' SaftyContainer'}>
                <Acordion Title="בטיחות רכב" open={true} >

                    {Car1Safty}
                </Acordion>
            </div>



            <div className={styles.ItemsContainer + ' SizesContainer'}>
                <Acordion Title="מידות" open={true} >

                    {Car1Size}

                </Acordion>
            </div>


            <div className={styles.ItemsContainer + ' MultimediaContainer'}>
                <Acordion Title="מולטימדיה ותקשרות" open={true} >

                    {Car1Multimedia}

                </Acordion>
            </div>


            <div className={styles.ItemsContainer + ' ComfortContainer'}>
                <Acordion Title="אבזור ונוחות" open={true} >
                    {Car1Comfort}

                </Acordion>
            </div>

            <div className={styles.ItemsContainer + ' ComfortContainer'}>
                <Acordion Title="אבזור נוסף כלול במחיר" open={true} >
                    <div className={styles.MoreInfo}>
                        <div className={styles.More}> {this.props.singleCar.Car1More}</div>
                    </div>
                </Acordion>
            </div>

            {this.props.singleCar.IsHybrid ?
                <div className={styles.ItemsContainer + ' HybridContainer'}>
                    <Acordion Title="היברידי" open={true} >
                        {Car1Hybrid}
                    </Acordion>
                </div>
                : null
            }


        </div>



        let datatoShow = <CarDataLoader />
        if (this.props.siteGlobal.loading == false && this.props.singleCar.loading == false) {
            datatoShow = Cardata
        }



        return (


            <>

                {datatoShow}
                {/* {this.props.singleCar.loading ? <CarDataLoader /> : Cardata} */}

            </>

        );


    }
}

export default withRouter(CarData);
