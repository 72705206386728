import React, { Component } from 'react';


import styles from './AcordionLoader.module.css';

import Collapsible from 'react-collapsible';


class Acordion extends Component {




    render() {


        return (

            <div className={this.props.className}>
                <Collapsible open={true} trigger='loader'>
                    {this.props.children}
                </Collapsible>
            </div>
        );


    }
}

export default Acordion;
