import React, { Component } from 'react';

import styles from './ButtonItem.module.css';



class FilterByCar extends Component {
    constructor() {
        super();
        this.state = {
            Image: '',

        };
    }


    componentDidMount() {
        this.setState({ Image: this.props.Image })
    }


    MouseEnter = () => {
        this.setState({ Image: this.props.HoverImage })
    }

    ChangePicBack = () => {
        this.setState({ Image: this.props.Image })
    }


    onClick = (event) => {
        this.setState({ Active: true })
    }

    render() {
        let SrparatorRight = ''
        this.props.SrparatorRight ? SrparatorRight = '' : SrparatorRight = 'none'

        let SrparatorLeft = ''
        this.props.SrparatorLeft ? SrparatorLeft = '' : SrparatorLeft = 'none'


        return (

            <>
                <div className={styles.SingleButtonConatiner}>
                    <div className={styles.Seperator} style={{ display: SrparatorRight }}></div>
                    <div onClick={this.props.FilterClick} onMouseEnter={this.MouseEnter} onMouseLeave={this.ChangePicBack} className={styles.ButtonItem} style={{ paddingLeft: this.props.PaddingLeft }}>
                        <img src={this.props.Active ? this.props.ActiveImage : this.state.Image} />
                    </div>
                    <div className={styles.Seperator + ' ' + this.props.SparatorLeftclassName} style={{ display: SrparatorLeft }}></div>
                </div>
            </>
        );


    }
}

export default FilterByCar;
