import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class AdminLoginCheck extends Component {

    render() {

     

        // console.log(this.props.PageRelation)
        return (
            <>
                {this.props.IsAdmin ?
                    this.props.children
                    :
                    <Redirect push to={`/`} />
                }
            </>
        );


    }
}

export default AdminLoginCheck;
