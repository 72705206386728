import React from 'react';
import { observable, configure, runInAction, action, toJS } from 'mobx';
import { Redirect } from 'react-router-dom';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import axios from 'axios';
import clientConfig from '../client-config';
import parse from 'html-react-parser';
import LogInScreen from '../Screens/LogInScreen';

configure({ enforceActions: true });


class cars {
    @observable loading = false
    @observable FatchEnd = false

    @observable FadeIn = '0'
    @observable FadeOut = '1'

    @observable AllCars1 = []
    @observable AllCars2 = []

    @observable CarsToShow1 = []
    @observable CarsToShow2 = []

    @observable CarsToShowCopy1 = []
    @observable CarsToShowCopy2 = []

    @observable selectedOption1 = null
    @observable selectedOption2 = null

    @observable ManufacturersToShow1 = []
    @observable ManufacturersToShow2 = []

    @observable SideBySideSelected1 = false
    @observable SideBySideSelected2 = false

    @observable CarselectedOption1 = null
    @observable CarselectedOption2 = null

    @observable ListOfCarsToShow1 = []
    @observable ListOfCarsToShow2 = []

    @observable ListOf_All_CarsToShow1 = []
    @observable ListOf_All_CarsToShow2 = []


    @observable WordpressId = '20'

    @observable FirstTimeSelectedOption = true
    @observable selectedCarType = 'all'

    @observable all = true
    @observable jeeponim = false
    @observable family = false
    @observable small = false
    @observable big = false
    @observable hybrid = false

    @observable SliderX = 0

    @observable SelectesValu2 = false

    @observable Car1id = '0'
    @observable Car2id = '0'






    // Filter יצרן be From Avilable Cars
    @action
    FilterManufacturers1() {
        const Manufacturers = [];

        this.CarsToShow1.map((car) =>
            Manufacturers.push(
                car.metadata.manufacturer[0]
            ));
        // remove Same Value Items
        const uniqueSet = new Set(Manufacturers)
        const backToArray = [...uniqueSet];
        let Options = [];
        // console.log(backToArray + ' :יצרנים לסינון')
        backToArray.map((item) => {
            if (item == "toyota") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'טויוטה';
                obj['order'] = '1';
                Options.push(obj);

            };
            if (item == "hyundai") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'יונדאי';
                obj['order'] = '2';
                Options.push(obj);

            };
            if (item == "mazda") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = "מאזדה";
                obj['order'] = '3';
                Options.push(obj);

            };
            if (item == "mitsubishi") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'מיצובישי';
                obj['order'] = '4';
                Options.push(obj);

            };
            if (item == "nissan") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'ניסאן';
                obj['order'] = '5';
                Options.push(obj);

            };
            if (item == "seat") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סיאט';
                obj['order'] = '6';
                Options.push(obj);
            };
            if (item == "citroen") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סיטרואן';
                obj['order'] = '7';
                Options.push(obj);
            };
            if (item == "skoda") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סקודה';
                obj['order'] = '8';
                Options.push(obj);

            };
            if (item == "volkswagen") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'פולקסווגן';
                obj['order'] = '9';
                Options.push(obj);

            };
            if (item == "ford") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'פורד';
                obj['order'] = '10';
                Options.push(obj);

            };
            if (item == "peugeot") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = "פיג'ו";
                obj['order'] = '11';
                Options.push(obj);

            };
            if (item == "kia") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'קיה';
                obj['order'] = '12';
                Options.push(obj);

            };

            if (item == "renault") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'רנו';
                obj['order'] = '13';
                Options.push(obj);

            };
            this.ManufacturersToShow1 = Options
        },
        );
        const CopyToSort = toJS(this.ManufacturersToShow1)
        CopyToSort.sort((a, b) => a.order - b.order);
        this.ManufacturersToShow1 = CopyToSort
    }

    // Filter יצרן be From Avilable Cars
    @action
    FilterManufacturers2() {
        const Manufacturers = [];

        this.CarsToShow2.map((car) =>
            Manufacturers.push(
                car.metadata.manufacturer[0]
            ));
        // remove Same Value Items
        const uniqueSet = new Set(Manufacturers)
        const backToArray = [...uniqueSet];
        let Options = [];
        // console.log(backToArray + ' :יצרנים לסינון')
        backToArray.map((item) => {
            if (item == "toyota") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'טויוטה';
                obj['order'] = '1';
                Options.push(obj);

            };
            if (item == "hyundai") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'יונדאי';
                obj['order'] = '2';
                Options.push(obj);

            };
            if (item == "mazda") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = "מאזדה";
                obj['order'] = '3';
                Options.push(obj);

            };
            if (item == "mitsubishi") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'מיצובישי';
                obj['order'] = '4';
                Options.push(obj);

            };
            if (item == "nissan") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'ניסאן';
                obj['order'] = '5';
                Options.push(obj);

            };
            if (item == "seat") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סיאט';
                obj['order'] = '6';
                Options.push(obj);
            };
            if (item == "citroen") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סיטרואן';
                obj['order'] = '7';
                Options.push(obj);
            };
            if (item == "skoda") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סקודה';
                obj['order'] = '8';
                Options.push(obj);

            };
            if (item == "volkswagen") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'פולקסווגן';
                obj['order'] = '9';
                Options.push(obj);

            };
            if (item == "ford") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'פורד';
                obj['order'] = '10';
                Options.push(obj);

            };
            if (item == "peugeot") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = "פיג'ו";
                obj['order'] = '11';
                Options.push(obj);

            };
            if (item == "kia") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'קיה';
                obj['order'] = '12';
                Options.push(obj);

            };

            if (item == "renault") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'רנו';
                obj['order'] = '13';
                Options.push(obj);

            };
            this.ManufacturersToShow1 = Options
            this.ManufacturersToShow2 = Options
        },
        );
        const CopyToSort = toJS(this.ManufacturersToShow2)
        CopyToSort.sort((a, b) => a.order - b.order);
        this.ManufacturersToShow2 = CopyToSort
    }


    @action
    SideBySideCarsUpdate1 = selectedOption1 => {
        const CarsNames = [];
        this.CarsToShow1.map((car) => {
            let obj = {};
            obj['value'] = car.id;
            obj['label'] = parse(car.title.rendered);
            obj['manufacturer'] = car.metadata.manufacturer[0];
            CarsNames.push(obj)
        }
        );
        this.ListOfCarsToShow1 = CarsNames
        this.ListOf_All_CarsToShow1 = CarsNames
    };


    @action
    SideBySideCarsUpdate2 = selectedOption1 => {
        const CarsNames = [];
        this.CarsToShow2.map((car) => {
            let obj = {};
            obj['value'] = car.id;
            obj['label'] = parse(car.title.rendered);
            obj['manufacturer'] = car.metadata.manufacturer[0];
            CarsNames.push(obj)
        }
        );
        this.ListOfCarsToShow2 = CarsNames
        this.ListOf_All_CarsToShow2 = CarsNames
    };


    @action
    SideBySidehandleManufacturerChange1 = selectedOption1 => {
        this.selectedOption1 = selectedOption1
        this.ListOfCarsToShow1 = this.ListOf_All_CarsToShow1
        const FilterByManufacturer = this.ListOfCarsToShow1.filter(page => page.manufacturer === selectedOption1)
        this.ListOfCarsToShow1 = FilterByManufacturer
    };

    @action
    SideBySidehandleManufacturerChange2 = selectedOption2 => {
        this.selectedOption2 = selectedOption2
        this.ListOfCarsToShow2 = this.ListOf_All_CarsToShow2
        const FilterByManufacturer = this.ListOfCarsToShow2.filter(page => page.manufacturer === selectedOption2)
        this.ListOfCarsToShow2 = FilterByManufacturer
    };



    @action
    SideBySidehandleCarChange1 = (event, Car_selectedOption) => {
        console.log(Car_selectedOption)
        this.CarselectedOption1 = Car_selectedOption
        this.SideBySideSelected1 = true
        this.Car1id = Car_selectedOption
    };
    @action
    SideBySidehandleCarChange2 = (event, Car_selectedOption) => {
        console.log(Car_selectedOption)
        this.CarselectedOption2 = Car_selectedOption
        this.SideBySideSelected2 = true
        this.Car2id = Car_selectedOption
    };


    @action
    UpdateCarsId1 = (selectedOption1, car1) => {
        console.log('dasdasds')
        // this.CarselectedOption1 = car1
        // this.Car2id = selectedOption1
        this.Car1id = selectedOption1
    };
    @action
    UpdateCarsId2 = (selectedOption1, car1) => {
        // this.CarselectedOption1 = car1
        this.Car2id = selectedOption1
        // this.Car1id = car1
    };



    @action
    UpdateFadeIn() {
        this.FadeIn = '1'
        this.FadeOut = '0'
    }













    @action
    LoadCars() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages?parent=${this.WordpressId}&per_page=100`)
            .then(res => {




                // ChangeBeforBuild!!!!!----------------------------------------------------------------------
                // const AllCars = res.data.filter(page => page.metadata.les_site[0] === "1");
                const AllCars = res.data.filter(page => page.metadata.tzamod_site[0] === "1");
                // ChangeBeforBuild!!!!!----------------------------------------------------------------------


                const jeeponim = AllCars.filter(page => page.metadata.category[0] === "jeeponim");
                const family = AllCars.filter(page => page.metadata.category[0] === "family");
                const small = AllCars.filter(page => page.metadata.category[0] === "small");
                const big = AllCars.filter(page => page.metadata.category[0] === "big");
                const hybrid = AllCars.filter(page => page.metadata.category[0] === "hybrid");

                let SortedCars = [...jeeponim, ...family, ...small, ...big, ...hybrid]

                runInAction(() => {
                    this.loading = false;
                    this.AllCars1 = SortedCars
                    this.AllCars2 = SortedCars

                    this.CarsToShow1 = SortedCars
                    this.CarsToShow2 = SortedCars

                    this.FilterManufacturers1()
                    this.SideBySideCarsUpdate1()

                    this.FilterManufacturers2()
                    this.SideBySideCarsUpdate2()

                    this.FatchEnd = true;

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadLessons ERR')
                });
            })

    };




    @action
    LoadManagesrCars() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages?parent=${this.WordpressId}&per_page=100`)
            .then(res => {
                // מכוניות מנהלים בכירים
                const AllCars = res.data.filter(page => page.metadata.tzamod_samankalim_site[0] === "1");

                const jeeponim = AllCars.filter(page => page.metadata.category[0] === "jeeponim");
                const family = AllCars.filter(page => page.metadata.category[0] === "family");
                const small = AllCars.filter(page => page.metadata.category[0] === "small");
                const big = AllCars.filter(page => page.metadata.category[0] === "big");
                const hybrid = AllCars.filter(page => page.metadata.category[0] === "hybrid");

                let SortedCars = [...jeeponim, ...family, ...small, ...big, ...hybrid]

                runInAction(() => {
                    this.loading = false;
                    this.AllCars1 = SortedCars
                    this.AllCars2 = SortedCars

                    this.CarsToShow1 = SortedCars
                    this.CarsToShow2 = SortedCars

                    this.FilterManufacturers1()
                    this.SideBySideCarsUpdate1()

                    this.FilterManufacturers2()
                    this.SideBySideCarsUpdate2()

                    this.FatchEnd = true;

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadLessons ERR')
                });
            })

    };




    @action
    LoadMainScreenManagersCars() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages?parent=${this.WordpressId}&per_page=100`)
            .then(res => {
                console.log(res.data)

                // מכוניות סמנכ"לים
                const AllCars_samancalim = res.data.filter(page => page.metadata.tzamod_samankalim_site[0] === "1");

                // מכוניות מנהלים בכירים
                const AllCars_menahlim_bechirim = res.data.filter(page => page.metadata.tzamod_menahlim_bechirim_site[0] === "1");

                const AllCars_tzamod_site = res.data.filter(page => page.metadata.tzamod_site[0] === "1");

                let AllCars = [...AllCars_samancalim, ...AllCars_menahlim_bechirim, ...AllCars_tzamod_site]

                const jeeponim = AllCars.filter(page => page.metadata.category[0] === "jeeponim");
                const family = AllCars.filter(page => page.metadata.category[0] === "family");
                const small = AllCars.filter(page => page.metadata.category[0] === "small");
                const big = AllCars.filter(page => page.metadata.category[0] === "big");
                const hybrid = AllCars.filter(page => page.metadata.category[0] === "hybrid");
                const managers = AllCars.filter(page => page.metadata.category[0] === "managers");
                const samancalim = AllCars.filter(page => page.metadata.category[0] === "samancalim");

                let SortedCars = [...samancalim, ...managers, ...jeeponim, ...family, ...big, ...hybrid]

                runInAction(() => {
                    this.loading = false;
                    this.AllCars = SortedCars
                    this.CarsToShow = SortedCars

                    this.FilterManufacturers()
                    this.SideBySideCarsUpdate()

                    this.FatchEnd = true;

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadLessons ERR')
                });
            })

    };



    @action
    LoadMainScreenManagementCars() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages?parent=${this.WordpressId}&per_page=100`)
            .then(res => {
                // מכוניות מנהלים בכירים

                const AllCars_tzamod = res.data.filter(page => page.metadata.tzamod_site[0] === "1");
                const AllCars_menahlim = res.data.filter(page => page.metadata.tzamod_menahlim_bechirim_site[0] === "1");
                const AllCars = [...AllCars_tzamod, ...AllCars_menahlim]

                const jeeponim = AllCars.filter(page => page.metadata.category[0] === "jeeponim");
                const family = AllCars.filter(page => page.metadata.category[0] === "family");
                const small = AllCars.filter(page => page.metadata.category[0] === "small");
                const big = AllCars.filter(page => page.metadata.category[0] === "big");
                const hybrid = AllCars.filter(page => page.metadata.category[0] === "hybrid");
                const managers = AllCars.filter(page => page.metadata.category[0] === "managers");

                let SortedCars = [...managers, ...jeeponim, ...family, ...small, ...big, ...hybrid]

                runInAction(() => {
                    this.loading = false;
                    this.AllCars1 = SortedCars
                    this.AllCars2 = SortedCars

                    this.CarsToShow1 = SortedCars
                    this.CarsToShow2 = SortedCars

                    this.FilterManufacturers1()
                    this.SideBySideCarsUpdate1()

                    this.FilterManufacturers2()
                    this.SideBySideCarsUpdate2()

                    this.FatchEnd = true;

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadLessons ERR')
                });
            })

    };




};







export default new cars();





