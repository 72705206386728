import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { withRouter, Link } from 'react-router-dom';


import styles from './PollutionSider.module.css';


@inject("siteLinks")
@observer
class Footer extends Component {





    render() {




        const Selected = (Item) => {
            if (Item == '1' && this.props.Degree == '1') {
                return styles.Selected
            }
            if (Item == '2' && this.props.Degree == '2') {
                return styles.Selected
            }
            if (Item == '3' && this.props.Degree == '3') {
                return styles.Selected
            }
            if (Item == '4' && this.props.Degree == '4') {
                return styles.Selected
            }
            if (Item == '5' && this.props.Degree == '5') {
                return styles.Selected
            }
            if (Item == '6' && this.props.Degree == '6') {
                return styles.Selected
            }
            if (Item == '7' && this.props.Degree == '7') {
                return styles.Selected
            }
            if (Item == '8' && this.props.Degree == '8') {
                return styles.Selected
            }
            if (Item == '9' && this.props.Degree == '9') {
                return styles.Selected
            }
            if (Item == '10' && this.props.Degree == '10') {
                return styles.Selected
            }
            if (Item == '11' && this.props.Degree == '11') {
                return styles.Selected
            }
            if (Item == '12' && this.props.Degree == '12') {
                return styles.Selected
            }
            if (Item == '13' && this.props.Degree == '13') {
                return styles.Selected
            }
            if (Item == '14' && this.props.Degree == '14') {
                return styles.Selected
            }
            if (Item == '15' && this.props.Degree == '15') {
                return styles.Selected
            }

        }

        const SideBySideStyle = this.props.SideBySide ? styles.FlexContainerSideBySide : ''

        return (

            <>


                <div className={styles.FlexContainer + ' ' + SideBySideStyle}>


                    <div className={styles.Container}>
                        <div className={styles.descriptionLeft}>זיהום מזערי</div>
                        <div className={styles.Item + ' ' + styles.Item1 + ' ' + Selected('1')}>1</div>
                        <div className={styles.Item + ' ' + styles.Item2 + ' ' + Selected('2')}>2</div>
                        <div className={styles.Item + ' ' + styles.Item3 + ' ' + Selected('3')}>3</div>
                        <div className={styles.Item + ' ' + styles.Item4 + ' ' + Selected('4')}>4</div>
                        <div className={styles.Item + ' ' + styles.Item5 + ' ' + Selected('5')}>5</div>
                        <div className={styles.Item + ' ' + styles.Item6 + ' ' + Selected('6')}>6</div>
                        <div className={styles.Item + ' ' + styles.Item7 + ' ' + Selected('7')}>7</div>
                        <div className={styles.Item + ' ' + styles.Item8 + ' ' + Selected('8')}>8</div>
                        <div className={styles.Item + ' ' + styles.Item9 + ' ' + Selected('9')}>9</div>
                        <div className={styles.Item + ' ' + styles.Item10 + ' ' + Selected('10')}>10</div>
                        <div className={styles.Item + ' ' + styles.Item11 + ' ' + Selected('11')}>11</div>
                        <div className={styles.Item + ' ' + styles.Item12 + ' ' + Selected('12')}>12</div>
                        <div className={styles.Item + ' ' + styles.Item13 + ' ' + Selected('13')}>13</div>
                        <div className={styles.Item + ' ' + styles.Item14 + ' ' + Selected('14')}>14</div>
                        <div className={styles.Item + ' ' + styles.Item15 + ' ' + Selected('15')}>15</div>
                        <div className={styles.descriptionRight}>זיהום מירבי</div>
                        {/* <div>{this.props.Degree}</div> */}

                    </div>

                </div>

            </>
        );


    }
}

export default withRouter(Footer);
