import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import styles from './FormCarSearch.module.css';

import { withRouter } from 'react-router-dom';

import Select from 'react-styled-select';

@inject('cars', 'singleCar', 'singleCar2')
@observer // REMOVE BRACKETS => @observer
class FormCarSearch extends Component {




    componentDidMount() {

    }

    Car1ManuChange = () => {
        console.log('dasdasdasdas')
    }


    render() {
        const options = [
            { label: "One", value: 1 },
            { label: "Two", value: 2 },
        ]

        const SelectManufacturer = <div className={styles.SelectManufacturer}>
            <Select
                searchable={false}
                options={this.props.ManufacturerList}
                value={this.props.ManufacturerValue}
                onChange={this.props.handleManufacturerChange}
                className={styles.SelectTeam}
                placeholder="בחר יצרן"
            />
         
            <Select
                searchable={false}
                options={this.props.CarsList}
                value={this.props.CarValue}
                onChange={this.props.handleCarChange}
                className={styles.SelectTeam}
                placeholder="בחר דגם"
            />
        </div>




        return (


            <>


                <div className={styles.GlleryTitleMedolContainer}>

                    <div className={styles.TitleContainer}>
                        <div className={styles.CarTitle}>{this.props.Title}</div>
                    </div>

                    <div className={styles.SelectArea}>
                        {SelectManufacturer}
                    </div>
                </div>










            </>
        );


    }
}

export default withRouter(FormCarSearch);
