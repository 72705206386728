import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';


import styles from './CarRequests2021.module.css';
import './CarRequests2021.css'
import Moment from 'react-moment';

import HeaderInside from '../components/HeaderInside'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import loadingBlue from '../images/loading_blue.gif'


@inject("siteGlobal", "siteLinks")
@observer
class CarRequests2021 extends Component {


    constructor() {
        super();
        this.state = {
            User: 'CarShow2020',
            Password: 'ZasdA1',
            Approved: false,
            ShowErr: false,
            Err: '',
            UserNameInput: '',
            PasswordInput: ''

        };
    }

    componentDidMount() {

        this.props.siteGlobal.CarRequests2021()

    }
    HandelOnChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value, Err: '' });

    }

    Valitade = () => {

        const UserName = this.state.UserNameInput
        const Password = this.state.PasswordInput

        if (UserName == this.state.User && Password == this.state.Password) {
            this.setState({ Approved: true })
        }
        else (
            this.setState({ ShowErr: true, Err: 'שם משתמש או סיסמא אינם נכונים' })
        )
    }

    render() {

        const TableData = this.props.siteGlobal.CarRequests.map((person, index) => {

            let sentFrom = person.sent_from
            // console.log(sentFrom)
            // console.log(person.id_num)

            if (sentFrom == "carshowleas") {
                sentFrom = <a href="/">ליסינג עובדים</a>
            }
            if (sentFrom == "carshow") {
                sentFrom = <a href="/">רכבים צמודים</a>
            }
            if (sentFrom == "managers") {
                sentFrom = <a href="/">סמנכ"לים בכירים וחברי הנהלה</a>
            }
            if (sentFrom == "management") {
                sentFrom = <a href="/">מנהלים בכירים</a>
            }


            return (
                <>


                    <Tr className={styles.TrData}>
                        <Td>{person.id_num}</Td>
                        <Td>{person.choice1}</Td>
                        <Td>{person.choice2}</Td>
                        <Td>{person.date}</Td>
                        {/* <Td>  <Moment format='DD/MM/YYYY'>{person.date}</Moment></Td> */}
                        <Td>{sentFrom}</Td>
                    </Tr>
                </>
            )
        }
        );


        const FinalTable = <>

            <div className={styles.Title}>CarShow 2021 טבלת הזמנות רכבים</div>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="carshowtable"
                filename="tablexls"
                sheet="tablexls"
                buttonText="XLS הורדת קובץ" />

            <div className={styles.TableContainer}>


                <div className="HiddenTableForDownload">
                    <Table id="carshowtable" className={styles.Table}>


                        <Tbody>
                            <Tr className={styles.Tr}>
                                <Th>תעודת זהות</Th>
                                <Th>רכב בעדיפות ראשונה</Th>
                                <Th>רכב בעדיפות שנייה</Th>
                                <Th>תאריך ביצוע</Th>
                                <Th>נשלח מאתר</Th>
                            </Tr>
                            {TableData}

                        </Tbody>
                    </Table>
                </div>

                <Table className={styles.Table}>
                    <Thead className={styles.Thead}>
                        <Tr className={styles.Tr}>
                            <Th>תעודת זהות</Th>
                            <Th>רכב בעדיפות ראשונה</Th>
                            <Th>רכב בעדיפות שנייה</Th>
                            <Th>תאריך ביצוע</Th>
                            <Th>נשלח מאתר</Th>
                        </Tr>
                    </Thead>

                    <Tbody>

                        {TableData}

                    </Tbody>
                </Table>
            </div>
        </>

        const NotApprove = <div className={styles.InputContainer}>

            <input
                placeholder="שם משתמש"
                type="text"
                className={styles.FromInput}
                name="UserNameInput"
                value={this.state.UserNameInput}
                onChange={this.HandelOnChange.bind(this)}
            />


            <input
                placeholder="סיסמא"
                type="password"
                className={styles.FromInput}
                name="PasswordInput"
                value={this.state.PasswordInput}
                onChange={this.HandelOnChange.bind(this)}
            />

            <div className={styles.EnterBt} onClick={this.Valitade.bind(this)} >כניסה</div>
            {this.state.ShowErr ? <div className={styles.Err}>{this.state.Err}</div> : null}
        </div>

        const Approved = <>
            <div className={styles.FlexContainer}>
                {this.props.siteGlobal.loading ? <img className="loader" src={loadingBlue} alt="Loader" /> : FinalTable}
            </div>

        </>


        return (

            <>
                <HeaderInside
                    MainLink='/'
                    HideSideBySide={true}
                />
                <div className={styles.FlexContainer}>

                    {this.state.Approved ? Approved : NotApprove}

                </div>
            </>
        );


    }
}

export default CarRequests2021;
