import React, { Component } from 'react';

import './Acordion.css';

import Collapsible from 'react-collapsible';


class Acordion extends Component {




    render() {


        return (

            <div className={this.props.className}>
                <Collapsible onTriggerOpening={this.props.onTriggerOpening} onTriggerClosing={this.props.onTriggerClosing} transitionTime='200' open={this.props.open} trigger={this.props.Title} triggerDisabled={this.props.triggerDisabled}>
                    {this.props.children}
                </Collapsible>
            </div>
        );


    }
}

export default Acordion;
