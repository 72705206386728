import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './SingleCar.module.css';

import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';

import LoginCheck from '../components/LoginCheck';

import HeaderInside from '../components/HeaderInside';
import Footer from '../components/Footer';
import CarData from '../components/CarData'
import CarDataLoader from '../components/CarDataLoader'

import Vimeo from '@u-wave/react-vimeo';

import { TiArrowBack } from 'react-icons/ti';
import ImageGallery from 'react-image-gallery';
import { toJS } from 'mobx';

@inject('login', 'singleCar', 'siteLinks')
@observer // REMOVE BRACKETS => @observer
class SingleCar extends Component {



    constructor() {
        super();
        this.state = {
            MainLink: '/main?m=carshow',
            SideBySideLink: ''

        };
    }


    componentDidMount() {

        this.setState({ SideBySideLink: `/side-by-side/${this.props.match.params.carid}/0` })

        const URlSearch = this.props.location.search
        const SiteRelation = new URLSearchParams(URlSearch).get('m')


        if (SiteRelation === 'carshow') {
            // this.setState({ MainLink: '/main' })
            this.setState({ SideBySideLink: `/side-by-side/${this.props.match.params.carid}/0?m=carshow` })
        }

        if (SiteRelation === 'carshow-t') {
            // this.setState({ MainLink: '/main' })
            this.setState({ SideBySideLink: `/side-by-side/${this.props.match.params.carid}/0?m=carshow-t` })
        }

        if (SiteRelation === 'managers') {
            this.setState({ MainLink: '/managers?m=managers' })
            this.setState({ SideBySideLink: `/side-by-side/${this.props.match.params.carid}/0?m=managers` })
        }

        if (SiteRelation === 'management') {
            this.setState({ MainLink: '/management?m=management' })
            this.setState({ SideBySideLink: `/side-by-side/${this.props.match.params.carid}/0?m=management` })
        }

        // scroller.scrollTo(scrollto, {
        //     duration: 1500,
        //     delay: 50,
        //     smooth: 'easeInOutQuint',
        // })

    }

    render() {

        const URlSearch = this.props.location.search
        const IsManager = new URLSearchParams(URlSearch).get('m')

        return (


            <>
                <LoginCheck loggedin={this.props.login.token} siteRealation={this.props.login.siteRealation} PageRelation={IsManager}>
                    <div className={styles.Container}>

                        {/* <Vimeo
                        video='https://player.vimeo.com/video/471113126'
                        className='vimeo_iframe'

                    /> */}

                        <HeaderInside
                            SideBySideLink={this.state.SideBySideLink}
                            Car1Id={this.props.match.params.carid}
                            Car2Id="0"
                            MainLink={this.state.MainLink} >
                            {/* <Link to='/main?scrollto=cars-filter'><TiArrowBack size={40} /></Link> */}
                            <div onClick={() => this.props.history.goBack()}><TiArrowBack size={40} /></div>
                        </HeaderInside>

                        <CarData CarId={this.props.match.params.carid} />

                    </div>
                    <Footer />
                </LoginCheck>
            </>
        );


    }
}

export default withLastLocation(withRouter(SingleCar));
