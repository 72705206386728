import React, { Component, useRef, useEffect } from 'react';
import styles from './TopAnimationAndVideo.module.css';
import Vimeo from '@u-wave/react-vimeo';
import ModalVideo from 'react-modal-video';

import { useMediaQuery } from 'react-responsive'

import { inject, observer } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';

import VideoPopUp from '../components/VideoPopUp'

import loadingWhite from '../images/loading_white.gif'

@inject("siteLinks")
@observer
class TopAnimationAndVideo extends Component {


    constructor() {
        super();
        this.state = {
            Dashbord: true,
            PopVideo: false,
            loading: true,
        };
    }




    VideoPopup = () => {
        this.setState({ PopVideo: true })
    }
    VimeoEnds = () => {
        this.setState({ Dashbord: true, loading: false })
        // let VideoPlayed = localStorage.getItem('PopupVideo')
        // VideoPlayed ? null : VideoPlayed = "1"
        // console.log(VideoPlayed)
        // if (VideoPlayed == '1') {
        //     setTimeout(() => {
        //         localStorage.setItem('PopupVideo', '0');
        //         this.setState({ PopVideo: true })
        //     }, 5000)
        // }
    }


    render() {


        const Desktop = ({ children }) => {
            const isDesktop = useMediaQuery({ minWidth: 601 })
            return isDesktop ? children : null
        }

        const Mobile = ({ children }) => {
            const isMobile = useMediaQuery({ maxWidth: 600 })
            return isMobile ? children : null
        }



        const DashbordAndLogo =
            this.state.Dashbord ? (
                <div className={styles.DashbordContainer}>
                    <div className={styles.VideoButtonContainer}>
                        <div onClick={this.VideoPopup} className={styles.OpenVideo}></div>
                    </div>
                    <object id="EdgeID" type="text/html" data-dw-widget="Edge" data={this.props.siteLinks.dashbord_and_logo_desktop}><div></div></object>
                </div>) :
                (


                    <div className={styles.DashbordContainerEmpty}>
                        <div className={styles.VimeoContainer}>

                            {this.state.loading && <img className="loader" src={loadingWhite} alt="Loader" />}

                            < Vimeo
                                video={this.props.VimeoId}
                                autoplay
                                responsive={true}
                                width='1920px'
                                className={styles.Vimeo}
                                onEnd={this.VimeoEnds}
                                controls={false}
                                autopause={false}
                                paused={false}
                                allowFullScreen={false}
                                showTitle={false}
                                onPause={this.VimeoEnds}

                            // loop={true}
                            />

                        </div>
                    </div>
                )




        const MobileDashbordAndLogo =
            this.state.Dashbord ? (
                <div className={styles.MobileDashbordContainer}>
                    <div className={styles.MobileVideoButtonContainer}>
                        <div onClick={this.VideoPopup} className={styles.MobileOpenVideo}></div>
                    </div>
                    <object id="EdgeID" type="text/html" data-dw-widget="Edge" data={this.props.siteLinks.dashbord_and_logo_mobile}><div></div></object>
                </div>) : <div className={styles.MobileDashbordContainerEmpty}>
                    <div className={styles.MobileVimeoContainer}>
                        {this.state.loading && <img className="loader, loadermainMobile" src={loadingWhite} alt="Loader" />}
                        <Vimeo
                            responsive={true}
                            video={this.props.MobileVimeoId}
                            autoplay
                            height='500px'
                            className={styles.Vimeo}
                            onEnd={this.VimeoEnds}
                            controls={false}
                            autopause={false}
                            paused={false}
                            allowFullScreen={false}
                            showTitle={false}
                            onPause={this.VimeoEnds}
                        />

                    </div>
                </div>



        const mainContainer = {
            backgroundColor: this.state.Dashbord ? '#000510' : '#000510'
        }




        return (


            <>

                <ModalVideo
                    channel='vimeo'
                    autopause={false}
                    autoplay={true}
                    isOpen={this.state.PopVideo}
                    videoId={this.props.PopupVideo}
                    onClose={() => this.setState({ PopVideo: false })}
                />

                {/* <VideoPopUp
                    videoId={this.props.PopupVideo}
                    autoplay={true}
                /> */}

                <div style={mainContainer}>
                    <Desktop> {DashbordAndLogo}</Desktop>
                    <Mobile> {MobileDashbordAndLogo}</Mobile>

                </div>


            </>
        );


    }
}

export default withRouter(TopAnimationAndVideo);
