import React, { Component } from 'react';

import styles from './AcordionItemSideBySide.module.css';



class AcordionItemBSideBySide extends Component {




    render() {
        const Price = this.props.Price ? styles.Price : null
        const PriceTitleClass = this.props.PriceTitleClass ? styles.PriceTitleClass : null
        const PriceValueClass = this.props.PriceValueClass ? styles.PriceValueClass : null
        return (

            <>
                <div className={styles.ItemContainer}>
                    <div className={styles.DividerTop + ' divder_top'}></div>
                    <div className={styles.AcordionItem + ' ' + this.props.AcordionItemClass}>

                        <div className={styles.Title + ' ' + PriceTitleClass}>
                            <div> {this.props.Title}</div>
                            {this.props.Taxtext ? <div className={styles.TaxText}>{this.props.Taxtext}</div> : null}
                        </div>
                        <div className={styles.Value + ' ' + Price + ' ' + PriceValueClass} >{this.props.Value}</div>

                    </div>
                    {/* <div className={styles.DividerBottom}></div> */}
                </div>
            </>
        );


    }
}

export default AcordionItemBSideBySide;
