import { observable, configure, runInAction, action, toJS } from 'mobx';
import axios from 'axios';
import clientConfig from '../client-config';

configure({ enforceActions: true });


class SiteGlobal {
    @observable loading = false
    @observable FatchEnd = false

    @observable ActiveCarRequests = true
    @observable ActiveUsers = false
    @observable ActiveAddUser = false
    @observable ActiveAddUsers = false
    @observable ActiveDelUsers = false


    @observable CarRequests = []
    @observable CarRequestsCopy = []

    @observable AllUsers = []
    @observable AllUsersCopy = []

    @observable FilterAllusers = true
    @observable FilterLeassite = false
    @observable FilterTzamotsite = false
    @observable FilterManagement = false
    @observable FilterManagers = false
    @observable FilterAdmins = false

    @observable ApproveAddUser = false
    @observable Err = ''

    @observable PopDelete = false
    @observable AprovedDelete = false
    @observable AprovedAddUsers = false

    @action ShowPopDelete() {
        this.PopDelete = true
    }

    @action HidePopDelete() {
        this.PopDelete = false
    }

    @action hideApproved() {
        this.AprovedDelete = false
    }
    
    @action HideAprovedAddUsers() {
        this.AprovedAddUsers = false
    }

    @action UpdateErr(err) {
        this.Err = err
    }

    @action FliterUsersTable(filterBy) {
        let FilteredData = []
        if (filterBy == 'AllUsers') {
            FilteredData = this.AllUsersCopy;
            this.FilterAllusers = true
            this.FilterLeassite = false
            this.FilterTzamotsite = false
            this.FilterManagement = false
            this.FilterManagers = false
            this.FilterAdmins = false
        }
        if (filterBy == 'leassite') {
            FilteredData = this.AllUsersCopy.filter(user => user.siterelation === filterBy);
            this.FilterAllusers = false
            this.FilterLeassite = true
            this.FilterTzamotsite = false
            this.FilterManagement = false
            this.FilterManagers = false
            this.FilterAdmins = false
        }
        if (filterBy == 'tzamotsite') {
            FilteredData = this.AllUsersCopy.filter(user => user.siterelation === filterBy);
            this.FilterAllusers = false
            this.FilterLeassite = false
            this.FilterTzamotsite = true
            this.FilterManagement = false
            this.FilterManagers = false
            this.FilterAdmins = false
        }
        if (filterBy == 'management') {
            FilteredData = this.AllUsersCopy.filter(user => user.siterelation === filterBy);
            this.FilterAllusers = false
            this.FilterLeassite = false
            this.FilterTzamotsite = false
            this.FilterManagement = true
            this.FilterManagers = false
            this.FilterAdmins = false
        }
        if (filterBy == 'managers') {
            FilteredData = this.AllUsersCopy.filter(user => user.siterelation === filterBy);
            this.FilterAllusers = false
            this.FilterLeassite = false
            this.FilterTzamotsite = false
            this.FilterManagement = false
            this.FilterManagers = true
            this.FilterAdmins = false
        }
        if (filterBy == 'admin_panel') {
            FilteredData = this.AllUsersCopy.filter(user => user.admin_panel == 1);
            this.FilterAllusers = false
            this.FilterLeassite = false
            this.FilterTzamotsite = false
            this.FilterManagement = false
            this.FilterManagers = false
            this.FilterAdmins = true
        }
        this.AllUsers = FilteredData
    }

    @action AddUser(UserId, UserSite, UserPanel) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.UserId = UserId
        data_to_post.UserSite = UserSite
        data_to_post.UserPanel = UserPanel

        this.loading = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/new_id`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                if (res.data == 'already_exists') {
                    runInAction(() => {
                        this.loading = false
                        this.Err = 'מספר כבר קיים במערכת'
                    });
                }
                else {
                    runInAction(() => {
                        this.loading = false
                        this.ApproveAddUser = true
                    });
                }

            })
            .catch(err => {
                this.loading = false
            })
    }

    @action ChangeApproveAddUser() {
        this.ApproveAddUser = false
    }
    @action DeleteRow(rawId) {
        this.Delete_row_car_requests(rawId)


    }



    @action DelAll(UsersList) {
        this.loading = false
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.UsersList = UsersList

        this.loading = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/del_user`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                    this.loading = false
                    this.PopDelete = false
                    this.AprovedDelete = true
                });
            })
            .catch(err => {
                this.loading = false
            })
    }



    @action AddUsers(UsersList) {
        this.loading = false
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.UsersList = UsersList

        this.loading = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/add_users`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                    this.loading = false
                    this.AprovedAddUsers = true
                });
            })
            .catch(err => {
                this.loading = false
            })
    }



    @action ChangePageContent(toShow) {
        // console.log(toShow)
        if (toShow == 'car-requests') {
            this.ActiveCarRequests = true
            this.ActiveUsers = false
            this.ActiveAddUser = false
            this.ActiveAddUsers = false
            this.ActiveDelUsers = false
        }
        if (toShow == 'users') {
            this.ActiveCarRequests = false
            this.ActiveUsers = true
            this.ActiveAddUser = false
            this.ActiveAddUsers = false
            this.ActiveDelUsers = false
        }
        if (toShow == 'add-user') {
            this.ActiveCarRequests = false
            this.ActiveUsers = false
            this.ActiveAddUser = true
            this.ActiveAddUsers = false
            this.ActiveDelUsers = false
        }
        if (toShow == 'add-users') {
            this.ActiveCarRequests = false
            this.ActiveUsers = false
            this.ActiveAddUser = false
            this.ActiveAddUsers = true
            this.ActiveDelUsers = false
        }
        if (toShow == 'del-users') {
            this.ActiveCarRequests = false
            this.ActiveUsers = false
            this.ActiveAddUser = false
            this.ActiveAddUsers = false
            this.ActiveDelUsers = true
        }
    }



    @action
    LoadUsers() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY

        this.loading = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/load_users`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                    this.loading = false
                    this.AllUsers = res.data
                    this.AllUsersCopy = res.data
                    this.FilterAllusers = true
                    this.FilterLeassite = false
                    this.FilterTzamotsite = false
                    this.FilterManagement = false
                    this.FilterManagers = false
                    this.FilterAdmins = false

                });
            })
            .catch(err => {
                this.loading = false
            })
    }




    @action
    UpdateUserData(UserId, CurrentSiteRelated, NewSiteRelated, NewIsAdmin) {
        const Id = UserId
        const IsAdmin = NewIsAdmin
        let SiteReRelated = NewSiteRelated

        if (!SiteReRelated) {
            SiteReRelated = CurrentSiteRelated

        }
        else {
            SiteReRelated = NewSiteRelated.value

        }


        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.UserId = Id
        data_to_post.UserSite = SiteReRelated
        data_to_post.UserPanel = IsAdmin

        this.loading = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/update_id`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                    this.loading = false
                    this.LoadUsers()
                });
            })
            .catch(err => {
                this.loading = false
            })

    }

    @action
    DeleteUserFromWordpress(UserId) {

        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.UserId = UserId

        this.loading = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/del_id`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                    this.loading = false
                    this.LoadUsers()
                });
            })
            .catch(err => {
                this.loading = false
            })
    }



    @action
    Delete_row_car_requests(rowId) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.RowId = rowId

        this.loading = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/Delete_row_car_requests`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {

                runInAction(() => {
                    this.loading = false
                    this.CarRequests2021()

                });
            })
            .catch(err => {
                this.loading = false
            })
    }


    // filter records by search text
    @action
    FilterData = (val) => {
        const ArrCopy = [...this.CarRequestsCopy]
        const SortCopy = ArrCopy.filter(order => (order.id_num.includes(val)));
        this.CarRequests = SortCopy
    }

    // filter records by search text
    @action
    FilterDataUsers = (val) => {
        const ArrCopy = [...this.AllUsersCopy]
        const SortCopy = ArrCopy.filter(order => (order.FirstName.includes(val)));
        this.AllUsers = SortCopy
    }


    @action
    SortBy = (SortBy) => {
        const ArrCopy = [...this.CarRequests]
        if (SortBy == 'id_num') {
            const SortCopy = ArrCopy.sort((a, b) => (a.id_num > b.id_num) ? 1 : -1)
            // const SortCopy = ArrCopy.filter(order => (order.id_num.includes('0313')));
            this.CarRequests = SortCopy
        }

        if (SortBy == 'choice1') {
            ArrCopy.sort((a, b) => (a.choice1 > b.choice1) ? 1 : -1)
            this.CarRequests = ArrCopy
        }
        if (SortBy == 'choice2') {
            ArrCopy.sort((a, b) => (a.choice2 > b.choice2) ? 1 : -1)
            this.CarRequests = ArrCopy
        }
        if (SortBy == 'date') {
            ArrCopy.sort((a, b) => (a.date - b.date) ? 1 : -1)
            this.CarRequests = ArrCopy
        }

        if (SortBy == 'sent_from') {
            ArrCopy.sort((a, b) => (a.sent_from > b.sent_from) ? 1 : -1)
            this.CarRequests = ArrCopy
        }

    }



    @action
    CarRequests2021() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/1739`)
            .then(res => {
                const TableItems = res.data.metadata.carrequests[0]
                // console.log(res.data.metadata)
                let TableData = []
                for (let i = 0; i < TableItems; i++) {
                    let obj = {};
                    obj['date'] = res.data.metadata["carrequests_" + i + "_date"][0];
                    obj['id_num'] = res.data.metadata["carrequests_" + i + "_id"][0];
                    obj['choice1'] = res.data.metadata["carrequests_" + i + "_choice1"][0];
                    obj['choice2'] = res.data.metadata["carrequests_" + i + "_choice2"][0];
                    obj['sent_from'] = res.data.metadata["carrequests_" + i + "_sent_from"][0];
                    obj['key'] = i + 1;
                    TableData.push(obj)
                }
                runInAction(() => {
                    this.loading = false;
                    this.FatchEnd = true;
                    this.CarRequests = TableData
                    this.CarRequestsCopy = TableData
                    // console.log(res.data)
                    // console.log(toJS(this.CarRequests))

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadLessons ERR')
                });
            })
    };













};



export default new SiteGlobal();




