import React, { Component, Suspense } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './LoginScreen.module.css';
import './LoginScreen.css';

import { withRouter, Link, Redirect } from 'react-router-dom';
import RadioButton from '../components/RadioButton'
import parse from 'html-react-parser';
import { toJS } from 'mobx';

import loadingWhite from '../images/loading_white.gif'



const AllCarsLazy = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("../components/LoaderComponent")), 4000)
        )
);



@inject('siteLinks', 'fatchPage', 'cars')
@observer
class Lesson extends Component {
    constructor() {
        super();
        this.state = {
            ShowComment: false,
            NextPageLink: ''
        };
    }

    componentDidMount() {
        this.props.fatchPage.GetLoginPage()

        this.setState({ NextPageLink: `/main?m=carshow` })

        const URlSearch = this.props.location.search
        const IsManager = new URLSearchParams(URlSearch).get('m')

        if (IsManager === 'managers') {
            this.setState({ NextPageLink: '/managers?m=managers' })
        }

        if (IsManager === 'management') {
            this.setState({ NextPageLink: '/management?m=management' })
        }
    }


    onValueChange() {
        this.props.fatchPage.Approved()
        this.setState({ ShowComment: false })
        localStorage.setItem('PopupVideo', '1');
        // localStorage.clear();
    }

    ClickWithOutAppove = () => {
        this.setState({ ShowComment: true })
    }

    render() {

        const AllCars = this.props.cars.CarsToShow.map((car, index) =>
            <>
                <img src={toJS(car.featured_url)} />
            </>)

        const BackgroundStyle = {
            backgroundImage: 'url(' + this.props.siteLinks.LoginScreenBackground + ')',



            //On moble screen
            // '@media (max-width: 801px)': {
            //     backgroundImage: 'url(' + this.props.siteLinks.LoginScreenBackground + ')',
            // }

        };

        const AvtiveButton = <Link to={this.state.NextPageLink}>
            <div className={styles.FlexContainer}>
                <div className={styles.ButtonContainer}>
                    <div className={styles.GoToSiteButton}>בואו נצא לדרך</div>
                    {this.props.siteLinks.CarWhite}
                </div>
            </div>
        </Link>

        const NonAvtiveButton =

            <div onClick={this.ClickWithOutAppove} className={styles.FlexContainer + ' ' + styles.NonActiveButton}>
                <div className={styles.NonActiveButtonButtonContainer}>
                    <div className={styles.GoToSiteButton}>בואו נצא לדרך</div>
                    {this.props.siteLinks.CarWhite}
                </div>
            </div>



        return (


            <div className={styles.MainContainer}  >


                {/* {localStorage.getItem('PopupVideo') ? null : <Redirect to="/main" />} */}

                {this.props.fatchPage.loading && <img className="loader" src={loadingWhite} alt="Loader" />}

                <div className={styles.BackgroundImage}><img src={this.props.siteLinks.LoginScreenBackground} /></div>


                {this.props.fatchPage.FatchEnd ?
                    (<div class={styles.Frame}>

                        <div class={styles.TopOfFrame}>
                            <div class={styles.Left}></div>
                            <div class={styles.Center}>{this.props.siteLinks.RafaelWhiteLogo}</div>
                            <div class={styles.Right}></div>
                        </div>

                        <Suspense fallback="">
                            <AllCarsLazy><div class={styles.AllImagesPreLoader}>{AllCars}</div></AllCarsLazy>
                        </Suspense>

                        <div class={styles.RestOfFrame}>
                            <div class={styles.BigTitle} >{this.props.fatchPage.LoginPageTitle}</div>

                            <div className={styles.ApproveComment} style={{ opacity: this.state.ShowComment ? 1 : 0 }}>קודם לאשר שקראת והבנת</div>
                            <div className={styles.Approve}>קראתי והבנתי</div>
                            <RadioButton ShowCommnet={this.state.ShowComment} onChange={this.onValueChange.bind(this)} checked={this.props.fatchPage.LoginPageApproved} />
                            {this.props.fatchPage.LoginPageApproved ? AvtiveButton : NonAvtiveButton}

                            <div className={styles.CarShowLogo}>  {this.props.siteLinks.CarShowLogoWhite}</div>
                        </div>



                    </div>
                    ) : null

                }

            </ div>
        );


    }
}

export default withRouter(Lesson);
