import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Link } from 'react-router-dom';

import styles from './LoginHeader.module.css';

@inject('login')
@observer
class LoginHeader extends Component {




    render() {
        const id = this.props.IdNumber

        let admin = null

        const showpanel = this.props.login.adminPanel

        if (showpanel == true) {
            admin = <>
                <div className={styles.space}>|</div>
                <Link to="/admin-panel" className={styles.logout}>פאנל ניהול</Link>
            </>
        }

        return (

            <div className={styles.LoginHeader}>
                <div className={styles.container}>
                    <div onClick={this.props.onClick} className={styles.logout}>התנתק</div>
                    {admin}
                    <div className={styles.space}>|</div>
                    <div className={styles.id}>
                        <span className={styles.Number}>{id} </span>שלום</div>
                </div>
            </div>
        );


    }
}

export default LoginHeader;
