import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import { GrTooltip } from 'react-icons/gr';

import { FaQuestionCircle } from 'react-icons/fa';

import styles from './AcordionItem.module.css';



class FilterByCar extends Component {

    constructor() {
        super();
        this.state = {

            isMobile: window.innerWidth < 1101


        };
    }



    updateIsMobile = () => {
        this.setState({
            isMobile: window.innerWidth < 1101
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }



    render() {
        const Price = this.props.Price ? styles.Price : null

        // console.log(this.state.isMobile)
        let offset = {}
        this.state.isMobile ? offset = { top: 2, right: 5} : offset = { top: -5, left: -3 }
        return (

            <>
                <div className={styles.ItemContainer}>

                    <div className={styles.DividerTop}></div>
                    <div className={styles.AcordionItem}>
                        {this.props.dataTip ? (
                            <div data-tip={this.props.dataTip} className={styles.toolTip}><FaQuestionCircle size='14' /></div>
                        ) : null}
                        < ReactTooltip
                            effect='solid'
                            offset={offset}
                            clickable={true}
                            multiline={true}
                            delayShow='100'
                            // width='100px'
                            backgroundColor='white'
                            className={styles.toolTipContent}
                            textColor='#101130' />

                        < div className={styles.Title}>{this.props.Title}</div>
                        <div className={styles.Value + ' ' + Price} >{this.props.Value}</div>

                    </div>

                </div>
            </>
        );


    }
}

export default FilterByCar;
