import { observable, configure, runInAction, action, toJS } from 'mobx';
import axios from 'axios';
import clientConfig from '../client-config';

import loadingWhite from '../'
configure({ enforceActions: true });


class SiteGlobal {
    @observable loading = false
    @observable PopUpFormOpacity = 0;
    @observable PopUpFormLeft = '100vw';
    @observable PopUpFormDisplay = 'flex'
    @observable PopUpFormTransition = ''
    @observable PopUpZ = '-5000'
    @observable IsPopUpOpen = false

    @observable CarRequests = []

    // more info near items on single car
    // שנת דגם
    @observable year = ''
    // מקומות ישיבה
    @observable seats = ''
    // הנעה
    @observable propulsion = ''
    // תיבת הילוכים
    @observable gearbox = ''
    // סוג מנוע
    @observable engine_type = ''
    // מס' בוכנות
    @observable number_of_pistons = ''
    // נפח (סמ"ק)
    @observable volume = ''
    // הספק מרבי (כ"ס)
    @observable maximum_power = ''
    // סל"ד להספק מרבי
    @observable rpm_for_maximum_power = ''
    // מומנט מרבי (קג"מ)
    @observable maximum_torque = ''
    // סל"ד למומנט מרבי
    @observable rpm_for_maximum_torque = ''
    // תאוצה 0-100
    @observable acceleration = ''
    // מהירות מרבית
    @observable maximum_speed = ''
    // צריכת דלק - עירונית (ק"מ/ליטר)
    @observable fuel_consumption_urban = ''
    // צריכת דלק - בינעירונית (ק"מ/ליטר)
    @observable Fuel_consumption_intercity = ''
    // צריכת דלק - ממוצעת (ק"מ/ליטר)
    @observable Fuel_consumption_average = ''
    // דרגת זיהום
    @observable degree_of_pollution = ''
    // מבחן ריסוק
    @observable crash_test = ''
    // בלמים
    @observable brakes = ''
    // כריות אוויר
    @observable air_bags = ''
    // ביטול כרית אוויר נוסע
    @observable disabling_a_passenger_airbag = ''
    // איזופיקס
    @observable isofix = ''
    // חיישני לחץ אוויר
    @observable air_pressure_sensors = ''
    // בלימת חירום אוטונומית
    @observable autonomous_emergency_braking = ''
    // זיהוי הולכי רגל
    @observable pedestrian_identification = ''
    // בקרת שיוט אדפטיבית
    @observable lane_departure_control = ''
    // בקרת סטיה מנתיב
    @observable two_wheeled_identification = ''
    // זיהוי כלי רכב ב"שטח מת
    @observable vehicle_identification_in_dead_zone = ''
    // התרעת תנועה חוצה מאחור
    @observable cross_traffic_warning_from_behind = ''
    // אורות גבוהים אוטומטיים
    @observable automatic_high_beams = ''
    // זיהוי תמרורים
    @observable signage_identification = ''
    // רוחב (ס"מ)
    @observable Width = ''
    // בסיס גלגלים (ס"מ)
    @observable wheelbase = ''
    // נפח תא מטען (ליטרים)
    @observable trunk_volume = ''
    // משקל עצמי (ק"ג)
    @observable net_weight = ''
    // מידות צמיגים
    @observable tire_dimensions = ''
    // קיבולת מיכל דלק (ליטרים)
    @observable fuel_tank_capacity = ''
    // טלפון
    @observable phone = ''
    // מסך מולטימדיה
    @observable multimedia_screen = ''
    // מערכת ניווט GPS
    @observable gps_navigation_system = ''
    // קישוריות
    @observable connectivity = ''
    // טעינה אלחוטית
    @observable wireless_charging = ''
    // מיזוג אוויר
    @observable air_conditioning = ''
    // חישוקים קלים
    @observable recreational_hoops = ''
    // בקרת שיוט
    @observable cruise_control = ''
    // מושבים - כוונון חשמלי
    @observable seats_electric_adjustment = ''
    // פנסי חזית
    @observable headlights = ''
    // חיישני תאורה
    @observable ighting_sensors = ''
    // עזרי חניה
    @observable parking_aids = ''
    // חניה אוטומטית
    @observable parking_cameras = ''
    // מצלמות חניה
    @observable automatic_parking = ''
    // מחשב דרך
    @observable trip_computer = ''
    // לוח מחוונים דיגיטלי
    @observable digital_dashboard = ''
    // תצוגה עילית
    @observable top_view = ''
    // מראות צד מתקפלות חשמלית
    @observable electric_folding_side_mirrors = ''
    // מראות נגד סנוור
    @observable mirrors_against_glare = ''
    // בורר מצבי נהיגה
    @observable driving_mode_selector = ''
    // מערכת "עצור וסע" לדימום מנוע בעמידה
    @observable stop_and_drive_system_for_standing_engine_bleeding = ''
    // בלם יד חשמלי
    @observable electric_handbrake = ''
    // בקרת גלישה במדרון
    @observable slope_control = ''
    // מתלים ובולמים
    @observable suspensions_and_dampers = ''
    // דלת תא מטען חשמלית
    @observable electric_trunk_door = ''



    @action
    getObs = (name) => {
        switch (name) {

            // case "שנת דגם": return this.year;
            // case "מקומות ישיבה": return this.seats;

            // מנוע וביצועים
            case "הנעה": return this.propulsion;
            case "תיבת הילוכים": return this.gearbox;
            case "סוג מנוע": return this.engine_type;
            case "מס' בוכנות": return this.number_of_pistons;
            case 'נפח (סמ"ק)': return this.volume;
            case 'הספק מרבי (כ"ס)': return this.maximum_power;
            case 'סל"ד להספק מרבי': return this.rpm_for_maximum_power;
            case 'מומנט מרבי (קג"מ)': return this.maximum_torque;
            case 'סל"ד למומנט מרבי': return this.rpm_for_maximum_torque;
            case 'תאוצה 0-100': return this.acceleration;
            case 'מהירות מרבית': return this.maximum_speed;
            // case 'צריכת דלק - עירונית (ק"מ/ליטר)': return this.fuel_consumption_urban;
            // case 'צריכת דלק - בינעירונית (ק"מ/ליטר)': return this.Fuel_consumption_intercity;
            // case 'צריכת דלק - ממוצעת (ק"מ/ליטר)': return this.Fuel_consumption_average;
            // מנוע וביצועים END

            // בטיחות רכב
            case 'מבחן ריסוק': return this.crash_test;
            case 'בלמים': return this.brakes;
            case 'כריות אוויר': return this.air_bags;
            case 'ביטול כרית אוויר נוסע': return this.disabling_a_passenger_airbag;
            case 'איזופיקס': return this.isofix;
            case 'חיישני לחץ אוויר': return this.air_pressure_sensors;
            case 'בלימת חירום אוטונומית': return this.autonomous_emergency_braking;
            case 'זיהוי הולכי רגל': return this.pedestrian_identification;
            case 'בקרת שיוט אדפטיבית': return this.lane_departure_control;
            case 'בקרת סטיה מנתיב': return this.two_wheeled_identification;
            case 'זיהוי כלי רכב ב"שטח מת': return this.vehicle_identification_in_dead_zone;
            case 'התרעת תנועה חוצה מאחור': return this.cross_traffic_warning_from_behind;
            case 'אורות גבוהים אוטומטיים': return this.automatic_high_beams;
            case 'זיהוי תמרורים': return this.signage_identification;
            // בטיחות רכב END

            // מידות
            case 'רוחב (ס"מ)': return this.Width;
            case 'בסיס גלגלים (ס"מ)': return this.wheelbase;
            case 'נפח תא מטען (ליטרים)': return this.trunk_volume;
            case 'משקל עצמי (ק"ג)': return this.net_weight;
            case 'מידות צמיגים': return this.tire_dimensions;
            case 'קיבולת מיכל דלק (ליטרים)': return this.fuel_tank_capacity;
            // מידות END

            // מולטימדיה ותקשרות
            case 'טלפון': return this.phone;
            case 'מסך מולטימדיה': return this.multimedia_screen;
            case 'מערכת ניווט GPS': return this.gps_navigation_system;
            case 'קישוריות': return this.connectivity;
            case 'טעינה אלחוטית': return this.wireless_charging;
            // מולטימדיה ותקשרות END

            // אבזור ונוחות
            case 'מיזוג אוויר': return this.air_conditioning;
            case 'חישוקים קלים': return this.recreational_hoops;
            case 'בקרת שיוט': return this.cruise_control;
            case 'מושבים - כוונון חשמלי': return this.seats_electric_adjustment;
            case 'פנסי חזית': return this.headlights;
            case 'חיישני תאורה': return this.ighting_sensors;
            case 'עזרי חניה': return this.parking_aids;
            case 'חניה אוטומטית': return this.parking_cameras;
            // case 'מצלמות חניה': return this.automatic_parking;
            case 'מחשב דרך': return this.trip_computer;
            case 'לוח מחוונים דיגיטלי': return this.digital_dashboard;
            case 'תצוגה עילית': return this.top_view;
            case 'מראות צד מתקפלות חשמלית': return this.electric_folding_side_mirrors;
            // case 'מראות נגד סנוור': return this.mirrors_against_glare;
            case 'בורר מצבי נהיגה': return this.driving_mode_selector;
            case 'מערכת "עצור וסע" לדימום מנוע בעמידה': return this.stop_and_drive_system_for_standing_engine_bleeding;
            case 'בלם יד חשמלי': return this.electric_handbrake;
            case 'בקרת גלישה במדרון': return this.slope_control;
            case 'מתלים ובולמים': return this.suspensions_and_dampers;
            case 'דלת תא מטען חשמלית': return this.electric_trunk_door;
            // אבזור ונוחות END

            default: return null
        }
    }

    //TO CHECK IF FROM IS OPEN OR NOT (STRAT FATCH IF YES)
    @action
    ToggleIsOpen = () => {
        const val = this.IsPopUpOpen
        this.IsPopUpOpen = !val;
        // console.log('POP UP IS OPEN= ' + this.IsPopUpOpen)
    }



    @action PopUpFomrShow() {
        this.PopUpZ = '5000'
        this.PopUpFormOpacity = 1
        this.PopUpFormTransition = 'opacity .2s ease-in-out'
        this.PopUpFormLeft = '0'
    }


    @action PopUpFomrShowMobile() {
        this.PopUpZ = '5000'
        this.PopUpFormOpacity = 1
        this.PopUpFormLeft = '0'
    }





    @action
    PopUpFomrHide(type) {
        if (type == 'desktop') {
            this.PopUpZ = '-5000'
            this.PopUpFormOpacity = 0
            this.PopUpFormTransition = ''
        }
        if (type == 'mobile') {
            this.PopUpFormLeft = '100vw';
        }
        this.ToggleIsOpen()
    }



    @action
    loadUsers() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.PageId = 1313

        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/get_page_content`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {




                // const Filterd = res.data.filter(student => student.role === 'student')

                // console.log(res.data)

                // Country: "jk"
                // Email: "sim444cha@activebranding.co.il"
                // FirstName: "simcha"
                // LastName: "54445"
                // RegisteredDate: "2020-05-04 15:51:55"
                // State: "hjkj"
                // Territory: "kjllk"
                // lessons_end_date: null
                // lessons_start_date: null
                // number_of_lessons: null
                // parent_email: null
                // parent_name: null
                // phone: null
                // program: null
                // role: "franchise_owner"
                // student_first_name: null
                // student_last_name: null
                // teacher_id: null

                // for (let i = 0; i < Filterd.length; i++) {
                //     let obj = [];

                //     obj = Franchise.filter(student => student.teacher_id === JSON.stringify(FranchiseOwner[i].user_id))

                //     Object.assign(Filterd[i], { TeacherNaem: JSON.stringify(obj.length) });
                //     // console.log(FranchiseOwner)
                // }



                runInAction(() => {
                    const AllUsers = res.data
                    // let Students = res.data.filter(student => student.role === 'student')

                    // for (let i = 0; i < Students.length; i++) {
                    //     const TeacherId = Students[i].teacher_id
                    //     const Franchise = res.data.filter(user => user.role === 'franchise_owner')
                    //     const GetTeacher = Franchise.filter(user => user.user_id.toString() === TeacherId.toString())

                    // Students[i].push(obj)
                    // Object.assign(Students[i], { TeacherData: GetTeacher });
                    // console.log(Students)
                    // Students[i].push(GetTeacher)
                    // }


                    // obj['original'] = Pics[i];
                    // obj['thumbnail'] = Pics[i];
                    // Buildingimages.push(obj);

                    // Object.assign(this.AllUsers, AllUsers)
                    // Object.assign(this.FilterdUserName, AllUsers)
                    // Object.assign(this.AllStudents, Students)
                    // console.log(this.AllStudents[0].TeacherData[0].FirstName)
                    // this.loading = false
                });
            })
            .catch(err => {

            })
    }




    @action
    CarRequests2021() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/1313`)
            .then(res => {
                const TableItems = res.data.metadata.carrequests[0]
                // console.log(res.data.metadata)
                let TableData = []
                for (let i = 0; i < TableItems; i++) {
                    let obj = {};
                    obj['date'] = res.data.metadata["carrequests_" + i + "_date"][0];
                    obj['id_num'] = res.data.metadata["carrequests_" + i + "_id"][0];
                    obj['choice1'] = res.data.metadata["carrequests_" + i + "_choice1"][0];
                    obj['choice2'] = res.data.metadata["carrequests_" + i + "_choice2"][0];
                    obj['sent_from'] = res.data.metadata["carrequests_" + i + "_sent_from"][0];
                    TableData.push(obj)
                }
                runInAction(() => {
                    this.loading = false;
                    this.FatchEnd = true;
                    this.CarRequests = TableData
                    // console.log(res.data)
                    // console.log(toJS(this.CarRequests))

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadLessons ERR')
                });
            })
    };




    @action
    ToolTips() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/1404`)
            .then(res => {
                // console.log(res.data.metadata)

                runInAction(() => {
                    this.year = res.data.metadata.year
                    this.seats = res.data.metadata.seats
                    this.propulsion = res.data.metadata.propulsion
                    this.gearbox = res.data.metadata.gearbox
                    this.engine_type = res.data.metadata.engine_type
                    this.number_of_pistons = res.data.metadata.number_of_pistons
                    this.volume = res.data.metadata.volume
                    this.maximum_power = res.data.metadata.maximum_power
                    this.rpm_for_maximum_power = res.data.metadata.rpm_for_maximum_power
                    this.maximum_torque = res.data.metadata.maximum_torque
                    this.rpm_for_maximum_torque = res.data.metadata.rpm_for_maximum_torque
                    this.acceleration = res.data.metadata.acceleration
                    this.maximum_speed = res.data.metadata.maximum_speed
                    this.fuel_consumption_urban = res.data.metadata.fuel_consumption_urban
                    this.Fuel_consumption_intercity = res.data.metadata.Fuel_consumption_intercity
                    this.Fuel_consumption_average = res.data.metadata.Fuel_consumption_average
                    this.degree_of_pollution = res.data.metadata.degree_of_pollution
                    this.crash_test = res.data.metadata.crash_test
                    this.brakes = res.data.metadata.brakes
                    this.air_bags = res.data.metadata.air_bags
                    this.disabling_a_passenger_airbag = res.data.metadata.disabling_a_passenger_airbag
                    this.isofix = res.data.metadata.isofix
                    this.air_pressure_sensors = res.data.metadata.air_pressure_sensors
                    this.autonomous_emergency_braking = res.data.metadata.autonomous_emergency_braking
                    this.pedestrian_identification = res.data.metadata.pedestrian_identification
                    this.lane_departure_control = res.data.metadata.lane_departure_control
                    this.two_wheeled_identification = res.data.metadata.two_wheeled_identification
                    this.vehicle_identification_in_dead_zone = res.data.metadata.vehicle_identification_in_dead_zone
                    this.cross_traffic_warning_from_behind = res.data.metadata.cross_traffic_warning_from_behind
                    this.automatic_high_beams = res.data.metadata.automatic_high_beams
                    this.signage_identification = res.data.metadata.signage_identification;
                    this.Width = res.data.metadata.Width
                    this.wheelbase = res.data.metadata.wheelbase
                    this.trunk_volume = res.data.metadata.trunk_volume
                    this.net_weight = res.data.metadata.net_weight
                    this.tire_dimensions = res.data.metadata.tire_dimensions
                    this.fuel_tank_capacity = res.data.metadata.fuel_tank_capacity
                    this.phone = res.data.metadata.phone
                    this.multimedia_screen = res.data.metadata.multimedia_screen
                    this.gps_navigation_system = res.data.metadata.gps_navigation_system
                    this.connectivity = res.data.metadata.connectivity
                    this.wireless_charging = res.data.metadata.wireless_charging
                    this.air_conditioning = res.data.metadata.air_conditioning
                    this.recreational_hoops = res.data.metadata.recreational_hoops
                    this.cruise_control = res.data.metadata.cruise_control
                    this.seats_electric_adjustment = res.data.metadata.seats_electric_adjustment
                    this.headlights = res.data.metadata.headlights
                    this.ighting_sensors = res.data.metadata.ighting_sensors
                    this.parking_aids = res.data.metadata.parking_aids
                    this.parking_cameras = res.data.metadata.parking_cameras
                    this.automatic_parking = res.data.metadata.automatic_parking
                    this.trip_computer = res.data.metadata.trip_computer
                    this.digital_dashboard = res.data.metadata.digital_dashboard
                    this.top_view = res.data.metadata.top_view
                    this.electric_folding_side_mirrors = res.data.metadata.electric_folding_side_mirrors
                    this.mirrors_against_glare = res.data.metadata.mirrors_against_glare
                    this.driving_mode_selector = res.data.metadata.driving_mode_selector
                    this.stop_and_drive_system_for_standing_engine_bleeding = res.data.metadata.stop_and_drive_system_for_standing_engine_bleeding
                    this.electric_handbrake = res.data.metadata.electric_handbrake
                    this.slope_control = res.data.metadata.slope_control
                    this.suspensions_and_dampers = res.data.metadata.suspensions_and_dampers
                    this.electric_trunk_door = res.data.metadata.electric_trunk_door


                });
                this.loading = false
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('ToolTips ERR')
                });
            })
    };











};



export default new SiteGlobal();




