import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';


import styles from './CarRequests2021.module.css';
import './CarRequests2021.css'

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import loadingBlue from '../../images/loading_blue.gif'

import { AiFillDelete } from 'react-icons/ai';
import { FaSort } from 'react-icons/fa';


@inject("adminPanel", "siteLinks")
@observer
class CarRequests2021 extends Component {


    constructor() {
        super();
        this.state = {
            popdelete: false,
            SelectedRaw: null,
            SelectedId: null,
            idNumber: '',
        };
    }

    componentDidMount() {

        this.props.adminPanel.CarRequests2021()

    }

    HandelOnChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        // this.props.adminPanel.filterData(value)

    }

    popdelete = (index, id) => {
        console.log(id)
        this.setState({ popdelete: true, SelectedRaw: index, SelectedId: id })
    }

    DeleteRow = () => {
        this.props.adminPanel.DeleteRow(this.state.SelectedRaw)
        this.setState({ popdelete: false, SelectedRaw: null })
    }

    CancelDelete = () => {
        this.setState({ popdelete: false, SelectedRaw: null })
    }

    render() {

        const TableData = this.props.adminPanel.CarRequests.map((person, index) => {

            let sentFrom = person.sent_from
            // console.log(sentFrom)
            // console.log(person.id_num)

            if (sentFrom == "carshowleas") {
                sentFrom = <a href="/">ליסינג עובדים</a>
            }
            if (sentFrom == "carshow") {
                sentFrom = <a href="/">רכבים צמודים</a>
            }
            if (sentFrom == "managers") {
                sentFrom = <a href="/">סמנכ"לים בכירים וחברי הנהלה</a>
            }
            if (sentFrom == "management") {
                sentFrom = <a href="/">מנהלים בכירים</a>
            }


            return (
                <>


                    <Tr className={styles.TrData}>
                        <Td>{person.id_num}</Td>
                        <Td>{ReactHtmlParser(person.choice1)}</Td>
                        <Td>{ReactHtmlParser(person.choice2)}</Td>
                        <Td>{person.date}</Td>
                        {/* <Td>  <Moment format='DD/MM/YYYY'>{person.date}</Moment></Td> */}
                        <Td>{sentFrom}</Td>
                        <Td onClick={this.popdelete.bind(this, person.key, person.id_num)} className={styles.remove}><AiFillDelete className={styles.Delicon} /></Td>
                    </Tr>


                </>
            )
        }
        );


        const FinalTable = <>


            <div className={styles.downloadAndSearch}>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="carshowtable"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="XLS הורדת קובץ" />

            </div>
            <div className={styles.TableContainer}>


                <div className="HiddenTableForDownload">
                    <Table id="carshowtable" className={styles.Table}>


                        <Tbody>
                            <Tr className={styles.Tr}>
                                <Th>תעודת זהות</Th>
                                <Th>רכב בעדיפות ראשונה</Th>
                                <Th>רכב בעדיפות שנייה</Th>
                                <Th>תאריך ביצוע</Th>
                                <Th>נשלח מאתר</Th>
                                <Th></Th>
                            </Tr>
                            {TableData}

                        </Tbody>
                    </Table>
                </div>

                <Table className={styles.Table}>
                    <Thead className={styles.Thead}>
                        <Tr className={styles.Tr}>

                            <Th className={styles.ThId}>
                                <div className={styles.tableWithSort}>
                                    תעודת זהות
                            <div onClick={this.props.adminPanel.SortBy.bind(this, 'id_num')}><FaSort className={styles.SortIcon} /></div>
                                </div>
                            </Th>
                            <Th className={styles.ThCar}>
                                <div className={styles.tableWithSort}>
                                    רכב בעדיפות ראשונה
                            <div onClick={this.props.adminPanel.SortBy.bind(this, 'choice1')}><FaSort className={styles.SortIcon} /></div>
                                </div>
                            </Th>

                            <Th className={styles.ThCar}>
                                <div className={styles.tableWithSort}>
                                    רכב בעדיפות שנייה
                            <div onClick={this.props.adminPanel.SortBy.bind(this, 'choice2')}><FaSort className={styles.SortIcon} /></div>
                                </div>
                            </Th>


                            <Th className={styles.ThDate}>
                                <div className={styles.tableWithSort}>
                                    תאריך ביצוע
                            <div onClick={this.props.adminPanel.SortBy.bind(this, 'date')}><FaSort className={styles.SortIcon} /></div>
                                </div>
                            </Th>
                            <Th className={styles.ThSite}>
                                <div className={styles.tableWithSort}>
                                    נשלח מאתר
                            <div onClick={this.props.adminPanel.SortBy.bind(this, 'sent_from')}><FaSort className={styles.SortIcon} /></div>
                                </div>
                            </Th>
                            <Th className={styles.ThDel}>מחק</Th>
                        </Tr>
                    </Thead>

                    <Tbody>

                        {TableData}

                    </Tbody>
                </Table>
            </div>
        </>

        const ConfirmDelete = <div className={styles.ConfirmDelete}>
            <div className={styles.TitleDelete}>למחוק שורה עבור תעודת זהות {this.state.SelectedId}?</div>
            <div className={styles.BtContainer}>
                <div onClick={this.DeleteRow} className={styles.BtDelete}>מחק</div>
                <div onClick={this.CancelDelete} className={styles.BtCancel}>ביטול</div>
            </div>
        </div>

        const Approved = <>
            <div className={styles.FlexContainer}>
                {this.state.popdelete ? ConfirmDelete : null}
                {this.props.adminPanel.loading ? <img className="loader" src={loadingBlue} alt="Loader" /> : FinalTable}
            </div>

        </>


        return (

            <>


                <div className={styles.FlexContainer}>
                    {this.state.popdelete ? ConfirmDelete : null}
                    {this.props.adminPanel.loading ? <img className="loader" src={loadingBlue} alt="Loader" /> : FinalTable}
                </div>

            </>
        );


    }
}

export default CarRequests2021;
