import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import styles from './CarDataSideBySide.module.css';

import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import { toJS } from 'mobx';
import { HiCheck } from 'react-icons/hi';
import { AiOutlineClose, AiFillStar, AiFillCloseSquare } from 'react-icons/ai';
import ImageGallery from 'react-image-gallery';
import Acordion from '../components/Acordion';

import AcordionItemSideBySide from '../components/AcordionItemSideBySide';

import PollutionSider from '../components/PollutionSider';
import CarDataSideBySideLoader from '../components/CarDataSideBySideLoader'

@inject('login', 'cars', 'singleCar', 'singleCar2')
@observer // REMOVE BRACKETS => @observer
class CarDataSideBySide extends Component {




    componentDidMount() {
        const CarId = this.props.CarId
        this.props.singleCar2.UpdateCar1(CarId)
    }



    UpdateAcordions = (event) => {
        this.props.singleCar.UpdeteMenuSideBySide(event)
        this.props.singleCar2.UpdeteMenuSideBySide(event)
    }


    resetVideo() {
        this.props.singleCar2.resetVideo()
    }



    onSlide() {
        this.resetVideo()
    }

    resetVideo() {
        this.props.singleCar2.resetVideo()
    }

    toggleShowVideo(url) {
        this.props.singleCar2.toggleShowVideo(url)
    }



    toggleShowVideo(url) {
        this.showVideo[url] = !Boolean(this.showVideo[url]);
        this.showVideo = this.showVideo

        if (this.showVideo[url]) {
            if (this.showPlayButton) {
                this.showGalleryPlayButton = false
            }

            if (this.showFullscreenButton) {
                this.showGalleryFullscreenButton = false
            }
        }
    }




    render() {


        const Title = <div className={styles.TitleContainer}>
            <div onClick={this.props.Close} className={styles.CloseBt}><AiFillCloseSquare /></div>
            <div className={styles.CarTitle}>{parse(this.props.singleCar2.Car1Title)}</div>
            <div className={styles.UnderCarTitle}>{this.props.singleCar2.Car1SmallText}</div>
        </div>

        const Gallery = <div className={styles.GallryContainer}>
            <ImageGallery
                ref={i => this._imageGallery = i}
                onSlide={this.onSlide.bind(this)}
                showFullscreenButton={false}
                showPlayButton={false}
                items={[...toJS(this.props.singleCar2.Car1Gallery)]}
                infinite={false}
                useBrowserFullscreen={false}
                useTranslate3D={true}
                showBullets={true}
                // isRTL={true}
                showThumbnails={false}
            // thumbnailPosition={"left"}
            />
        </div>


        const Car1Model = this.props.singleCar2.Car1Model.map((car, index) => {
            if (car.Title == 'שווי שימוש רציף')
                return null

            if (this.props.login.siteRealation == 'tzamotsite') {
                if (car.Title == 'supply')
                    return <div className={styles.supply}>{car.Value}</div>
            }
            if (this.props.login.siteRealation == 'managers') {
                if (car.Title == 'supply')
                    return <div className={styles.supply}>{car.Value}</div>
            }
            if (this.props.login.siteRealation == 'management') {
                if (car.Title == 'supply')
                    return <div className={styles.supply}>{car.Value}</div>
            }
            if (this.props.login.siteRealation == 'leassite') {
                if (car.Title == 'supply')
                    return null
            }

            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Engine = this.props.singleCar2.Car1Engine.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemSideBySide Title={car.Title} ValueAsIcon={true} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemSideBySide Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            if (car.Title == 'דרגת זיהום')
                return <AcordionItemSideBySide Title={car.Title} Value={<PollutionSider SideBySide={true} Degree={car.Value} />} />
            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Safty = this.props.singleCar2.Car1Safty.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemSideBySide Title={car.Title} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemSideBySide Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            if (car.Value == '5stars')
                return <AcordionItemSideBySide Title={car.Title} Value={<div className={styles.Starts}> <AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /> </div>} />
            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Size = this.props.singleCar2.Car1Size.map((car, index) => {
            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Multimedia = this.props.singleCar2.Car1Multimedia.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemSideBySide Title={car.Title} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemSideBySide Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Comfort = this.props.singleCar2.Car1Comfort.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemSideBySide Title={car.Title} ValueAsIcon={true} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemSideBySide Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }

        );



        const Car1Hybrid = this.props.singleCar.Car1Hybrid.map((car, index) => {
            if (car.Title == 'link')
                return <div className={styles.LinkFlexContainer}><div className={styles.LinkContainer}>
                    <div className={styles.LinkText}>{car.Value}</div>
                    <a href="https://www.gov.il/he/departments/guides/radiation_of_electrical_frequency?chapterIndex=2" target="_blank">
                        לחצו כאן
                    </a>
                </div>
                </div>
            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }

        );



        const ModelMuneSideBySide = this.props.singleCar2.ModelMuneSideBySide
        const EnginMuneSideBySide = this.props.singleCar2.EnginMuneSideBySide
        const CarSaftyMuneSideBySide = this.props.singleCar2.CarSaftyMuneSideBySide
        const SizesMuneSideBySide = this.props.singleCar2.SizesMuneSideBySide
        const MultimediaMuneSideBySide = this.props.singleCar2.MultimediaMuneSideBySide
        const ComfortMuneSideBySide = this.props.singleCar2.ComfortMuneSideBySide
        const MoreComfortMuneSideBySide = this.props.singleCar2.MoreComfortMuneSideBySide
        const MoreComfort2MuneSideBySide = this.props.singleCar2.MoreComfort2MuneSideBySide


        if (this.props.singleCar2.FatchEnd) {
            setInterval(() => {
                this.props.singleCar2.UpdateFadeIn()
            }, 0);
        }

        let CarDataFadeIn = {
            opacity: this.props.singleCar2.FadeIn,
            transition: '.5s'
        }

        let taxText = ''
        if (this.props.singleCar.IsHybrid) {
            taxText = <div className={styles.texText}>לאחר הטבת מס</div>
        }


        const carData = <div style={CarDataFadeIn}>
            <div className={styles.GlleryTitleMedolContainer}>
                {Title}
                <div>{this.props.singleCar2.TorenderVideo}</div>
                <div className={styles.SingleImage}><img src={this.props.singleCar2.SingleImage} /> </div>
                {Gallery}


                {/* מ חיר */}
                <div className={styles.ItemsContainer + ' Price'}>
                    <Acordion
                        open={true}
                        className='PriceTopAcordion'
                        triggerDisabled={true} >

                        <AcordionItemSideBySide
                            AcordionItemClass='priceHover'
                            Price={true}
                            PriceTitleClass={true}
                            PriceValueClass={true}
                            Title="שווי שימוש רציף"
                            Value={this.props.singleCar2.CarPrice + ' ₪'}
                            Taxtext={taxText}
                        />

                    </Acordion>
                </div>
                {/* מ חיר */}
                {/* 
                    {this.props.singleCar2.ShowModelTab ? */}
                <div className={styles.ItemsContainer + ' ModelContainer'}>
                    <Acordion
                        onTriggerOpening={this.UpdateAcordions.bind(this, 'ModelContainer')}
                        onTriggerClosing={this.UpdateAcordions.bind(this, 'ModelContainer')}
                        Title="דגם"
                        open={ModelMuneSideBySide}
                        className={styles.ModelAcordion}
                        triggerDisabled={false} >
                        {Car1Model}
                    </Acordion>
                </div>
                {/* :
                        null
                    } */}
            </div>

            {/* {this.props.singleCar2.ShowEnginlTab ? */}
            <div className={styles.ItemsContainer + ' EnginContainer'}>
                <Acordion
                    Title="מנוע וביצועים"
                    open={EnginMuneSideBySide}
                    onTriggerOpening={this.UpdateAcordions.bind(this, 'EnginContainer')}
                    onTriggerClosing={this.UpdateAcordions.bind(this, 'EnginContainer')}
                >
                    {Car1Engine}
                </Acordion>
            </div>
            {/* : null} */}

            {/* {this.props.singleCar2.ShowSaftylTab ? */}
            <div className={styles.ItemsContainer + ' SaftyContainer'}>
                <Acordion
                    Title="בטיחות רכב"
                    open={CarSaftyMuneSideBySide}
                    onTriggerOpening={this.UpdateAcordions.bind(this, 'SaftyContainer')}
                    onTriggerClosing={this.UpdateAcordions.bind(this, 'SaftyContainer')}
                >

                    {Car1Safty}
                </Acordion>
            </div>
            {/* : null} */}

            {/* {this.props.singleCar2.ShowSizelTab ? */}
            <div className={styles.ItemsContainer + ' SizesContainer'}>
                <Acordion
                    Title="מידות"
                    open={SizesMuneSideBySide}
                    onTriggerOpening={this.UpdateAcordions.bind(this, 'SizesContainer')}
                    onTriggerClosing={this.UpdateAcordions.bind(this, 'SizesContainer')}
                >

                    {Car1Size}

                </Acordion>
            </div>
            {/* : null} */}


            {/* {this.props.singleCar2.ShowMultimediaTab ? */}
            <div className={styles.ItemsContainer + ' MultimediaContainer'}>
                <Acordion
                    Title="מולטימדיה ותקשרות"
                    open={MultimediaMuneSideBySide}
                    onTriggerOpening={this.UpdateAcordions.bind(this, 'MultimediaContainer')}
                    onTriggerClosing={this.UpdateAcordions.bind(this, 'MultimediaContainer')}
                >

                    {Car1Multimedia}

                </Acordion>
            </div>

            {/* : null} */}

            {/* {this.props.singleCar2.ShowComfortTab ?
                    <> */}
            <div className={styles.ItemsContainer + ' ComfortContainer'}>
                <Acordion
                    Title="אבזור ונוחות"
                    open={ComfortMuneSideBySide}
                    onTriggerOpening={this.UpdateAcordions.bind(this, 'ComfortContainer')}
                    onTriggerClosing={this.UpdateAcordions.bind(this, 'ComfortContainer')}
                >
                    {Car1Comfort}

                </Acordion>
            </div>

            <div className={styles.ItemsContainer + ' ComfortContainer'}>
                <Acordion
                    Title="אבזור נוסף כלול במחיר"
                    open={MoreComfortMuneSideBySide}
                    onTriggerOpening={this.UpdateAcordions.bind(this, 'MoreComfortContainer')}
                    onTriggerClosing={this.UpdateAcordions.bind(this, 'MoreComfortContainer')}
                >
                    <div className={styles.MoreInfo}>
                        <div className={styles.More}> {this.props.singleCar2.Car1More}</div>
                    </div>
                </Acordion>
            </div>


            {this.props.singleCar2.IsHybrid ?
                <div className={styles.ItemsContainer + ' HybridContainer'}>
                    <Acordion Title="היברידי" open={true} >
                        {Car1Hybrid}

                    </Acordion>
                </div>
                : null
            }


        </div>
        // const SingleImage = [...toJS(this.props.singleCar2.Car1Gallery[0])]
        // console.log(SingleImage.original)

        return (


            <>

                <div style={{ position: "relative" }}>
                    <div style={{ opacity: this.props.singleCar2.FadeOut, transition: '0s', position: 'absolute', width: '100%' }}>
                        <CarDataSideBySideLoader />
                    </div>
                </div>
                { carData}

                {/* {this.props.singleCar2.loading ?
                    <CarDataSideBySideLoader />
                    :
                    carData

                } */}
            </>


        );


    }
}

export default withRouter(CarDataSideBySide);
