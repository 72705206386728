import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class LoginCheck extends Component {

    render() {

        // If enter With no ?m=
        if (this.props.PageRelation == null) {
            if (this.props.siteRealation == 'leassite') {
                return (<Redirect push to={`/main?m=carshow`} />)
            }
            if (this.props.siteRealation == 'tzamotsite') {
                return (<Redirect push to={`/main?m=carshow`} />)
            }
            if (this.props.siteRealation == 'managers') {
                return (<Redirect push to={`/managers?m=managers`} />)
            }
            if (this.props.siteRealation == 'management') {
                return (<Redirect push to={`/management?m=management`} />)
            }
        }

        // Only user with leassite or tzamotsite
        if (this.props.PageRelation == 'carshow') {
            if (this.props.siteRealation == 'managers') {
                return (<Redirect push to={`/managers?m=managers`} />)
            }
            if (this.props.siteRealation == 'management') {
                return (<Redirect push to={`/management?m=management`} />)
            }
        }

        // Only user with Managers
        if (this.props.PageRelation == 'managers') {
            console.log(this.props.siteRealation)
            if (this.props.siteRealation == 'leassite') {
                return (<Redirect push to={`/main?m=carshow`} />)
            }
            if (this.props.siteRealation == 'tzamotsite') {
                return (<Redirect push to={`/main?m=carshow`} />)
            }
            if (this.props.siteRealation == 'management') {
                return (<Redirect push to={`/management?m=management`} />)
            }
        }

        // Only user with management
        if (this.props.PageRelation == 'management') {
            if (this.props.siteRealation == 'leassite') {
                return (<Redirect push to={`/main?m=carshow`} />)
            }
            if (this.props.siteRealation == 'tzamotsite') {
                return (<Redirect push to={`/main?m=carshow`} />)
            }
            if (this.props.siteRealation == 'managers') {
                return (<Redirect push to={`/managers?m=managers`} />)
            }
        }

        // console.log(this.props.PageRelation)
        return (
            <>
                {this.props.loggedin ?
                    this.props.children
                    :
                    <Redirect push to={`/`} />
                }
            </>
        );


    }
}

export default LoginCheck;
