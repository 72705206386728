import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import styles from './AdminPanel.module.css';

import { withRouter, Link } from 'react-router-dom';

import HeaderAdmin from './HeaderAdmin';
import LoginCheck from '../../components/AdminLoginCheck';
import CarRequests2021 from './CarRequests2021';
import UsersList from './UsersList';
import AddUser from './AddUser';
import AddUsers from './AddUsers';
import DelUsers from './DelUsers';

@inject("adminPanel", "siteLinks", "login")
@observer
class AdminPanel extends Component {


    constructor() {
        super();
        this.state = {
            logoutTrigger: true
        };
    }

    componentDidMount() {

    }

    ChangePageContent = (toShow) => {
        this.props.adminPanel.ChangePageContent(toShow)
    }


    Logout = () => {
        this.props.login.Logout()
        this.setState({ logoutTrigger: !this.state.logoutTrigger })
    }
    HandelOnChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.props.adminPanel.FilterData(value)
    }



    render() {
        const URlSearch = this.props.location.search
        const ShowTab = new URLSearchParams(URlSearch).get('tab')
        this.props.adminPanel.ChangePageContent(ShowTab)







        return (

            <>
                <LoginCheck IsAdmin={this.props.login.adminPanel} siteRealation={this.props.login.siteRealation}>
                    <HeaderAdmin
                        MainLink='/'
                        HideSideBySide={true}
                        Logout={this.Logout}
                    >
                    </HeaderAdmin>

                    <div className={styles.FlexContainer}>

                        {this.props.adminPanel.ActiveCarRequests ?
                            (
                                <div className={styles.CarRequestsContainer}>
                                    <div className={styles.Title}>2021 טבלת הזמנות רכבים</div>

                                    <div className={styles.InputContainer}>
                                        <input
                                            placeholder="חפש תעודת זהות"
                                            type="number"
                                            className={styles.FromInput}
                                            name="idNumber"
                                            value={this.state.idNumber}
                                            onChange={this.HandelOnChange.bind(this)}
                                        />
                                    </div>

                                    <CarRequests2021 />
                                </div>
                            )
                            : null}


                        {this.props.adminPanel.ActiveUsers ?
                            (
                                <div className={styles.CarRequestsContainer}>

                                    <UsersList />
                                </div>
                            ) : null}

                        {this.props.adminPanel.ActiveAddUser ?
                            (
                                <div className={styles.CarRequestsContainer}>

                                    <AddUser />
                                </div>
                            ) : null}

                        {this.props.adminPanel.ActiveAddUsers ?
                            (
                                <div className={styles.CarRequestsContainer}>
                                    <AddUsers />
                                </div>
                            ) : null}
                        {this.props.adminPanel.ActiveDelUsers ?
                            (
                                <div className={styles.CarRequestsContainer}>
                                    <DelUsers />
                                </div>
                            ) : null}
                    </div>
                </LoginCheck>
            </>
        );


    }
}

export default withRouter(AdminPanel);
