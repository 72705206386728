import React, { Component, useRef } from 'react';
import styles from './AddUsers.module.css';
import { inject, observer } from 'mobx-react';
import { FixedSizeList as List } from "react-window";
import { toJS } from 'mobx';
import { withRouter, Link } from 'react-router-dom';

import { AiOutlineDownload } from 'react-icons/ai';
import loadingBlue from '../../images/loading_blue.gif'

@inject("adminPanel", "siteLinks")
@observer
class HeaderWithSlider extends Component {

    constructor(props) {
        super(props);
        this.File = React.createRef();
        this.state = {
            data: null,
            dataCopy: null,
            HaveErrInExcel: false
        };
    }


    inputchange = (event) => {
        const selectedFile = event.target.files[0]
        // console.log(selectedFile)
        let data = [{
            // "name": "jayanth",
            // "data": "scd",
            // "abc": "sdef"
        }]
        XLSX.utils.json_to_sheet(data, 'out.xlsx');
        if (selectedFile) {
            let fileReader = new FileReader();
            fileReader.readAsBinaryString(selectedFile);
            fileReader.onload = (event) => {
                let data = event.target.result;
                let workbook = XLSX.read(data, { type: "binary" });

                let dataStr = []
                workbook.SheetNames.forEach(sheet => {
                    let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);

                    dataStr.push(rowObject)

                });
                // console.log(dataStr[0])
                this.setState({ data: dataStr[0], dataCopy: dataStr[0], HaveErrInExcel: false })

            }
        }

    }


    SelectFile = () => {
        this.File.current.value = null
        this.File.current.click();
    }

    AddUsers = () => {
        this.props.adminPanel.AddUsers(this.state.data)
    }

    CheckId = (data, index) => {
        if (!data[index].id) {
            this.state.HaveErrInExcel ? null : this.setState({ HaveErrInExcel: true })
            return <div className={styles.id + ' ' + styles.err}>נתון לא קיים</div>
        }
        return (
            <div className={styles.id}>{data[index].id}</div>
        )
    }
    CheckSite = (data, index) => {
        let site = <div className={styles.site + ' ' + styles.err}>נתון שגוי</div>

        if (!data[index].site) {
            this.state.HaveErrInExcel ? null : this.setState({ HaveErrInExcel: true })
            site = <div className={styles.site + ' ' + styles.err}>נתון לא קיים</div>
            return site
        }

        if (data[index].site == 'leassite') {
            site = <div className={styles.site}>אתר ליסינג</div>
            return site
        }
        if (data[index].site == 'tzamotsite') {
            site = <div className={styles.site}>אתר צמודים</div>
            return site
        }
        if (data[index].site == 'managers') {
            site = <div className={styles.site}>אתר סמנכ"לים</div>
            return site
        }
        if (data[index].site == 'management') {
            site = <div className={styles.site}>אתר מנהלים בכירים</div>
            return site
        }

        if (data[index].site == 'leassite' || data[index].site == 'tzamotsite' || data[index].site == 'managers' || data[index].site != 'management') {
            this.state.HaveErrInExcel ? null : this.setState({ HaveErrInExcel: true })
        }

        return (
            site
        )
    }

    FilterData = (filterby) => {
        if (filterby == 'err') {
            const dataCopy = this.state.dataCopy
            const filterSite = dataCopy.filter(row => row.site != 'leassite' && row.site != 'tzamotsite' && row.site != 'managers' && row.site != 'management')
            const filterId = dataCopy.filter(row => row.id == null || row.id == '')
            let AllErr = [...filterSite, ...filterId]
            // remove Same Value Items
            const uniqueSet = new Set(AllErr)
            const backToArray = [...uniqueSet];
            this.setState({ data: backToArray })
        }
        if (filterby == 'showall') {
            const dataCopy = this.state.dataCopy
            this.setState({ data: dataCopy })
        }

    }


    CloseAdd = () => {
        this.props.adminPanel.HideAprovedAddUsers()
        this.setState({ data: null, dataCopy: null })
    }
    render() {

        const data = this.state.data

        let listData = ''
        if (data) {

            const Row = ({ index, key, style }) =>
                (
                    < div key={key} style={style} className={styles.RowContainer} >
                        {this.state.HaveErrInExcel ? <div className={styles.rowNum}>{data[index].__rowNum__ + 1}</div> : null}
                        {this.CheckId(data, index)}
                        {this.CheckSite(data, index)}

                    </div >

                )
            listData = <List
                className={styles.List + ' list'}
                height={700}
                itemCount={data.length}
                itemSize={60}
            >
                {Row}
            </List>
        }


        let table = ''
        data ?
            table = <div className={styles.RowsContainer}>
                {this.state.HaveErrInExcel ?
                    <div className={styles.BtContainer}> <div className={styles.BtAddDisable}>הוסף משתמשים</div> </div>
                    :
                    <div onClick={this.AddUsers} className={styles.BtContainer}> <div className={styles.BtAdd}>הוסף משתמשים</div> </div>
                }

                {this.state.HaveErrInExcel ?
                    <>
                        <div className={styles.errText}>
                            ישנן שגיאות בקובץ האקסל
                </div>
                        <div className={styles.FiltersContainer}>
                            <div className={styles.errClick} onClick={this.FilterData.bind(this, 'showall')}>הצג הכל</div>
                            <div className={styles.errClick} onClick={this.FilterData.bind(this, 'err')}>הצג שגיאות</div>
                        </div>
                    </>
                    : null}
                <div className={styles.RowContainer}>
                    <div className={styles.idTitle}>
                        מספר תעודת זהות
                        <br />
                        <span className={styles.info}>כותרת עמודה באקסל: id</span>
                    </div>
                    <div className={styles.siteTitle}>משוייך לאתר
                    <br />
                        <span className={styles.info}>כותרת עמודה באקסל: site</span>
                    </div>
                </div>
                {listData}

            </div> :
            null


        const AproveAdd = <div className={styles.ConfirmAdd}>
            <div className={styles.TitleAdd}>הפעולה עברה בהצלחה</div>
            <div className={styles.BtContainer}>
                <div onClick={this.CloseAdd} className={styles.BtCancel}>סגור</div>
            </div>
        </div>
        return (

            <>

                <div className={styles.Container}>
                    {this.props.adminPanel.loading ? <img className={'loader ' + styles.loader} src={loadingBlue} alt="Loader" /> : null}
                    {this.props.adminPanel.AprovedAddUsers ? AproveAdd : null}
                    <div className={styles.Title}>הוספת משתמשים מקובץ</div>
                    <a className={styles.example} href="https://rafael-car-show.co.il/db/wp-content/uploads/2020/12/add_users_example.xlsx" >
                        קובץ לדוגמא
                     <AiOutlineDownload className={styles.downloadicon} />

                    </a>
                    <input
                        ref={this.File}
                        name="file" onChange={this.inputchange.bind(this)} className={styles.InputFile} type="file" accept=".xls,.xlsx" />
                    <div className={styles.SelectFile} onClick={this.SelectFile}>בחר טבלת אקסל</div>

                    {table}
                </div>

            </>
        );

    }
}

export default withRouter(HeaderWithSlider);
