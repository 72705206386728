import React from 'react';

import { StyleRoot } from 'radium';

import Slider from 'react-input-slider';
import { Switch, Route } from "react-router-dom";

import LoginScreen from './Screens/LogInScreen_With_Id';
import MainScreen from './Screens/MainScreen';
import MainScreenManagers from './Screens/MainScreenManagersAllCars'
import MainScreenManagement from './Screens/MainScreenManagementAllCars'
import SingleCar from './Screens/SingleCar';
import SideBySide from './Screens/SideBySideFix';
import Test from './components/test';
import CarRequests2021 from './Screens/CarRequests2021';
import AdminPanels from './Screens/Admin/AdminPanels';
import './App.scss';


function App() {



  return (
    <StyleRoot>
      <div className="App">


        <Switch>

          <Route path="/test">
            <Test />
          </Route>

          <Route path="/single-car/:carid">
            <SingleCar />
          </Route>

          <Route path="/side-by-side/:carid_1/:carid_2">
            <SideBySide />
          </Route>

          <Route path="/main/">
            <MainScreen />
          </Route>


          {/* אתר מנהלים */}
          <Route path="/managers/">
            <MainScreenManagers />
          </Route>

          <Route path="/management/">
            <MainScreenManagement />
          </Route>
          {/* אתר מנהלים */}


          <Route path="/car-requests">
            <CarRequests2021 />
          </Route>

          <Route path="/admin-panel">
            <AdminPanels />
          </Route>

          <Route path="/">
            <LoginScreen />
          </Route>

        </Switch>

      </div>
    </StyleRoot>
  );
}

export default App;
