import React, { Component, Suspense } from 'react';
import { inject, observer } from 'mobx-react';

import styles from './MainScreen.module.css';

import { withRouter, Link } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';

import parse from 'html-react-parser';
import { toJS } from 'mobx';


import TopAnimationAndVideo from '../components/TopAnimationAndVideo';
import MainScreenFirstText from '../components/MainScreenFirstText';
import ButtonItem from '../components/ButtonItem';
import HeaderWithSlider from '../components/HeaderWithSliderSamancalim';
import Footer from '../components/Footer';

import FormPopUp from '../components/PopUpForm/FormPopUp';
import LoginCheck from '../components/LoginCheck';
import LoginHeader from '../components/LoginHeader'

import CarsLoaderMainScreen from '../components/CarsLoaderMainScreen'
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

/* wait 2000 ms to render component */
const AllCarsLazy = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("../components/LoaderComponent")), 2000)
        )
);


@inject('login', 'cars', 'siteLinks', 'fatchPage')
@observer
class HomeScreen extends Component {


    constructor() {
        super();
        this.state = {
            Dashbord: false,
            PopVideo: false,
            UserParameter: '',
            sliderx: 0,
            logoutTrigger: false,
            SiteRelation: ''

        };
    }




    componentDidMount() {
        const URlSearch = this.props.location.search
        const SiteRelation = new URLSearchParams(URlSearch).get('m')
        this.setState({ SiteRelation: SiteRelation })
        // this.props.cars.LoadCars()
        this.props.cars.LoadMainScreenManagersCars()



        if (this.props.lastLocation) {
            const LastLocation = this.props.lastLocation.pathname
            if (LastLocation.includes('single-car')) {
                this.ScrollToFilter()
            }
        }

        this.props.fatchPage.GetMainPage()
    }



    ScrollToFilter = () => {
        scroller.scrollTo('cars-filter', {
            duration: 1500,
            delay: 50,
            smooth: 'easeInOutQuint',
        })
    }

    Logout = () => {
        this.props.login.Logout()
        this.setState({ logoutTrigger: !this.state.logoutTrigger })
    }

    VideoPopup = () => {


        this.setState({ PopVideo: true })
    }



    ClearAll = (event) => {
        event.preventDefault();
        this.props.cars.ClearAll()
        this.setState({ sliderx: 0 })
    }



    ChangeToHebraw = (event) => {
        const category = toJS(event[0])
        switch (category) {

            case 'jeeponim':
                return (
                    "ג'יפונים"
                )
            case 'family':
                return (
                    "משפחתי"
                )
            case 'small':
                return (
                    "קטן"
                )
            case 'big':
                return (
                    "גדול 7 מקומות"
                )
            case 'hybrid':
                return (
                    "היברידי"
                )
            case 'managers':
                return (
                    "רכבי מנהלים"
                )
            case 'samancalim':
                return (
                    'רכבי סמנכ"לים'
                )

            default:
                return (
                    event
                )
        }

    }





    render() {


        const AllCarsDEMO =
            <>


                <Link to={`/single-car/111`} className={styles.SingleCarContainer}>
                    <div className={styles.MobileContainer}>
                        <div className={styles.MobilePic}>
                            <div className={styles.Category}>ג'יפונים</div>
                            <img src='https://rafael-car-show.co.il/db/wp-content/uploads/2020/10/car2.jpg' />
                        </div>

                        <div className={styles.NameAndDescription}>
                            <div className={styles.ModelName}>הונדה הונדה הונדה הונדה</div>
                            <div className={styles.SmallDescription}>bxbxbddd 50 bxbxbddd bxbxbddd 50 bxbxbddd </div>
                        </div>
                    </div>

                    <div className={styles.MobilePriceAndInfo}>
                        <div className={styles.MoreInfo}>עוד מידע</div>
                        <div className={styles.Divider}></div>
                        <div className={styles.PriceContainer}>
                            <div className={styles.PriceTitle}>שווי שימוש רציף</div>
                            <div className={styles.Price}><span>₪</span>2500</div>
                        </div>
                    </div>
                </Link>
                <Link to={`/single-car/111`} className={styles.SingleCarContainer}>
                    <div className={styles.MobileContainer}>
                        <div className={styles.MobilePic}>
                            <div className={styles.Category}>ג'יפונים</div>
                            <img src='https://rafael-car-show.co.il/db/wp-content/uploads/2020/10/car2.jpg' />
                        </div>

                        <div className={styles.NameAndDescription}>
                            <div className={styles.ModelName}>הונדה</div>
                            <div className={styles.SmallDescription}>bxbxbddd 50</div>
                        </div>
                    </div>

                    <div className={styles.MobilePriceAndInfo}>
                        <div className={styles.MoreInfo}>עוד מידע</div>
                        <div className={styles.Divider}></div>
                        <div className={styles.PriceContainer}>
                            <div className={styles.PriceTitle}>שווי שימוש רציף</div>
                            <div className={styles.Price}><span>₪</span>2500</div>
                        </div>
                    </div>
                </Link>

                <Link to={`/single-car/111`} className={styles.SingleCarContainer}>
                    <div className={styles.MobileContainer}>
                        <div className={styles.MobilePic}>
                            <div className={styles.Category}>ג'יפונים</div>
                            <img src='https://rafael-car-show.co.il/db/wp-content/uploads/2020/10/car2.jpg' />
                        </div>

                        <div className={styles.NameAndDescription}>
                            <div className={styles.ModelName}>הונדה</div>
                            <div className={styles.SmallDescription}>bxbxbddd 50</div>
                        </div>
                    </div>

                    <div className={styles.MobilePriceAndInfo}>
                        <div className={styles.MoreInfo}>עוד מידע</div>
                        <div className={styles.Divider}></div>
                        <div className={styles.PriceContainer}>
                            <div className={styles.PriceTitle}>שווי שימוש רציף</div>
                            <div className={styles.Price}><span>₪</span>2500</div>
                        </div>
                    </div>
                </Link>

                <Link to={`/single-car/111`} className={styles.SingleCarContainer}>
                    <div className={styles.MobileContainer}>
                        <div className={styles.MobilePic}>
                            <div className={styles.Category}>ג'יפונים</div>
                            <img src='https://rafael-car-show.co.il/db/wp-content/uploads/2020/10/car2.jpg' />
                        </div>

                        <div className={styles.NameAndDescription}>
                            <div className={styles.ModelName}>הונדה</div>
                            <div className={styles.SmallDescription}>bxbxbddd 50</div>
                        </div>
                    </div>

                    <div className={styles.MobilePriceAndInfo}>
                        <div className={styles.MoreInfo}>עוד מידע</div>
                        <div className={styles.Divider}></div>
                        <div className={styles.PriceContainer}>
                            <div className={styles.PriceTitle}>שווי שימוש רציף</div>
                            <div className={styles.Price}><span>₪</span>2500</div>
                        </div>
                    </div>
                </Link>

            </>








        const AllCars = this.props.cars.CarsToShow.map((car, index) =>
            <Link key={index} to={`/single-car/${car.id}?m=${this.state.SiteRelation}`} className={styles.SingleCarContainer}>
                <div className={styles.MobileContainer}>
                    <div className={styles.MobilePic}>
                        <div className={styles.Category}>{this.ChangeToHebraw(car.metadata.category)}</div>
                        {/* <img src='https://rafael-car-show.co.il/db/wp-content/uploads/2020/10/car2.jpg' /> */}
                        {/* <img src={toJS(car.media_0_gallery[0])} /> */}
                        <img src={toJS(car.featured_url)} />
                    </div>

                    <div className={styles.NameAndDescription}>
                        <div className={styles.ModelName}>{parse(car.title.rendered)}</div>
                        <div className={styles.SmallDescription}>{toJS(car.metadata.smalltitle)}</div>
                    </div>
                </div>

                <div className={styles.MobilePriceAndInfo}>
                    <div className={styles.MoreInfo}>עוד מידע</div>
                    <div className={styles.Divider}></div>
                    <div className={styles.PriceContainer}>
                        <div className={styles.PriceTitle}>שווי שימוש רציף</div>
                        <div className={styles.Price}><span>₪</span>{toJS(car.metadata.leasing_price[0])}</div>
                    </div>
                </div>
            </Link>
        );




        const CarsFilter =
            <div id='cars-filter' className={styles.FilterCarsContainer}>
                <div>
                    {/* <ButtonItem
                        Active={this.props.cars.all}
                        Image={this.props.siteLinks.all_gray}
                        HoverImage={this.props.siteLinks.all_animation}
                        ActiveImage={this.props.siteLinks.all_gold}
                        FilterClick={this.props.cars.CarClicked.bind(this, 'all')}
                        SrparatorLeft={true}
                    /> */}

                    <ButtonItem
                        Active={this.props.cars.samancalim}
                        Image={this.props.siteLinks.Samancalim_gray}
                        HoverImage={this.props.siteLinks.Samancalim_animation}
                        ActiveImage={this.props.siteLinks.Samancalim_gold}
                        FilterClick={this.props.cars.CarClicked.bind(this, 'samancalim')}
                        SrparatorLeft={true}
                    />

                    <ButtonItem
                        Active={this.props.cars.managers}
                        Image={this.props.siteLinks.Managers_gray}
                        HoverImage={this.props.siteLinks.Managers_animation}
                        ActiveImage={this.props.siteLinks.Managers_gold}
                        FilterClick={this.props.cars.CarClicked.bind(this, 'managers')}
                        SrparatorLeft={true}

                    />

                    <ButtonItem
                        Active={this.props.cars.jeeponim}
                        Image={this.props.siteLinks.Jeeps_gray}
                        HoverImage={this.props.siteLinks.Jeeps_animation}
                        ActiveImage={this.props.siteLinks.Jeeps_gold}
                        FilterClick={this.props.cars.CarClicked.bind(this, 'jeeponim')}
                        SrparatorLeft={true}
                        SparatorLeftclassName="FamilySparator"
                    />


                </div>
                <div>
                    <ButtonItem
                        Active={this.props.cars.family}
                        Image={this.props.siteLinks.Family_gray}
                        HoverImage={this.props.siteLinks.Family_animation}
                        ActiveImage={this.props.siteLinks.Family_gold}
                        FilterClick={this.props.cars.CarClicked.bind(this, 'family')}
                        SrparatorLeft={true}

                    />

                    <ButtonItem
                        Active={this.props.cars.big}
                        Image={this.props.siteLinks.Big_gray}
                        HoverImage={this.props.siteLinks.Big_animation}
                        ActiveImage={this.props.siteLinks.Big_gold}
                        FilterClick={this.props.cars.CarClicked.bind(this, 'big')}
                        SrparatorLeft={true}
                    />

                    <ButtonItem
                        Active={this.props.cars.hybrid}
                        Image={this.props.siteLinks.Hybrid_gray}
                        HoverImage={this.props.siteLinks.Hybrid_animation}
                        ActiveImage={this.props.siteLinks.Hybrid_gold}
                        FilterClick={this.props.cars.CarClicked.bind(this, 'hybrid')}
                    />
                </div>

            </div>


        if (this.props.cars.FatchEnd) {
            this.props.cars.UpdateFadeIn()
        }
        let CarDataFadeIn = {
            opacity: this.props.cars.FadeIn,
            transition: '1s',


        }

        const URlSearch = this.props.location.search
        const IsManager = new URLSearchParams(URlSearch).get('m')

        // console.log(this.props.location)
        return (



            <>
                <LoginCheck loggedin={this.props.login.token} siteRealation={this.props.login.siteRealation} PageRelation={IsManager}>

                    <FormPopUp IsManager={IsManager} IdNumber={this.props.login.username} logout={this.Logout} />

                    <LoginHeader AdminPanel={this.props.login.adminPanel} IdNumber={this.props.login.username} onClick={this.Logout} />

                    <TopAnimationAndVideo
                        onClick={this.VideoPopup}
                        VimeoId='468551838'
                        PopupVideo='473100033' //RAFAEL MOVIE
                        MobileVimeoId='471538266'
                    />

                    {/* <MainScreenFirstText
                    bgImage={this.props.siteLinks.leather_Texture}
                    Title={this.props.fatchPage.MainPageTitle}
                    BodyText={this.props.fatchPage.MainPageText}
                /> */}

                    {CarsFilter}

                    <HeaderWithSlider
                        SideBySideLink={`/side-by-side/0/0?m=managers`}
                        x={this.props.cars.SliderX}
                        onChange={this.props.cars.SliderChange}
                        options={toJS(this.props.cars.ManufacturersToShow)}
                        selectedOption={this.props.cars.selectedOption}
                        handleManufacturerChange={this.props.cars.handleManufacturerChange}
                        ClearAll={this.ClearAll}
                        ShowAllCars={this.props.cars.CarClicked.bind(this, 'all')}
                    />




                    {/* <Link to={`/single-car`}>
                        <div>fdsfdsfd</div>
                    </Link> */}
                    <div className={styles.CarsFlexContainer}>



                        <div className={styles.CarsContainer}>
                            {
                                this.props.cars.loading
                                    ?
                                    <CarsLoaderMainScreen />
                                    :
                                    <Suspense fallback={<CarsLoaderMainScreen />}>
                                        <AllCarsLazy>
                                            {AllCars}
                                        </AllCarsLazy>
                                    </Suspense>
                            }

                        </div>

                    </div>


                    <Footer />

                </LoginCheck>
            </>
        );


    }
}

export default withLastLocation(withRouter(HomeScreen));
