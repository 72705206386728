import React from 'react';
import { observable, configure, runInAction, action, toJS } from 'mobx';
import { Redirect } from 'react-router-dom';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import moment from 'moment';
import axios from 'axios';
import clientConfig from '../client-config';
import parse from 'html-react-parser';
import LogInScreen from '../Screens/LogInScreen';

configure({ enforceActions: true });


class cars {
    @observable loading = false
    @observable FatchEnd = false
    @observable AllCars = []

    @observable CarsToShow1 = []
    @observable CarsToShow2 = []

    @observable CarsToShowCopy1 = []
    @observable CarsToShowCopy2 = []

    @observable ListOfCarsToShow1 = []
    @observable ListOfCarsToShow2 = []

    @observable ListOf_All_CarsToShow1 = []
    @observable ListOf_All_CarsToShow2 = []

    @observable selectedOption1 = null
    @observable selectedOption2 = null

    @observable ManufacturersToShow1 = []
    @observable ManufacturersToShow2 = []

    @observable CarselectedOption1 = null
    @observable CarselectedOption2 = null

    @observable SelectedCarName1 = ''
    @observable SelectedCarName2 = ''


    @observable FormSendButtonActive = false
    @observable FormIdNumber = ""
    @observable FormCarChoice1 = ""
    @observable FormCarChoice2 = ""
    @observable FormData = moment().format('YMMDD')
    @observable ShowFromSentText = false

    @observable WordpressId = '20' //PARENT PAGE OF ALL CARS PAGES



    @action HandelPopUpClose = () => {
        this.FormIdNumber = ""
        this.ShowFromSentText = false
        this.SelectedCarName1 = null
        this.SelectedCarName2 = null

        this.CarselectedOption1 = null
        this.CarselectedOption2 = null

        this.selectedOption1 = null
        this.selectedOption2 = null

        this.FormSendButtonActive = false
    }

    @action
    HandleIDValOnChange = (event) => {


        // this[event.target.name] = event.target.value
        this.FormIdNumber = event.target.value

        if (this.CarselectedOption1 && this.CarselectedOption2 && this.FormIdNumber != '') {
            this.FormSendButtonActive = true
        }
        else this.FormSendButtonActive = false


    };




    @action
    CarsUpdate1 = () => {
        const CarsNames1 = [];
        this.CarsToShow1.map((car) => {
            let obj = {};
            obj['value'] = car.id;
            obj['label'] = parse(car.title.rendered);
            obj['manufacturer'] = car.metadata.manufacturer[0];
            CarsNames1.push(obj)
        }
        );
        this.ListOfCarsToShow1 = CarsNames1
        this.ListOf_All_CarsToShow1 = CarsNames1
    };
    @action
    CarsUpdate2 = () => {
        const CarsNames2 = [];
        this.CarsToShow2.map((car) => {
            let obj = {};
            obj['value'] = car.id;
            obj['label'] = parse(car.title.rendered);
            obj['manufacturer'] = car.metadata.manufacturer[0];
            CarsNames2.push(obj)
        }
        );
        this.ListOfCarsToShow2 = CarsNames2
        this.ListOf_All_CarsToShow2 = CarsNames2
    };


    @action
    HandleManufacturerChange1 = selectedOption => {
        // Keep car value
        this.CarselectedOption1 = this.SelectedCarName1
        this.CarselectedOption2 = this.SelectedCarName2

        this.selectedOption1 = selectedOption
        const cars = (toJS(this.ListOf_All_CarsToShow1))
        const FilterByManufacturer = cars.filter(page => page.manufacturer === selectedOption)
        this.ListOfCarsToShow1 = FilterByManufacturer
    };

    @action
    HandleManufacturerChange2 = selectedOption => {
        // Keep car value
        this.CarselectedOption1 = this.SelectedCarName1
        this.CarselectedOption2 = this.SelectedCarName2

        this.selectedOption2 = selectedOption
        const cars = (toJS(this.ListOf_All_CarsToShow2))
        const FilterByManufacturer = cars.filter(page => page.manufacturer === selectedOption)
        this.ListOfCarsToShow2 = FilterByManufacturer
    };



    FilterManufacturers1() {
        const Manufacturers = [];

        this.CarsToShow1.map((car) =>
            Manufacturers.push(
                car.metadata.manufacturer[0]
            ));
        // remove Same Value Items
        const uniqueSet = new Set(Manufacturers)
        const backToArray = [...uniqueSet];
        let Options = [];
        // console.log(backToArray + ' :יצרנים לסינון')
        backToArray.map((item) => {
            if (item == "toyota") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'טויוטה';
                obj['order'] = '1';
                Options.push(obj);

            };
            if (item == "hyundai") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'יונדאי';
                obj['order'] = '2';
                Options.push(obj);

            };
            if (item == "mazda") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = "מאזדה";
                obj['order'] = '3';
                Options.push(obj);

            };
            if (item == "mitsubishi") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'מיצובישי';
                obj['order'] = '4';
                Options.push(obj);

            };
            if (item == "nissan") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'ניסאן';
                obj['order'] = '5';
                Options.push(obj);

            };
            if (item == "seat") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סיאט';
                obj['order'] = '6';
                Options.push(obj);
            };
            if (item == "citroen") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סיטרואן';
                obj['order'] = '7';
                Options.push(obj);
            };
            if (item == "skoda") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סקודה';
                obj['order'] = '8';
                Options.push(obj);

            };
            if (item == "volkswagen") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'פולקסווגן';
                obj['order'] = '9';
                Options.push(obj);

            };
            if (item == "ford") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'פורד';
                obj['order'] = '10';
                Options.push(obj);

            };
            if (item == "peugeot") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = "פיג'ו";
                obj['order'] = '11';
                Options.push(obj);

            };
            if (item == "kia") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'קיה';
                obj['order'] = '12';
                Options.push(obj);

            };

            if (item == "renault") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'רנו';
                obj['order'] = '13';
                Options.push(obj);

            };
            this.ManufacturersToShow1 = Options
        },
        );
        const CopyToSort = toJS(this.ManufacturersToShow1)
        CopyToSort.sort((a, b) => a.order - b.order);
        this.ManufacturersToShow1 = CopyToSort
    }
    FilterManufacturers2() {
        const Manufacturers = [];

        this.CarsToShow2.map((car) =>
            Manufacturers.push(
                car.metadata.manufacturer[0]
            ));
        // remove Same Value Items
        const uniqueSet = new Set(Manufacturers)
        const backToArray = [...uniqueSet];
        let Options = [];
        // console.log(backToArray + ' :יצרנים לסינון')
        backToArray.map((item) => {
            if (item == "toyota") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'טויוטה';
                obj['order'] = '1';
                Options.push(obj);

            };
            if (item == "hyundai") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'יונדאי';
                obj['order'] = '2';
                Options.push(obj);

            };
            if (item == "mazda") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = "מאזדה";
                obj['order'] = '3';
                Options.push(obj);

            };
            if (item == "mitsubishi") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'מיצובישי';
                obj['order'] = '4';
                Options.push(obj);

            };
            if (item == "nissan") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'ניסאן';
                obj['order'] = '5';
                Options.push(obj);

            };
            if (item == "seat") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סיאט';
                obj['order'] = '6';
                Options.push(obj);
            };
            if (item == "citroen") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סיטרואן';
                obj['order'] = '7';
                Options.push(obj);
            };
            if (item == "skoda") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סקודה';
                obj['order'] = '8';
                Options.push(obj);

            };
            if (item == "volkswagen") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'פולקסווגן';
                obj['order'] = '9';
                Options.push(obj);

            };
            if (item == "ford") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'פורד';
                obj['order'] = '10';
                Options.push(obj);

            };
            if (item == "peugeot") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = "פיג'ו";
                obj['order'] = '11';
                Options.push(obj);

            };
            if (item == "kia") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'קיה';
                obj['order'] = '12';
                Options.push(obj);

            };

            if (item == "renault") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'רנו';
                obj['order'] = '13';
                Options.push(obj);

            };
            this.ManufacturersToShow2 = Options
        },
        );
        const CopyToSort = toJS(this.ManufacturersToShow2)
        CopyToSort.sort((a, b) => a.order - b.order);
        this.ManufacturersToShow2 = CopyToSort
    }



    @action
    HandleCarChange1 = (carid) => {
        const AllCars = [...toJS(this.AllCars)]
        const FilterCar = AllCars.filter(page => page.id === carid);
        const GetCarName = FilterCar[0].title.rendered
        this.FormCarChoice1 = GetCarName

        this.SelectedCarName1 = carid
        this.CarselectedOption1 = carid

        if (this.CarselectedOption1 && this.CarselectedOption2) {
            this.FormSendButtonActive = true
        }
        // console.log(this.FormSendButtonActive)

    };
    @action
    HandleCarChange2 = (carid) => {
        const AllCars = [...toJS(this.AllCars)]
        const FilterCar = AllCars.filter(page => page.id === carid);
        const GetCarName = FilterCar[0].title.rendered
        this.FormCarChoice2 = GetCarName

        this.SelectedCarName2 = carid
        this.CarselectedOption2 = carid

        if (this.CarselectedOption1 && this.CarselectedOption2) {
            this.FormSendButtonActive = true
        }
        console.log(this.FormSendButtonActive)
    };


    @action
    LoadCars(FilterBy) {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages?parent=${this.WordpressId}&per_page=100`)
            .then(res => {
                let AllCars = []

                // ChangeBeforBuild!!!!!----------------------------------------------------------------------
                // if (FilterBy == 'carshow') {
                //     AllCars = res.data.filter(page => page.metadata.les_site[0] === "1");
                // }
                if (FilterBy == 'carshow') {
                    AllCars = res.data.filter(page => page.metadata.tzamod_site[0] === "1");
                }
                // ChangeBeforBuild!!!!!----------------------------------------------------------------------

                if (FilterBy == 'management') {
                    const AllCars_tzamod = res.data.filter(page => page.metadata.tzamod_site[0] === "1");
                    const AllCars_menahlim = res.data.filter(page => page.metadata.tzamod_menahlim_bechirim_site[0] === "1");
                    AllCars = [...AllCars_tzamod, ...AllCars_menahlim]
                }

                if (FilterBy == 'managers') {
                    const AllCars_tzamod = res.data.filter(page => page.metadata.tzamod_site[0] === "1");
                    const AllCars_menahlim = res.data.filter(page => page.metadata.tzamod_menahlim_bechirim_site[0] === "1");
                    const AllCars_samankalim = res.data.filter(page => page.metadata.tzamod_samankalim_site[0] === "1");
                    AllCars = [...AllCars_samankalim, ...AllCars_tzamod, ...AllCars_menahlim]
                }


                const jeeponim = AllCars.filter(page => page.metadata.category[0] === "jeeponim");
                const family = AllCars.filter(page => page.metadata.category[0] === "family");
                const small = AllCars.filter(page => page.metadata.category[0] === "small");
                const big = AllCars.filter(page => page.metadata.category[0] === "big");
                const hybrid = AllCars.filter(page => page.metadata.category[0] === "hybrid");
                const managers = AllCars.filter(page => page.metadata.category[0] === "managers");

                let SortedCars = [...managers, ...jeeponim, ...family, ...small, ...big, ...hybrid]

                // console.log('SortedCars')
                runInAction(() => {
                    this.loading = false;
                    this.AllCars = SortedCars

                    this.CarsToShow1 = SortedCars
                    this.CarsToShow2 = SortedCars


                    this.FilterManufacturers1()
                    this.FilterManufacturers2()

                    this.CarsUpdate1()
                    this.CarsUpdate2()




                    this.FatchEnd = true;

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadLessons ERR')
                });
            })

    };







    @action
    SendForm(FormIdNumber, FormCarChoice1, FormCarChoice2, FormData, FormSiteLocation, IsLeasSite) {
        console.log(this.FormIdNumber)
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;

        let From = FormSiteLocation
        if (From == 'carshow') {
            IsLeasSite ? From = 'carshowleas' : null
        }

        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.PageId = 1739
        data_to_post.FormIdNumber = FormIdNumber
        data_to_post.FormCarChoice1 = FormCarChoice1
        data_to_post.FormCarChoice2 = FormCarChoice2
        data_to_post.FormData = FormData
        data_to_post.FormSiteLocation = From

        this.loading = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/car_requests`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {

                // console.log(res.data)



                runInAction(() => {
                    this.loading = false
                    this.ShowFromSentText = true
                });
            })
            .catch(err => {

            })
    }







};







export default new cars();





