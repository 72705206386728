import { observable, configure, runInAction, action, toJS } from 'mobx';
import axios from 'axios';
import clientConfig from '../client-config';
import Cookies from 'universal-cookie';

configure({ enforceActions: true });


class Login {
    @observable loading = false

    @observable loggedIn = false
    @observable token = null
    @observable username = ''
    @observable siteRealation = ''
    @observable adminPanel = false
    @observable ShowlogErr = false
    @observable LogErrMessage = 'נא לאשר שקראת והבנת'
    @observable firstLogin = ''


    constructor() {
        const cookies = new Cookies();
        if (cookies.get('token')) {
            this.loggedIn = cookies.get('loggedIn')
            this.token = cookies.get('token')
            this.username = cookies.get('username')
            this.siteRealation = cookies.get('site_realation')
            this.adminPanel = this.stringToBoolean(cookies.get('admin_panel'))
            this.firstLogin = this.stringToBoolean(cookies.get('FirstLogin'))
        }

    };


    @action
    SetFirstLoginEnd() {
        const cookies = new Cookies();
        cookies.set('FirstLogin', false, { path: '/' });
        this.firstLogin = false
    }


    @action
    SetErrMessage(message) {
        this.LogErrMessage = message
        this.ShowlogErr = true
    }

    @action
    HideErr() {
        this.ShowlogErr = false
    }


    @action
    Logout() {
        const cookies = new Cookies();

        cookies.remove('loggedIn')
        cookies.remove('token')
        cookies.remove('username')
        cookies.remove('site_realation')
        cookies.remove('FirstLogin')
        cookies.remove('admin_panel')

        this.loggedIn = false
        this.token = null
        this.username = ''
        this.siteRealation = ''
        this.ShowlogErr = false
        this.adminPanel = false


    }

    @action
    stringToBoolean(string) {
        switch (string.toLowerCase().trim()) {
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(string);
        }
    }

    @action
    Login(username) {

        const wordPressSiteUrl = clientConfig.siteUrl;
        const loginData = {
            username: username,
            password: username,
        };

        this.loading = true

        axios.post(`${wordPressSiteUrl}/wp-json/jwt-auth/v1/token`, loginData)
            .then(res => {
                // console.log(res.data)
                const adminpanel = res.data.admin_panel
                const isAdmin = this.stringToBoolean(adminpanel)

                runInAction(() => {
                    const cookies = new Cookies();
                    cookies.set('loggedIn', true, { path: '/' }); //path to be able to see from all pages
                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('username', res.data.user_nicename, { path: '/' });
                    cookies.set('site_realation', res.data.site_realation, { path: '/' });
                    cookies.set('admin_panel', isAdmin, { path: '/' });
                    cookies.set('FirstLogin', true, { path: '/' });

                    this.loggedIn = cookies.get('loggedIn')
                    this.token = cookies.get('token')
                    this.username = cookies.get('username')
                    this.siteRealation = cookies.get('site_realation')
                    this.adminPanel = this.stringToBoolean(cookies.get('admin_panel'))
                    this.firstLogin = cookies.get('FirstLogin')
                   
                    // console.log('loggedIn: ' + this.loggedIn)
                    // console.log('token: ' + this.token)
                    // console.log('username: ' + this.username)
                    // console.log('siteRealation: ' + this.siteRealation)
                    // console.log(this.adminPanel)
                    // console.log(this.firstLogin)

                });
                this.loading = false
                this.ShowlogErr = false
                this.LogErrMessage = ''
            })


            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    this.ShowlogErr = true
                    this.LogErrMessage = 'תעודת זהות שגויה או לא רשומה'
                    console.log('Login ERR')
                });
            })
    };















};



export default new Login();




