import React from 'react';
import { observable, configure, runInAction, action, toJS } from 'mobx';
import { Redirect } from 'react-router-dom';

import axios from 'axios';
import clientConfig from '../client-config';
import parse from 'html-react-parser';

configure({ enforceActions: true });


class cars2 {
    @observable loading = false
    @observable FatchEnd = false
    @observable AllCars = []
    @observable CarsToShow = []
    @observable CarsToShowCopy = []

    @observable selectedOption = null
    @observable selectedOption2 = null
    @observable ManufacturersToShow = []
    @observable ManufacturersToShow2 = []

    @observable SideBySideSelected = false

    @observable CarselectedOption = null

    @observable Car2selectedOption = null
    @observable ListOfCarsToShow = []
    @observable ListOfCarsToShow2 = []
    @observable ListOf_All_CarsToShow = []

    // Car1
    @observable Car1selectedOption = null
    @observable ListOfCarsToShow1 = []
    @observable ListOf_All_CarsToShow1 = []


    @observable FamilyCars = []
    @observable WordpressId = '20'

    @observable FirstTimeSelectedOption = true
    @observable selectedCarType = 'all'

    @observable all = true
    @observable jeeponim = false
    @observable family = false
    @observable small = false
    @observable big = false
    @observable hybrid = false

    @observable SliderX = 0

    @observable SelectesValu2 = false

    @observable Car1id = '0'
    @observable Car2id = '0'

    // constructor() { this.LoadCars() };




    @action
    SideBySidehandleManufacturerChange = selectedOption => {
        this.selectedOption = selectedOption
        this.ListOfCarsToShow = this.ListOf_All_CarsToShow
        const FilterByManufacturer = this.ListOfCarsToShow.filter(page => page.manufacturer === selectedOption)
        this.ListOfCarsToShow = FilterByManufacturer
    };



    @action
    SideBySidehandleCarChange = (currnetCar2, Car_selectedOption) => {
        console.log(currnetCar2)
        this.CarselectedOption = Car_selectedOption

        this.Car2id = Car_selectedOption
        this.Car1id = Car_selectedOption
        this.SideBySideSelected = true
    };

    // @action
    // SideBySidehandleCarChange = (selectedOption, car1) => {

    //     this.CarselectedOption = car1

    //     this.Car2id = selectedOption
    //     this.Car1id = car1
    // };


    @action
    UpdateCarsId = (selectedOption, car1) => {
        // this.CarselectedOption = car1
        this.Car1id = selectedOption
        this.Car2id = car1
    };


    // @action
    // UpdateCarsId = (car1, car2) => {
    //     this.Car1id = car1
    //     this.Car2id = car2
    // };

    @action
    SideBySideCarsUpdate = selectedOption => {
        const CarsNames = [];
        this.CarsToShow.map((car) => {
            let obj = {};
            obj['value'] = car.id;
            obj['label'] = parse(car.title.rendered);
            obj['manufacturer'] = car.metadata.manufacturer[0];
            CarsNames.push(obj)
        }
        );
        this.ListOfCarsToShow = CarsNames
        this.ListOf_All_CarsToShow = CarsNames
    };




    @action
    SliderChange = (event) => {
        this.SliderX = event.x

        let FilterCategory = []
        this.all ? FilterCategory = this.AllCars : null
        this.jeeponim ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'jeeponim') : null
        this.family ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'family') : null
        this.small ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'small') : null
        this.big ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'big') : null
        this.hybrid ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'hybrid') : null


        let FilterPrices = []
        if (event.x === 0) {
            FilterPrices = FilterCategory
        }
        if (event.x === 1) {
            FilterPrices = FilterCategory.filter(page => parseInt(page.metadata.leasing_price[0]) <= 2500);
        }
        if (event.x === 2) {
            const Range1 = FilterCategory.filter(page => parseInt(page.metadata.leasing_price[0]) >= 2501);
            FilterPrices = Range1.filter(page => parseInt(page.metadata.leasing_price[0]) <= 3000);
        }
        if (event.x === 3) {
            const Range1 = FilterCategory.filter(page => parseInt(page.metadata.leasing_price[0]) >= 3001);
            FilterPrices = Range1.filter(page => parseInt(page.metadata.leasing_price[0]) <= 3500);
        }
        if (event.x === 4) {
            const Range1 = FilterCategory.filter(page => parseInt(page.metadata.leasing_price[0]) >= 3501);
            FilterPrices = Range1.filter(page => parseInt(page.metadata.leasing_price[0]) <= 4000);
        }
        if (event.x === 5) {
            const Range1 = FilterCategory.filter(page => parseInt(page.metadata.leasing_price[0]) >= 4001);
            FilterPrices = Range1.filter(page => parseInt(page.metadata.leasing_price[0]) <= 4500);
        }
        if (event.x === 6) {
            FilterPrices = FilterCategory.filter(page => parseInt(page.metadata.leasing_price[0]) > 4500);
        }
        FilterPrices.sort((a, b) => a.metadata.leasing_price - b.metadata.leasing_price);
        this.CarsToShow = FilterPrices



    }

    // Filter Car By Clicked Button Value
    @action
    FilterCategory(FilterName) {
        this.selectedCarType = FilterName
        this.selectedOption = null
        this.SliderX = 0
        if (FilterName == 'all') {
            this.CarsToShow = this.AllCars
            this.FilterManufacturers()
            return
        }
        const FilterData = this.AllCars.filter(page => page.metadata.category[0] === FilterName);
        this.CarsToShow = FilterData
        this.FilterManufacturers()

    }

    // עדכון בחר יצרן
    @action
    handleManufacturerChange = selectedOption => {

        this.selectedOption = selectedOption;

        let FilterCategory = []
        this.all ? FilterCategory = this.AllCars : null
        this.jeeponim ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'jeeponim') : null
        this.family ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'family') : null
        this.small ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'small') : null
        this.big ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'big') : null
        this.hybrid ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'hybrid') : null

        const FilterManufacturer = FilterCategory.filter(page => page.metadata.manufacturer[0] === selectedOption);

        this.CarsToShow = FilterManufacturer
    };







    @action
    ClearAll() {
        this.selectedOption = null

        let FilterCategory = []
        this.all ? FilterCategory = this.AllCars : null
        this.jeeponim ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'jeeponim') : null
        this.family ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'family') : null
        this.small ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'small') : null
        this.big ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'big') : null
        this.hybrid ? FilterCategory = this.AllCars.filter(page => page.metadata.category[0] === 'hybrid') : null

        this.CarsToShow = FilterCategory
        this.FilterManufacturers()
        this.SliderX = 0



    };

    // Filter יצרן be From Avilable Cars
    @action
    //1 טויוטה
    //2 יונדאי
    //3 מאזדה
    //4 מיצובישי
    //5 ניסאן
    //6 סיאט
    //7 סיטרואן
    //8 סקודה
    //9 פולקסווגן
    //10 פיג'ו
    //11 קיה
    //12 רנו



    FilterManufacturers() {
        const Manufacturers = [];

        this.CarsToShow.map((car) =>
            Manufacturers.push(
                car.metadata.manufacturer[0]
            ));
        // remove Same Value Items
        const uniqueSet = new Set(Manufacturers)
        const backToArray = [...uniqueSet];
        let Options = [];
        // console.log(backToArray + ' :יצרנים לסינון')
        backToArray.map((item) => {
            if (item == "toyota") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'טויוטה';
                obj['order'] = '1';
                Options.push(obj);

            };
            if (item == "hyundai") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'יונדאי';
                obj['order'] = '2';
                Options.push(obj);

            };
            if (item == "mazda") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = "מאזדה";
                obj['order'] = '3';
                Options.push(obj);

            };
            if (item == "mitsubishi") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'מיצובישי';
                obj['order'] = '4';
                Options.push(obj);

            };
            if (item == "nissan") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'ניסאן';
                obj['order'] = '5';
                Options.push(obj);

            };
            if (item == "seat") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סיאט';
                obj['order'] = '6';
                Options.push(obj);
            };
            if (item == "citroen") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סיטרואן';
                obj['order'] = '7';
                Options.push(obj);
            };
            if (item == "skoda") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'סקודה';
                obj['order'] = '8';
                Options.push(obj);

            };
            if (item == "volkswagen") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'פולקסווגן';
                obj['order'] = '9';
                Options.push(obj);

            };
            if (item == "peugeot") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = "פיג'ו";
                obj['order'] = '10';
                Options.push(obj);

            };
            if (item == "kia") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'קיה';
                obj['order'] = '11';
                Options.push(obj);

            };

            if (item == "renault") {
                let obj = {};
                obj['value'] = item;
                obj['label'] = 'רנו';
                obj['order'] = '12';
                Options.push(obj);

            };
            this.ManufacturersToShow = Options
            this.ManufacturersToShow2 = Options
        },
        );
        const CopyToSort = toJS(this.ManufacturersToShow)
        CopyToSort.sort((a, b) => a.order - b.order);
        this.ManufacturersToShow = CopyToSort
    }


    @action
    CarClicked = (value) => {
        // מסנן קטגוריה
        this.FilterCategory(value)

        // משנה צבע כפתור
        if (value == 'hybrid') {
            this.all = false
            this.jeeponim = false
            this.family = false
            this.small = false
            this.big = false
            this.hybrid = true
        }
        if (value == 'big') {
            this.all = false
            this.jeeponim = false
            this.family = false
            this.small = false
            this.big = true
            this.hybrid = false
        }
        if (value == 'small') {
            this.all = false
            this.jeeponim = false
            this.family = false
            this.small = true
            this.big = false
            this.hybrid = false
        }
        if (value == 'family') {
            this.all = false
            this.jeeponim = false
            this.family = true
            this.small = false
            this.big = false
            this.hybrid = false
        }
        if (value == 'jeeponim') {
            this.all = false
            this.jeeponim = true
            this.family = false
            this.small = false
            this.big = false
            this.hybrid = false
        }
        if (value == 'all') {
            this.all = true
            this.jeeponim = false
            this.family = false
            this.small = false
            this.big = false
            this.hybrid = false
        }
    }


    @action
    LoadCars() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages?parent=${this.WordpressId}&per_page=100`)
            .then(res => {
                // const testnumnber = 0
                // const key = 'model_' + testnumnber + '_item_title'


                // const FilterMainModels = AllModels.filter(page => page.metadata.experimental_model[0] === "0");
                // FilterMainModels.sort((a, b) => (a.metadata.order - b.metadata.order))

                // const FilterExperimentalModels = AllModels.filter(page => page.metadata.experimental_model[0] === "1");
                // FilterExperimentalModels.sort((a, b) => (a.metadata.order - b.metadata.order))

                //  .les_site ליסינג עובדים
                //  .tzamod_site רכבים צמודי
                //  .tzamod_menahlim_bechirim_site רכבים צמודים - מנהלים בכירים
                // .tzamod_samankalim_site רכבים צמודים - סמנכ"לים בכירים וחברי הנהלה

                // לשנות לפני BUILD!!!!!!!!!!!!!
                // לשנות גם טקסט בעמוד כניסה!!!!!!!!!!!!!
                // const AllCars = res.data.filter(page => page.metadata.les_site[0] === "1");
                const AllCars = res.data.filter(page => page.metadata.tzamod_site[0] === "1");

                const jeeponim = AllCars.filter(page => page.metadata.category[0] === "jeeponim");
                const family = AllCars.filter(page => page.metadata.category[0] === "family");
                const small = AllCars.filter(page => page.metadata.category[0] === "small");
                const big = AllCars.filter(page => page.metadata.category[0] === "big");
                const hybrid = AllCars.filter(page => page.metadata.category[0] === "hybrid");

                let SortedCars = [...jeeponim, ...family, ...small, ...big, ...hybrid]



                // SortCars.sort((a, b) => a.metadata.category[0].localeCompare(b.metadata.category[0]))

                runInAction(() => {
                    this.loading = false;
                    this.AllCars = SortedCars
                    this.CarsToShow = SortedCars
                    this.FilterManufacturers()
                    this.SideBySideCarsUpdate()






                    // this.MainModels = FilterMainModels;
                    // this.ExperimentalModels = FilterExperimentalModels;

                    // this.Plm1 = Plm1MainModels;
                    // this.Plm1Experimental = Plm1Experimental;

                    // this.Plm2 = Plm2MainModels
                    // this.Plm2Experimental = Plm2Experimental;

                    this.FatchEnd = true;

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadLessons ERR')
                });
            })

    };




    @action
    LoadManagesrCars() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages?parent=${this.WordpressId}&per_page=100`)
            .then(res => {
                // מכוניות מנהלים בכירים
                const AllCars = res.data.filter(page => page.metadata.tzamod_samankalim_site[0] === "1");

                const jeeponim = AllCars.filter(page => page.metadata.category[0] === "jeeponim");
                const family = AllCars.filter(page => page.metadata.category[0] === "family");
                const small = AllCars.filter(page => page.metadata.category[0] === "small");
                const big = AllCars.filter(page => page.metadata.category[0] === "big");
                const hybrid = AllCars.filter(page => page.metadata.category[0] === "hybrid");

                let SortedCars = [...jeeponim, ...family, ...small, ...big, ...hybrid]

                // /management
                // const tzamod_menahlim_bechirim_site = res.data.filter(page => page.metadata.tzamod_menahlim_bechirim_site[0] === "1");

                // /managers
                // const tzamod_samankalim_site = res.data.filter(page => page.metadata.tzamod_samankalim_site[0] === "1");


                runInAction(() => {
                    this.loading = false;
                    this.AllCars = SortedCars
                    this.CarsToShow = SortedCars


                    this.FilterManufacturers()
                    this.SideBySideCarsUpdate()



                    this.FatchEnd = true;

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadLessons ERR')
                });
            })

    };




    @action
    LoadMainScreenManagementCars() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages?parent=${this.WordpressId}&per_page=100`)
            .then(res => {
                // מכוניות מנהלים בכירים
                const AllCars = res.data.filter(page => page.metadata.tzamod_menahlim_bechirim_site[0] === "1");

                const jeeponim = AllCars.filter(page => page.metadata.category[0] === "jeeponim");
                const family = AllCars.filter(page => page.metadata.category[0] === "family");
                const small = AllCars.filter(page => page.metadata.category[0] === "small");
                const big = AllCars.filter(page => page.metadata.category[0] === "big");
                const hybrid = AllCars.filter(page => page.metadata.category[0] === "hybrid");

                let SortedCars = [...jeeponim, ...family, ...small, ...big, ...hybrid]

                // /management
                // const tzamod_menahlim_bechirim_site = res.data.filter(page => page.metadata.tzamod_menahlim_bechirim_site[0] === "1");

                // /managers
                // const tzamod_samankalim_site = res.data.filter(page => page.metadata.tzamod_samankalim_site[0] === "1");


                runInAction(() => {
                    this.loading = false;
                    this.AllCars = SortedCars
                    this.CarsToShow = SortedCars


                    this.FilterManufacturers()
                    this.SideBySideCarsUpdate()



                    this.FatchEnd = true;

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadLessons ERR')
                });
            })

    };



};







export default new cars2();





