import React, { Component } from 'react';

import styles from './VideoPopup.module.css';

import Vimeo from '@u-wave/react-vimeo';


class VideoPopUp extends Component {





    render() {






        return (

            <>


                <div className={styles.FlexContainer}>

                    <Vimeo
                    // responsive={true}
                    video={this.props.videoId}
                    autoplay={this.props.autoplay}
                    autopause={false}
                    // height='500px'
                    // className={styles.Vimeo}
                    // onEnd={this.VimeoEnds}
                    // controls={false}
                  
                    // paused={false}
                    // allowFullScreen={false}
                    // showTitle={false}
                    // onPause={this.VimeoEnds}
                    />

                </div>



            </>
        );


    }
}

export default VideoPopUp;
