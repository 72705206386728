import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { withRouter, Link } from 'react-router-dom';


import styles from './SaftySiderSider.module.css';


@inject("siteLinks")
@observer
class SaftySider extends Component {





    render() {




        const Selected = (Item) => {
            if (Item == '0' && this.props.Degree == '0') {
                return styles.Selected
            }
            if (Item == '1' && this.props.Degree == '1') {
                return styles.Selected
            }
            if (Item == '2' && this.props.Degree == '2') {
                return styles.Selected
            }
            if (Item == '3' && this.props.Degree == '3') {
                return styles.Selected
            }
            if (Item == '4' && this.props.Degree == '4') {
                return styles.Selected
            }
            if (Item == '5' && this.props.Degree == '5') {
                return styles.Selected
            }
            if (Item == '6' && this.props.Degree == '6') {
                return styles.Selected
            }
            if (Item == '7' && this.props.Degree == '7') {
                return styles.Selected
            }
            if (Item == '8' && this.props.Degree == '8') {
                return styles.Selected
            }


        }

        const SideBySideStyle = this.props.SideBySide ? styles.FlexContainerSideBySide : ''

        return (

            <>


                <div className={styles.FlexContainer + ' ' + SideBySideStyle}>


                    <div className={styles.Container}>
                        <div className={styles.descriptionLeft}>בטיחות נמוכה</div>
                        <div className={styles.Item + ' ' + styles.Item0 + ' ' + Selected('0')}>0</div>
                        <div className={styles.Item + ' ' + styles.Item1 + ' ' + Selected('1')}>1</div>
                        <div className={styles.Item + ' ' + styles.Item2 + ' ' + Selected('2')}>2</div>
                        <div className={styles.Item + ' ' + styles.Item3 + ' ' + Selected('3')}>3</div>
                        <div className={styles.Item + ' ' + styles.Item4 + ' ' + Selected('4')}>4</div>
                        <div className={styles.Item + ' ' + styles.Item5 + ' ' + Selected('5')}>5</div>
                        <div className={styles.Item + ' ' + styles.Item6 + ' ' + Selected('6')}>6</div>
                        <div className={styles.Item + ' ' + styles.Item7 + ' ' + Selected('7')}>7</div>
                        <div className={styles.Item + ' ' + styles.Item8 + ' ' + Selected('8')}>8</div>
                        <div className={styles.descriptionRight}>בטיחות גבוהה</div>

                        {/* <div>{this.props.Degree}</div> */}

                    </div>

                </div>

            </>
        );


    }
}

export default withRouter(SaftySider);
