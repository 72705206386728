import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { withRouter, Link } from 'react-router-dom';


import styles from './FilterItem.module.css';


@inject("siteLinks")
@observer
class FilterItem extends Component {





    render() {






        return (

            <>


                <div className={styles.FlexContainer}>

                    <div className={styles.Image} onClick={this.props.OnClick}>{this.props.IsActive ? <div> <img src={this.props.ImgOn} /> </div> : <div> <img src={this.props.ImgOff} /> </div>}</div>
                    <div className={styles.Title}>{this.props.Title}</div>
                </div>

            </>
        );


    }
}

export default withRouter(FilterItem);
