import React, { Component, useRef } from 'react';
import styles from './DelUsers.module.css';
import { inject, observer } from 'mobx-react';
import { FixedSizeList as List } from "react-window";
import { toJS } from 'mobx';
import { withRouter, Link } from 'react-router-dom';

import loadingBlue from '../../images/loading_blue.gif'


@inject("adminPanel", "siteLinks")
@observer
class HeaderWithSlider extends Component {

    constructor(props) {
        super(props);
        this.File = React.createRef();
        this.state = {
            data: null,
            popdelete: false,
        };
    }


    inputchange = (event) => {
        const selectedFile = event.target.files[0]
        console.log(selectedFile)
        let data = [{
            "name": "jayanth",
            "data": "scd",
            "abc": "sdef"
        }]
        XLSX.utils.json_to_sheet(data, 'out.xlsx');
        if (selectedFile) {
            let fileReader = new FileReader();
            fileReader.readAsBinaryString(selectedFile);
            fileReader.onload = (event) => {
                let data = event.target.result;
                let workbook = XLSX.read(data, { type: "binary" });

                let dataStr = []
                workbook.SheetNames.forEach(sheet => {
                    let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);

                    dataStr.push(rowObject)

                });
                console.log(dataStr[0])
                this.setState({ data: dataStr[0] })
            }
        }

    }


    SelectFile = () => {
        this.File.current.click();
    }

    popdelete = () => {
        this.props.adminPanel.ShowPopDelete()
    }

    DeleteUsers = () => {
        this.props.adminPanel.DelAll(this.state.data)
    }
    CancelDelete = () => {
        this.props.adminPanel.HidePopDelete()
    }

    CloseDelete = () => {
        this.setState({ popdelete: false, data: null })
        this.props.adminPanel.hideApproved()
    }


    render() {

        const data = this.state.data

        let TableData = ''
        if (data) {
            TableData = data.map((person, index) => {

                let id = <div className={styles.id + ' ' + styles.err}>נתון לא קיים</div>
                let site = <div className={styles.site + ' ' + styles.err}>נתון לא תקין</div>

                if (!person.site) {
                    site = <div className={styles.site + ' ' + styles.err}>נתון לא קיים</div>
                }

                if (person.site === 'leassite') {
                    site = <div className={styles.site}>ליסינג עובדים</div>
                }
                if (person.id) {
                    id = <div className={styles.id}>{person.id}</div>
                }
                return (
                    <>
                        <div key={index} className={styles.RowContainer}>
                            {id}
                            {site}

                        </div>
                    </>
                )
            }
            );
        }

        let listData = ''
        if (data) {

            const Row = ({ index, key, style }) =>
                (

                    < div key={key} style={style} className={styles.RowContainer} >
                      
                        
                        { <div className={styles.id}>{data[index].id}</div>}
                        {/* <div className={styles.site}>{data[index].site}</div> */}
                    </div >

                )
            listData = <List
                className={styles.List + ' list'}
                height={700}
                itemCount={data.length}
                itemSize={60}
            >
                {Row}
            </List>
        }


        let table = ''
        data ?
            table = <div className={styles.RowsContainer}>

                <div onClick={this.popdelete} className={styles.BtContainer}> <div className={styles.BtPopDelete}>מחיקת משתמשים</div> </div>

                <div className={styles.RowContainer}>
                    <div className={styles.idTitle}>מספר תעודת זהות</div>
                    {/* <div className={styles.siteTitle}>משוייך לאתר</div> */}
                </div>
                {listData}

            </div> :
            null


        const ConfirmDelete = <div className={styles.ConfirmDelete}>
            <div className={styles.TitleDelete}> כל המשתמשים המופיעים ברשימה<br />ימחקו ממסד הנתונים</div>
            <div className={styles.BtContainer}>
                <div onClick={this.DeleteUsers} className={styles.BtDelete}>מחק</div>
                <div onClick={this.CancelDelete} className={styles.BtCancel}>ביטול</div>
            </div>
        </div>


        const AproveDelte = <div className={styles.ConfirmDelete}>
            <div className={styles.TitleDelete}>כל המשתמשים נמחקו בהצלחה</div>
            <div className={styles.BtContainer}>
                <div onClick={this.CloseDelete} className={styles.BtCancel}>סגור</div>
            </div>
        </div>

        return (

            <>

                <div className={styles.Container}>
                    {this.props.adminPanel.loading ? <img className={'loader ' + styles.loader} src={loadingBlue} alt="Loader" /> : null}
                    {this.props.adminPanel.PopDelete ? ConfirmDelete : null}
                    {this.props.adminPanel.AprovedDelete ? AproveDelte : null}
                    <div className={styles.Title}>מחיקת משתמשים מקובץ</div>
                    <input
                        ref={this.File}
                        name="file" onChange={this.inputchange.bind(this)} className={styles.InputFile} type="file" accept=".xls,.xlsx" />

                    <div className={styles.popup}></div>
                    <div className={styles.SelectFile} onClick={this.SelectFile}>בחר טבלת אקסל</div>

                    {table}
                </div>

            </>
        );

    }
}

export default withRouter(HeaderWithSlider);
