import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './SideBySide.module.css';

import { withRouter, Link } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';

import LoginCheck from '../components/LoginCheck';

import HeaderInside from '../components/HeaderInside';
import Footer from '../components/Footer';
import CarData from '../components/CarData'
import CarDataSideBySide_1 from '../components/CarDataSideBySide_1'
import CarDataSideBySide_2 from '../components/CarDataSideBySide_2'
import CarDataSideBySideSearchs from '../components/CarDataSideBySideSearchs'

import FilterItem from '../components/FilterItem'

import { toJS } from 'mobx';
import loadingBlue from '../images/loading_blue.gif'

@inject('login', 'cars', 'cars2', 'singleCar', 'singleCar2', 'siteLinks', 'sideBySide')
@observer // REMOVE BRACKETS => @observer
class SideBySide extends Component {

    constructor() {
        super();
        this.state = {
            car1: true,
            car2: true,
            SiteRelation: '',
            MainLink: '/main?m=carshow',
        };
    }

    componentDidMount() {

        const URlSearch = this.props.location.search
        const SiteRelation = new URLSearchParams(URlSearch).get('m')

        this.setState({ SiteRelation: SiteRelation })

        if (SiteRelation === 'carshow') {
            this.props.sideBySide.LoadCars()
        }

        if (SiteRelation === 'managers') {
            this.props.sideBySide.LoadMainScreenManagementCars()
            this.setState({ MainLink: '/managers?m=managers' })
        }

        if (SiteRelation === 'management') {
            this.props.sideBySide.LoadMainScreenManagementCars()
            this.setState({ MainLink: '/management?m=management' })
        }


        if (this.props.match.params.carid_1 == 0) {
            this.setState({ car1: false })
        }
        else this.props.sideBySide.UpdateCarsId1(this.props.match.params.carid_1)

        if (this.props.match.params.carid_2 == 0) {
            this.setState({ car2: false })
        }
        else this.props.sideBySide.UpdateCarsId2(this.props.match.params.carid_2)

        this.props.sideBySide.UpdateCarsId1(this.props.match.params.carid_1)
        this.props.sideBySide.UpdateCarsId2(this.props.match.params.carid_2)

    }

    Close1 = () => {
        this.setState({ car1: false })
        this.props.sideBySide.UpdateCarsId1(this.props.match.params.carid_1, this.props.match.params.carid_2)
    }

    Close2 = () => {
        this.setState({ car2: false })
        this.props.sideBySide.UpdateCarsId2(this.props.match.params.carid_1, this.props.match.params.carid_2)
    }



    // הצג טאבים להשוואה
    UpdateAcordionsShowFilters = (event) => {
        this.props.singleCar.ShowHideFilter(event)
        this.props.singleCar2.ShowHideFilter(event)

    }




    render() {




        const Car1Search =

            <>
                {this.props.sideBySide.loading && <div className={styles.loader}> <img className="loader" src={loadingBlue} alt="Loader" /></div>}
                {/* <div className={styles.loader}> <img className="loader" src={loadingBlue} alt="Loader" /></div> */}
                <CarDataSideBySideSearchs
                    ManufacturerList={toJS(this.props.sideBySide.ManufacturersToShow1)}
                    ManufacturerValue={this.props.sideBySide.selectedOption1}
                    handleManufacturerChange={this.props.sideBySide.SideBySidehandleManufacturerChange1}
                    ShowSelect={this.props.sideBySide.FatchEnd}
                    CarsList={toJS(this.props.sideBySide.ListOfCarsToShow1)}
                    CarValue={this.props.sideBySide.CarselectedOption1}
                    handleCarChange={this.props.sideBySide.SideBySidehandleCarChange1.bind(this, this.props.match.params.carid_1)}
                />
            </>
        const Car1Data = <CarDataSideBySide_1 Close={this.Close1} CarId={this.props.match.params.carid_1} />



        const Car2Search =
            <>{this.props.sideBySide.loading && <div className={styles.loader}> <img className="loader" src={loadingBlue} alt="Loader" /></div>}
                {/* <div className={styles.loader}> <img className="loader" src={loadingBlue} alt="Loader" /></div> */}
                <CarDataSideBySideSearchs
                    ManufacturerList={toJS(this.props.sideBySide.ManufacturersToShow2)}
                    ManufacturerValue={this.props.sideBySide.selectedOption2}
                    handleManufacturerChange={this.props.sideBySide.SideBySidehandleManufacturerChange2}
                    ShowSelect={this.props.sideBySide.FatchEnd}
                    CarsList={toJS(this.props.sideBySide.ListOfCarsToShow2)}
                    CarValue={this.props.sideBySide.CarselectedOption2}
                    handleCarChange={this.props.sideBySide.SideBySidehandleCarChange2.bind(this, this.props.match.params.carid_2)}
                />
            </>
        const Car2Data = <CarDataSideBySide_2 Close={this.Close2} CarId={this.props.match.params.carid_2} />


        const link = '/side-by-side/' + this.props.sideBySide.Car1id + '/' + this.props.sideBySide.Car2id + '?m=' + this.state.SiteRelation



        const IsCarSelected1 = this.props.sideBySide.SideBySideSelected1
        const IsCarSelected2 = this.props.sideBySide.SideBySideSelected2


        let SearchLink = <Link className={styles.Compare} to={link}>לחץ להשוואת הרכבים</Link>


        if (this.state.car1 == false || this.state.car2 == false) {
            SearchLink = <div className={styles.CompareDisable} >לחץ להשוואת הרכבים</div>
        }

        if (IsCarSelected1 == false && IsCarSelected2 == false) {
            SearchLink = <div className={styles.CompareDisable} >לחץ להשוואת הרכבים</div>
        }

        if (IsCarSelected1 == true && IsCarSelected2 == true) {
            SearchLink = <Link className={styles.Compare} to={link}>לחץ להשוואת הרכבים</Link>
        }

        if (IsCarSelected1 == true && IsCarSelected2 == false) {
            SearchLink = <Link className={styles.Compare} to={link}>לחץ להשוואת הרכבים</Link>
        }

        if (IsCarSelected1 == false && IsCarSelected2 == true) {
            SearchLink = <Link className={styles.Compare} to={link}>לחץ להשוואת הרכבים</Link>
        }


        const URlSearch = this.props.location.search
        const IsManager = new URLSearchParams(URlSearch).get('m')

        return (


            <>
                <LoginCheck loggedin={this.props.login.token} siteRealation={this.props.login.siteRealation} PageRelation={IsManager}>
                    <HeaderInside
                        MainLink={this.state.MainLink}
                        HideSideBySide={true} >


                        <div className={styles.FilterContainer}>
                            <div className={styles.FilterSet1}>
                                <FilterItem
                                    OnClick={this.UpdateAcordionsShowFilters.bind(this, 'model')}
                                    IsActive={this.props.singleCar.ModelMuneSideBySide}
                                    ImgOn={this.props.siteLinks.ModelOn}
                                    ImgOff={this.props.siteLinks.ModelOff}
                                    Title="דגם"
                                />

                                <FilterItem
                                    OnClick={this.UpdateAcordionsShowFilters.bind(this, 'engin')}
                                    IsActive={this.props.singleCar.EnginMuneSideBySide}
                                    ImgOn={this.props.siteLinks.EnginOn}
                                    ImgOff={this.props.siteLinks.EnginOff}
                                    Title="מנוע וביצועים"
                                />

                                <FilterItem
                                    OnClick={this.UpdateAcordionsShowFilters.bind(this, 'safty')}
                                    IsActive={this.props.singleCar.CarSaftyMuneSideBySide}
                                    ImgOn={this.props.siteLinks.SaftyOn}
                                    ImgOff={this.props.siteLinks.SaftyOff}
                                    Title="בטיחות רכב"
                                />
                            </div>
                            <div className={styles.FilterSet2}>
                                <FilterItem
                                    OnClick={this.UpdateAcordionsShowFilters.bind(this, 'size')}
                                    IsActive={this.props.singleCar.SizesMuneSideBySide}
                                    ImgOn={this.props.siteLinks.SizeOn}
                                    ImgOff={this.props.siteLinks.SizeOff}
                                    Title="מידות"
                                />

                                <FilterItem
                                    OnClick={this.UpdateAcordionsShowFilters.bind(this, 'multimedia')}
                                    IsActive={this.props.singleCar.MultimediaMuneSideBySide}
                                    ImgOn={this.props.siteLinks.MultimediaOn}
                                    ImgOff={this.props.siteLinks.MultimediaOff}
                                    Title="מולטימדיה"
                                />

                                <FilterItem
                                    OnClick={this.UpdateAcordionsShowFilters.bind(this, 'comfort')}
                                    IsActive={this.props.singleCar.ComfortMuneSideBySide}
                                    ImgOn={this.props.siteLinks.ComfortOn}
                                    ImgOff={this.props.siteLinks.ComfortOff}
                                    Title="אבזור ונוחות"
                                />
                            </div>
                        </div>

                    </HeaderInside>
                    <div className={styles.Container}>







                        {SearchLink}

                        {/* <div onClick={this.Close1} >Close1</div>
                    <div onClick={this.Close2}>Close2</div> */}
                        <div className={styles.FlexContainer}>
                            <div className={styles.SideBySideContainer + ' SideBySideContainer'} >

                                {/* {this.state.redirect ? <Redirect to={NewLink} /> : null} */}

                                {/* Left Car */}
                                <div className={styles.CarContainer}>

                                    {this.state.car2 ? Car2Data : Car2Search}
                                </div>


                                {/* Right Car */}
                                <div className={styles.CarContainer}>

                                    {this.state.car1 ? Car1Data : Car1Search}



                                    {/* {<CarDataSideBySide_1  CarId={this.props.match.params.carid_1} />} */}

                                </div>




                            </div>
                        </div>
                    </div>
                    <Footer />
                </LoginCheck>
            </>
        );


    }
}

export default withLastLocation(withRouter(SideBySide));
