import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { FixedSizeList as List } from "react-window";
import Select from 'react-select';

import styles from './UserList.module.css';
import './CarRequests2021.css'

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import loadingBlue from '../../images/loading_blue.gif'


import { AiFillDelete } from 'react-icons/ai';
import { FaSort } from 'react-icons/fa';
import { RiEdit2Fill } from 'react-icons/ri';



const SiteOptins = [
    { value: 'leassite', label: 'ליסינג עובדים' },
    { value: 'tzamotsite', label: 'רכבים צמודים' },
    { value: 'managers', label: 'סמנכ"לים בכירים וחברי הנהלה' },
    { value: 'management', label: 'מנהלים בכירים' },
];



@inject("adminPanel", "siteLinks")
@observer
class CarRequests2021 extends Component {


    constructor() {
        super();
        this.state = {
            popdelete: false,
            popEdit: false,
            UserId: null,
            SiteRelated: null,
            ShowPanel: null,
            ShowPanelCheck: false,
            idNum: null,

            NewSite: null,
            NewSiteLable: null,
            NewIsAdmin: null,
            ActiveBtUpdate: false,

            SelectedRaw: null,
            SelectedId: null,
            idNumber: '',
        };
    }

    componentDidMount() {
        this.props.adminPanel.LoadUsers()
    }

    handleSelectSiteChange = NewSite => {

        const CurrentSite = this.state.SiteRelated
        const NewSiteValue = NewSite.value

        if (CurrentSite == NewSiteValue) {
            this.setState({ NewSite: NewSite, NewSiteLable: NewSite.label, ActiveBtUpdate: false });
        } else
            this.setState({ NewSite: NewSite, NewSiteLable: NewSite.label, ActiveBtUpdate: true });
        // console.log(`Option selected:`, NewSite.label);
    };

    HandelOnChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        // this.props.adminPanel.filterData(value)

    }

    popEdit = (UserId, SiteRelated, panel, idNum) => {

        if (panel == '1') {
            this.setState({ ShowPanelCheck: true })
        }
        if (panel == '0') {
            this.setState({ ShowPanelCheck: false })
        }
        // Shows Edit conternt
        this.setState({
            UserId: UserId, SiteRelated: SiteRelated, ShowPanel: panel, idNum: idNum, NewSiteLable: this.ChangeToHeb(SiteRelated), popEdit: true
        })

    }
    UpdateUser = () => {
        const UserId = this.state.UserId
        const SiteRelated = this.state.SiteRelated
        const NewSiteRelated = this.state.NewSite
        const NewIsAdmin = this.state.ShowPanel

        this.props.adminPanel.UpdateUserData(UserId, SiteRelated, NewSiteRelated, NewIsAdmin)
        this.setState({ idNumber: '', ActiveBtUpdate: false, popEdit: false, UserId: null, SiteRelated: null, ShowPanel: null, idNum: null, NewSiteLable: null, NewSite: null })
    }
    CancelUpdateUser = () => {
        this.setState({ ActiveBtUpdate: false, popEdit: false, UserId: null, SiteRelated: null, ShowPanel: null, idNum: null, NewSiteLable: null, NewSite: null })
    }



    // UserId = wordpress user id
    // idNum =תעודת זהות
    popdelete = (UserId, idNum) => {
        // Shows ConfirmDelete conternt
        this.setState({ popdelete: true, UserId: UserId, idNum: idNum })
    }
    DeleteUser = () => {
        this.props.adminPanel.DeleteUserFromWordpress(this.state.UserId)
        this.setState({ idNumber: '', popdelete: false, UserId: null, idNum: null })
    }
    CancelDelete = () => {
        this.setState({ popdelete: false, UserId: null, idNum: null })
    }

    FliterUsersTable = (filterBy) => {
        this.setState({ idNumber: '' })
        this.props.adminPanel.FliterUsersTable(filterBy)
    }



    ChangeToHeb = (sitename) => {
        let heb = ''
        if (sitename == 'tzamotsite') {
            heb = 'רכבים צמודים'
            return <>{heb}</>
        }
        if (sitename == 'leassite') {
            heb = 'ליסינג עובדים'
            return <>{heb}</>
        }
        if (sitename == 'management') {
            heb = 'מנהלים בכירים'
            return <>{heb}</>
        }
        if (sitename == 'managers') {
            heb = 'סמנכ"לים בכירים וחברי הנהלה'
            return <>{heb}</>
        }

    }

    CheckboxChange = () => {

        if (this.state.ShowPanel == '1') {
            this.setState({ ShowPanel: '0', ShowPanelCheck: false, ActiveBtUpdate: true })
        }
        if (this.state.ShowPanel == '0') {
            this.setState({ ShowPanel: '1', ShowPanelCheck: true, ActiveBtUpdate: true })
        }


    }

    ShowDelete = (idnum, wordpressid) => {
        if (idnum == '205060')
            return (
                <div
                    className={styles.IconContainer1}><AiFillDelete size={20} className={styles.RemoveIconDisable} /></div>)
        else
            return (
                <div onClick={this.popdelete.bind(this, wordpressid, idnum)}
                    className={styles.IconContainer1}><AiFillDelete size={20} className={styles.RemoveIcon} /></div>)

    }


    ShowEdit = (user_id, siterelation, admin_panel, FirstName) => {
        if (FirstName == '205060')
            return (
                <div className={styles.IconContainer2}>
                    <RiEdit2Fill size={20} className={styles.EditIconDisable} />
                </div>
            )
        else
            return (
                <div onClick={this.popEdit.bind(
                    this,
                    user_id,
                    siterelation,
                    admin_panel,
                    FirstName
                )}
                    className={styles.IconContainer2}>
                    <RiEdit2Fill size={20} className={styles.EditIcon} />
                </div>
            )

    }


    HandelOnChangeUsers(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        this.props.adminPanel.FilterDataUsers(value)
    }


    render() {

        const { NewSite } = this.state;
        const Edit = <div className={styles.ConfirmDelete}>
            <div className={styles.TitleDelete}>עדכון פרטים לתעודת זהות {this.state.idNum}</div>

            <div className={styles.TitleSelect}>שיוך לאתר:</div>
            <Select
                className={styles.Select}
                value={NewSite}
                onChange={this.handleSelectSiteChange}
                options={SiteOptins}
                placeholder={this.state.NewSiteLable}
            />

            <div className={styles.panel}>
                <div className={styles.TitleSelect}>הצג פאנל ניהול</div>
                <Checkbox
                    value="0"
                    onChange={this.CheckboxChange.bind(this)}
                    checked={this.state.ShowPanelCheck}
                    className={styles.chaeckbox}
                // inputProps={{ 'aria-label': 'Checkbox A' }}
                />
            </div>


            <div className={styles.BtContainer}>



                {this.state.ActiveBtUpdate ?
                    <div onClick={this.UpdateUser} className={styles.BtUpdate}>עדכן</div>
                    :
                    <div className={styles.BtUpdateDisable}>עדכן</div>
                }

                <div onClick={this.CancelUpdateUser} className={styles.BtCancel}>ביטול</div>
            </div>
        </div>



        const ConfirmDelete = <div className={styles.ConfirmDelete}>
            <div className={styles.TitleDelete}> למחוק את המשתמש {this.state.idNum}?</div>
            <div className={styles.BtContainer}>
                <div onClick={this.DeleteUser} className={styles.BtDelete}>מחק</div>
                <div onClick={this.CancelDelete} className={styles.BtCancel}>ביטול</div>
            </div>
        </div>


        const Row = ({ index, key, style }) => (

            < div key={key} style={style} className={styles.Row} >
                {this.ShowDelete(this.props.adminPanel.AllUsers[index].FirstName, this.props.adminPanel.AllUsers[index].user_id)}

                {this.ShowEdit(
                    this.props.adminPanel.AllUsers[index].user_id,
                    this.props.adminPanel.AllUsers[index].siterelation,
                    this.props.adminPanel.AllUsers[index].admin_panel,
                    this.props.adminPanel.AllUsers[index].FirstName
                )}

                {/* <div onClick={this.popEdit.bind(
                    this,
                    this.props.adminPanel.AllUsers[index].user_id,
                    this.props.adminPanel.AllUsers[index].siterelation,
                    this.props.adminPanel.AllUsers[index].admin_panel,
                    this.props.adminPanel.AllUsers[index].FirstName
                )}
                    className={styles.IconContainer2}>
                    <RiEdit2Fill size={20} className={styles.EditIcon} />
                </div> */}

                <div className={styles.SiteContainer}>
                    <div className={styles.SiteName}>{this.ChangeToHeb(this.props.adminPanel.AllUsers[index].siterelation)}</div>
                </div>

                <div className={styles.IdContainer}>
                    <div className={styles.Id}>{this.props.adminPanel.AllUsers[index].FirstName}</div>
                </div>


            </div >

        )

        const ActiveBt = { backgroundColor: '#a37a29' }

        return (

            <>


                <div className={styles.FlexContainer}>

                    {this.state.popEdit ? Edit : null}
                    {this.state.popdelete ? ConfirmDelete : null}
                    {this.props.adminPanel.loading ? <img className="loader" src={loadingBlue} alt="Loader" /> :

                        (
                            <>

                                <div className={styles.Title}>טבלת משתמשים</div>
                                <div>
                                    <div className={styles.InputContainer}>
                                        <input
                                            placeholder="חפש תעודת זהות"
                                            type="number"
                                            className={styles.FromInput}
                                            name="idNumber"
                                            value={this.state.idNumber}
                                            onChange={this.HandelOnChangeUsers.bind(this)}
                                        />
                                    </div>
                                    <div className={styles.FiltersContainer}>
                                        <div
                                            onClick={this.FliterUsersTable.bind(this, 'AllUsers')}
                                            style={this.props.adminPanel.FilterAllusers ? ActiveBt : null}
                                            className={styles.filterbt}>
                                            כל המשתמשים
                                            </div>
                                        <div
                                            onClick={this.FliterUsersTable.bind(this, 'leassite')}
                                            style={this.props.adminPanel.FilterLeassite ? ActiveBt : null}
                                            className={styles.filterbt}>
                                            ליסינג עובדים
                                            </div>
                                        <div
                                            onClick={this.FliterUsersTable.bind(this, 'tzamotsite')}
                                            style={this.props.adminPanel.FilterTzamotsite ? ActiveBt : null}
                                            className={styles.filterbt}>
                                            רכבים צמודים
                                            </div>
                                        <div
                                            onClick={this.FliterUsersTable.bind(this, 'management')}
                                            style={this.props.adminPanel.FilterManagement ? ActiveBt : null}
                                            className={styles.filterbt}>
                                            מנהלים בכירים
                                            </div>
                                        <div
                                            onClick={this.FliterUsersTable.bind(this, 'managers')}
                                            style={this.props.adminPanel.FilterManagers ? ActiveBt : null}
                                            className={styles.filterbt}>
                                            סמנכ"לים בכירים וחברי הנהלה
                                            </div>
                                        <div
                                            onClick={this.FliterUsersTable.bind(this, 'admin_panel')}
                                            style={this.props.adminPanel.FilterAdmins ? ActiveBt : null}
                                            className={styles.filterbt}>
                                            מורשים פאנל ניהול
                                            </div>

                                    </div>
                                </div>
                                <div className={styles.RowTitle}>

                                    <div className={styles.titleRemove}>הסרה</div>
                                    <div className={styles.titleEdit}>עריכה</div>

                                    <div className={styles.title_site}>משוייך לאתר</div>
                                    <div className={styles.title_id}>תעודת זהות</div>


                                </div>

                                <List
                                    className={styles.List + ' list'}
                                    height={700}
                                    itemCount={this.props.adminPanel.AllUsers.length}
                                    itemSize={60}
                                >
                                    {Row}
                                </List>
                            </>
                        )
                        // FinalTable
                    }
                </div>

            </>
        );


    }
}

export default CarRequests2021;
