import login from './Login';
import cars from './Cars';
import cars2 from './Cars2';

import formcars from './CarsForm';
import formcars2 from './Cars2Form';


import siteLinks from './SiteLinks';
import fatchPage from './FatchPage';
import singleCar from './SingleCar';
import singleCar2 from './SingleCar2';
import siteGlobal from './SiteGlobal';

import sideBySide from './SideBySide';
import adminPanel from './AdminPanel';
const stores = {
    login,
    cars,
    cars2,
    formcars,
    formcars2,
    siteLinks,
    fatchPage,
    singleCar,
    singleCar2,
    siteGlobal,
    sideBySide,
    adminPanel,
};

export default stores