import React, { Component } from 'react';

import styles from './CarsLoaderMainScreen.module.css';

import loaderPic from '../images/PicLoader.gif'
class CarsLoaderMainScreen extends Component {




    render() {
        const loader = <div className={styles.SingleCarContainer}>
            <div className={styles.MobileContainer}>
                <div className={styles.MobilePic}>
                    {/* <div className={styles.Category}>ג'יפונים</div> */}
                    <img src={loaderPic} />
                </div>

                <div className={styles.NameAndDescription}>
                    <div className={styles.ModelName}></div>
                    <div className={styles.SmallDescription}> </div>
                </div>
            </div>

            <div className={styles.MobilePriceAndInfo}>
                <div className={styles.MoreInfo}></div>
                {/* <div className={styles.Divider}></div> */}
                <div className={styles.PriceContainer}>
                    <div className={styles.PriceTitle}></div>
                    <div className={styles.Price}></div>
                </div>
            </div>
        </div>

        return (


            <>
                {loader}
                {loader}
                {loader}
                {loader}
                {loader}
                {loader}

            </>
        );


    }
}

export default CarsLoaderMainScreen;
