import React, { Component } from 'react';

import { FaQuestionCircle } from 'react-icons/fa';

import styles from './AcordionItemB.module.css';



class FilterByCar extends Component {




    render() {

        return (

            <>
                <div className={styles.ItemContainer}>
                    <div className={styles.DividerTop}></div>
                    <div className={styles.AcordionItem + ' ' + this.props.s}>
                        {this.props.dataTip ? (
                            <div data-tip={this.props.dataTip} className={styles.toolTip}><FaQuestionCircle size='14' /></div>
                        ) : null}
                    
                        <div className={styles.Title}>{this.props.Title}</div>
                        <div className={styles.Value}>{this.props.Value}</div>

                    </div>
                    {/* <div className={styles.DividerBottom}></div> */}
                </div>
            </>
        );


    }
}

export default FilterByCar;
