import React, { Component } from 'react';
import { inject, observer, computed } from 'mobx-react';

import styles from './CarDataLoader.module.css';

import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import { toJS } from 'mobx';
import { HiCheck } from 'react-icons/hi';
import { AiOutlineClose, AiFillStar } from 'react-icons/ai';
import ImageGallery from 'react-image-gallery';
import Acordion from '../components/AcordionLoader';
import AcordionItem from '../components/AcordionItemLoader';
import AcordionItemB from '../components/AcordionItemLoader';
import HeaderInside from '../components/HeaderInside';
import Footer from '../components/Footer';
import PollutionSider from '../components/PollutionSider';

import loaderPic from '../images/PicLoader.gif'

@inject('cars', 'singleCar', 'singleCar2')
@observer // REMOVE BRACKETS => @observer
class CarData extends Component {

    constructor() {
        super();
        this.state = {
            showIndex: false,
            showBullets: true,
            infinite: true,
            showThumbnails: false,
            showFullscreenButton: true,
            showGalleryFullscreenButton: true,
            showPlayButton: false,
            showGalleryPlayButton: false,
            showNav: true,
            isRTL: false,
            slideDuration: 450,
            slideInterval: 2000,
            slideOnThumbnailOver: false,
            thumbnailPosition: 'bottom',
            showVideo: {},


        };
    }



    componentDidMount() {

        const CarId = this.props.CarId
        this.props.singleCar.UpdateCar1(CarId)
    }



    resetVideo() {
        this.props.singleCar.resetVideo()
    }



    onSlide() {
        this.resetVideo()
    }

    resetVideo() {
        this.props.singleCar.resetVideo()
    }

    toggleShowVideo(url) {
        this.props.singleCar.toggleShowVideo(url)
    }



    toggleShowVideo(url) {
        this.showVideo[url] = !Boolean(this.showVideo[url]);
        this.showVideo = this.showVideo

        if (this.showVideo[url]) {
            if (this.showPlayButton) {
                this.showGalleryPlayButton = false
            }

            if (this.showFullscreenButton) {
                this.showGalleryFullscreenButton = false
            }
        }
    }





    render() {


        const Title = <div className={styles.TitleContainer}>
            {/* <div className={styles.CarTitle}>{parse(this.props.singleCar.Car1Title)}</div>
            <div>{this.props.singleCar.TorenderVideo}</div>
            <div className={styles.UnderCarTitle}>{this.props.singleCar.Car1SmallText}</div>
            <div className={styles.Divider}></div>
            <div className={styles.PriceTitle}>שווי שימוש רציף</div>
            <div className={styles.BigPrice}><span>₪</span>{this.props.singleCar.CarPrice}</div> */}
        </div>

        // const Price = <div className={styles.PriceContainer}>
        //     <div className={styles.PriceTitle}>שווי שימוש רציף</div>
        //     <div className={styles.BigPrice}>{this.props.singleCar.CarPrice + ' ₪'}</div>
        // </div>

        const Gallery = <div className={styles.GallryContainer}>
            <img src={loaderPic} />
            {/* <ImageGallery
                ref={i => this._imageGallery = i}
                onSlide={this.onSlide.bind(this)}
                showFullscreenButton={false}
                showPlayButton={false}
                items={[...toJS(this.props.singleCar.Car1Gallery)]}
                infinite={false}
                useBrowserFullscreen={false}
                useTranslate3D={true}
                showBullets={true}
                pause={true}

                showThumbnails={false}

            /> */}
        </div>


        const Car1Model = this.props.singleCar.Car1Model.map((car, index) => {
            if (car.Title == 'שווי שימוש רציף')
                return null
            // return <AcordionItem Price={true} Title={car.Title} Value={car.Value + ' ₪'} />
            return <AcordionItem Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Engine = this.props.singleCar.Car1Engine.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemB Title={car.Title} ValueAsIcon={true} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemB Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            if (car.Title == 'דרגת זיהום')
                return <AcordionItemB Title={car.Title} Value={<PollutionSider Degree={car.Value} />} />
            return <AcordionItemB Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Safty = this.props.singleCar.Car1Safty.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemB Title={car.Title} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemB Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            if (car.Value == '3stars')
                return <AcordionItemB Title={car.Title} Value={<div className={styles.Starts}> <AiFillStar /><AiFillStar /><AiFillStar /></div>} />
            if (car.Value == '4stars')
                return <AcordionItemB Title={car.Title} Value={<div className={styles.Starts}> <AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></div>} />
            if (car.Value == '5stars')
                return <AcordionItemB Title={car.Title} Value={<div className={styles.Starts}> <AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /> </div>} />
            return <AcordionItemB Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Size = this.props.singleCar.Car1Size.map((car, index) => {
            return <AcordionItemB Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Multimedia = this.props.singleCar.Car1Multimedia.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemB Title={car.Title} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemB Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            return <AcordionItemB Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Comfort = this.props.singleCar.Car1Comfort.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemB Title={car.Title} ValueAsIcon={true} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemB Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            return <AcordionItemB Title={car.Title} Value={car.Value} />
        }

        );



        return (


            <>

                <div className={styles.GlleryTitleMedolContainer + ' ' + styles.DesktopView}>
                    <div className={styles.TitleAndModelContainer}>
                        {Title}

                        <div className={styles.ModelContainer + ' LoaderSingleCar'}>
                            <Acordion Title="דגם" open={true} className={styles.ModelAcordion} triggerDisabled={true} >
                                {/* {Car1Model} */}
                            </Acordion>
                        </div>
                    </div>
                    {/* {<Test
                        items={[...toJS(this.props.singleCar.Car1Gallery)]}
                    />} */}
                    {Gallery}

                </div>

                <div className={styles.GlleryTitleMedolContainer + ' ' + styles.MobileView}>
                    {/* {Title} */}
                    {/* {<Test
                        items={[...toJS(this.props.singleCar.Car1Gallery)]}
                    />} */}
                    {Gallery}
                    {/* <div className={styles.ModelContainer + ' LoaderSingleCar'}>
                        <Acordion Title="דגם" open={true} className={styles.ModelAcordion} triggerDisabled={false} >
                     
                        </Acordion>
                    </div> */}
                </div>

                <div className={styles.ItemsContainer + ' LoaderSingleCar'}>
                    <Acordion >
                        <AcordionItemB />
                        <AcordionItemB />
                        <AcordionItemB />
                        <AcordionItemB />
                        <AcordionItemB />
                        <AcordionItemB />
                        
                    </Acordion>
                </div>

                <div className={styles.ItemsContainer + ' LoaderSingleCar'}>
                    <Acordion >
                        <AcordionItemB />
                        <AcordionItemB />
                        <AcordionItemB />
                        <AcordionItemB />
                        <AcordionItemB />
                        <AcordionItemB />
                        
                    </Acordion>
                </div>




            </>
        );


    }
}

export default withRouter(CarData);
