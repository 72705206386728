import React, { Component, Suspense } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './LoginScreen.module.css';
import './LoginScreen.css';

import { withRouter, Link, Redirect } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import Vimeo from '@u-wave/react-vimeo';

import RadioButton from '../components/RadioButton'
import parse from 'html-react-parser';
import { toJS } from 'mobx';

import loadingWhite from '../images/loading_white.gif'
import { GoMute, GoUnmute } from 'react-icons/go';

import VideoPopUp from '../components/VideoPopUp'


const AllCarsLazy = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("../components/LoaderComponent")), 4000)
        )
);



@inject('login', 'siteLinks', 'fatchPage', 'cars')
@observer
class login extends Component {
    constructor() {
        super();
        this.state = {
            ShowComment: false,
            HightlightRadio: false,
            RadioButtonActive: true,
            NextPageLink: '',
            IdNumner: '',
            Validated: false,
            PopVideo: false,
            paused: false,
            autoplay: true,
            muted: false,
            volume: 0,
            showunmute: true,
            VideoEnded: false
        };
    }

    componentDidMount() {
        this.props.fatchPage.GetLoginPage()
    }

    UnMute = () => {
        this.setState({ muted: false, volume: .5, showunmute: false })
    }


    onValueChange = () => {
        const currentRadio = this.state.RadioButtonActive
        const currentID = this.state.IdNumner
        this.setState({ RadioButtonActive: !currentRadio, ShowComment: false, HightlightRadio: false })
        this.props.login.HideErr()

        if (!currentRadio == true && currentID !== '') {
            this.setState({ Validated: true })
        }
        if (!currentRadio == false) {
            this.setState({ Validated: false })
        }
    }

    onIdChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value, ShowComment: false, HightlightRadio: false });
        this.props.login.HideErr()
        const currentRadio = this.state.RadioButtonActive
        const currentID = value

        if (currentRadio == true && currentID !== '') {
            this.setState({ Validated: true })
        }
        if (currentID == '') {
            this.setState({ Validated: false })
        }
    }

    ClickWithOutAppove = () => {
        const currentRadio = this.state.RadioButtonActive
        const currentID = this.state.IdNumner

        if (currentRadio === false) {
            return this.props.login.SetErrMessage('נא לאשר שקראת והבנת')
        }
        if (currentID === '') {
            return this.props.login.SetErrMessage('נא למלא תעודת זהות')
        }
    }

    Login = () => {
        this.props.login.Login(this.state.IdNumner)
    }


    VimeoEnds = () => {
        this.props.login.SetFirstLoginEnd()
    }

    simulateClick(e) {
        e.click()
    }

    render() {

        // if user loged in
        if (this.props.login.token) {
            const siteRealation = this.props.login.siteRealation

            // ChangeBeforBuild!!!!!----------------------------------------------------------------------*/

            // if (siteRealation == 'leassite') {
            //     this.props.login.Logout()
            //     window.location.href = 'https://rafael-car-show-leas.co.il/';
            // }

            // if (siteRealation == 'tzamotsite') {
            //     this.props.login.Logout()
            //     window.location.href = 'https://rafael-car-show.co.il/';
            // }

            // ChangeBeforBuild!!!!!----------------------------------------------------------------------*/


            // First Time POP VIDEO

            const PopVideo = this.state.PopVideo
            if (PopVideo == false) {
                this.setState({ PopVideo: true })
            }


            // const firstLogin = this.props.login.firstLogin
            const firstLogin = false

            if (firstLogin == false) {


                // ChangeBeforBuild!!!!!----------------------------------------------------------------------*/

                if (siteRealation == 'leassite') {
                    return (<Redirect push to={`/main?m=carshow`} />)
                }

                if (siteRealation == 'tzamotsite') {
                    return (<Redirect push to={`/main?m=carshow`} />)
                }

                // ChangeBeforBuild!!!!!----------------------------------------------------------------------*/


                if (siteRealation == 'managers') {
                    return (<Redirect push to={`/managers?m=managers`} />)
                }
                if (siteRealation == 'management') {
                    return (<Redirect push to={`/management?m=management`} />)
                }

            }



        }




        const AllCars = this.props.cars.CarsToShow.map((car, index) =>
            <>
                <img src={toJS(car.featured_url)} />
            </>)

        const BackgroundStyle = {
            backgroundImage: 'url(' + this.props.siteLinks.LoginScreenBackground + ')',
        };


        const AvtiveButton = <div onClick={this.Login}>
            <div className={styles.FlexContainer}>
                <div className={styles.ButtonContainer}>
                    <div className={styles.GoToSiteButton}>בואו נצא לדרך</div>
                    {this.props.siteLinks.CarWhite}
                </div>
            </div>
        </div>

        const NonAvtiveButton =

            <div onClick={this.ClickWithOutAppove} className={styles.FlexContainer + ' ' + styles.NonActiveButton}>
                <div className={styles.NonActiveButtonButtonContainer}>
                    <div className={styles.GoToSiteButton}>בואו נצא לדרך</div>
                    {this.props.siteLinks.CarWhite}
                </div>
            </div>

        const Video =
            <div className={styles.VideoContainer}>
                <div className={styles.VimeoContainer} >
                    <Vimeo
                        responsive={true}
                        video='430707712'
                        autoplay={this.state.autoplay}
                        autopause={false}
                        className={styles.Vimeo}
                        onEnd={this.VimeoEnds}
                        controls={true}
                        paused={this.state.paused}
                        muted={this.state.muted}
                    // volume={this.state.volume}
                    // onTimeUpdate={this.changeVol}
                    // onVolumeChange={this.playvideo}
                    // allowFullScreen={false}
                    // showTitle={false}
                    // onPause={this.VimeoEnds}
                    />

                </div>

            </div>

        const URlSearch = this.props.location.search
        const IsManager = new URLSearchParams(URlSearch).get('m')

        let TextToshow = ''
        if (IsManager == 'managers') {
            TextToshow = <>
                {/* טקסט מנהלים */}
                <div class={styles.BodyText} >{parse(this.props.fatchPage.LoginPageMangerText)}</div>
            </>
        }
        else TextToshow = <>
            {/* ChangeBeforBuild!!!!!----------------------------------------------------------------------*/}
            {/* טקסט אתר ליסינג */}
            {/* < div class={styles.BodyText} >{parse(this.props.fatchPage.LoginPageLeasText)}</div> */}

            {/* טקסט צמוד */}
            <div class={styles.BodyText} >{parse(this.props.fatchPage.LoginPageTzamodText)}

            </div>
            {/* ChangeBeforBuild!!!!!----------------------------------------------------------------------*/}
        </>

        const login = <div className={styles.MainContainer}  >

            {this.props.login.loading && <img className="loader" src={loadingWhite} alt="Loader" />}

            {this.props.fatchPage.loading && <img className="loader" src={loadingWhite} alt="Loader" />}

            <div className={styles.BackgroundImage}><img src={this.props.siteLinks.LoginScreenBackground} /></div>


            {this.props.fatchPage.FatchEnd ?
                (<div class={styles.Frame}>

                    <div class={styles.TopOfFrame}>
                        <div class={styles.Left}></div>
                        <div class={styles.Center}><img src={this.props.siteLinks.DemoLogo} /></div>
                        <div class={styles.Right}></div>
                    </div>

                    <Suspense fallback="">
                        <AllCarsLazy><div class={styles.AllImagesPreLoader}>{AllCars}</div></AllCarsLazy>
                    </Suspense>


                    <div class={styles.RestOfFrame}>
                        <div class={styles.BigTitle} >{this.props.fatchPage.LoginPageTitle}</div>
                        <div className={styles.DemoText}>
                            <p>לכניסה לפורטל הדגמה</p>
                            <p>205060 הקלידו מספר</p>
                        </div>

                        {/* 
                        {TextToshow} */}


                        <div style={{ opacity: this.props.login.token ? 0 : 1 }}> {/* HIDE MESSAGE WHEN LOGED IN */}
                            <div className={styles.ApproveComment} style={{ opacity: this.props.login.ShowlogErr ? 1 : 0 }}>{this.props.login.LogErrMessage} </div>
                        </div>


                        {/* <div className={styles.ApproveReading}>
                            <div className={styles.Approve}>קראתי והבנתי</div>
                            <RadioButton className={styles.RadioButton} ShowCommnet={this.state.HightlightRadio} onChange={this.onValueChange} checked={this.state.RadioButtonActive} />
                        </div> */}

                        <div className={styles.InputContainer}>
                            {/* <div className={styles.idTitle}>נא למלא מספר תעודת זהות</div> */}
                            <input
                                placeholder="תעודת זהות 9 ספרות"
                                type="number"
                                className={styles.FromInput}
                                name="IdNumner"
                                value={this.state.FormIdNumner}
                                onChange={this.onIdChange.bind(this)}
                            />
                        </div>


                        {this.state.Validated ? AvtiveButton : NonAvtiveButton}
                        <div className={styles.ContactInfo}>
                            לפרטים נוספים צרו קשר:
                            054-4379888
                        </div>
                        {/* <div className={styles.CarShowLogo}>  {this.props.siteLinks.CarShowLogoWhite}</div> */}
                    </div>



                </div>
                ) : null

            }

        </ div>


        return (

            <>
                {/* {login} */}
                {/* {this.state.PopVideo ? Video : login} */}
                {login}

            </>

        );


    }
}

export default withRouter(login);
