import React, { Component } from 'react';

import styles from './AcordionItemLoader.module.css';



class FilterByCar extends Component {




    render() {
        const Price = this.props.Price ? styles.Price : null
        return (

            <>
                <div className={styles.ItemContainer}>
                    <div className={styles.DividerTop}></div>
                    <div className={styles.AcordionItem}>

                        <div className={styles.Title}>loader</div>
                        <div className={styles.Value  + ' ' + Price} >loader</div>

                    </div>
                    {/* <div className={styles.DividerBottom}></div> */}
                </div>
            </>
        );


    }
}

export default FilterByCar;
