import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { withRouter, Link } from 'react-router-dom';


import styles from './Footer.module.css';
import { AiOutlineWhatsApp } from 'react-icons/ai';

@inject("siteLinks")
@observer
class Footer extends Component {





    render() {






        return (

            <>


                <div className={styles.FlexContainer}>


                    <div className={styles.Container}>

                        <div className={styles.RafaelLogo}><img src={this.props.siteLinks.RafaelHebWhite} /></div>
                        <a href="https://activebranding.co.il/home_he/" target="_blank" className={styles.ActiveBranding}>{this.props.siteLinks.ActiveBranding}</a>
                        <a href="https://wa.me/972544379888?text=שלום אשמח לשמוע פרטים נוספים על אתר הרכבים" target="_blank">
                            <div className={styles.callus}>
                                <AiOutlineWhatsApp className={styles.AiOutlineWhatsApp} />
                                לפרטים צרו קשר: 054-4379888
                            </div>
                        </a>
                    </div>

                </div>

            </>
        );


    }
}

export default withRouter(Footer);
