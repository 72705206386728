import { observable, configure, runInAction, action, toJS } from 'mobx';
import axios from 'axios';
import clientConfig from '../client-config';

import loadingWhite from '../'
configure({ enforceActions: true });


class FatchPage {
    @observable loading = false
    @observable FatchEnd = false
    @observable LoginPageTitle = ''
    @observable LoginPageLeasText = ''
    @observable LoginPageTzamodText = ''
    @observable LoginPageMangerText = ''
    @observable LoginPageApproved = false

  
    @observable MainPageTitle = ''
    @observable MainPageText = ''


    @observable PageId = []


    @action Approved() {
        this.LoginPageApproved = !this.LoginPageApproved
    }
    @action
    GetLoginPage() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        this.PageId = '109'
        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/${this.PageId}`)
            .then(res => {
                // console.log(res.data.metadata)
                // const FamilyCars = res.data.filter(page => page.metadata.category[0] === "family");
                runInAction(() => {
                    this.loading = false;
                    this.FatchEnd = true;
                    this.LoginPageTitle = res.data.metadata.title[0];
                    this.LoginPageLeasText = res.data.metadata.leas_work[0];
                    this.LoginPageTzamodText = res.data.metadata.tzamod[0]
                    this.LoginPageMangerText = res.data.metadata.manger_text[0];
                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadPage ERR')
                });
            })

    };


    @action
    GetMainPage() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        this.PageId = '116'
        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/${this.PageId}`)
            .then(res => {
                // console.log(res.data.metadata.title[0])
                // const FamilyCars = res.data.filter(page => page.metadata.category[0] === "family");
                runInAction(() => {
                    this.loading = false;
                    this.FatchEnd = true;
                    this.MainPageTitle = res.data.metadata.title[0];
                    this.MainPageText = res.data.metadata.text[0];
                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadPage ERR')
                });
            })

    };


    // constructor() { this.GetPageData() };


};



export default new FatchPage();





