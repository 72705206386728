import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'mobx-react'


import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from 'react-router-last-location';

import stores from './stores';

ReactDOM.render(
  <Provider
    login={stores.login}
    
    cars={stores.cars}
    cars2={stores.cars2}

    formcars={stores.formcars}
    formcars2={stores.formcars2}

    singleCar={stores.singleCar}
    singleCar2={stores.singleCar2}
    siteLinks={stores.siteLinks}
    fatchPage={stores.fatchPage}
    siteGlobal={stores.siteGlobal}
    sideBySide={stores.sideBySide}
    adminPanel={stores.adminPanel}
  >
    <BrowserRouter forceRefresh={true}>
      <LastLocationProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </LastLocationProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
