import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import styles from './CarDataSideBySideLoader.module.css';

import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import { toJS } from 'mobx';
import { HiCheck } from 'react-icons/hi';
import { AiOutlineClose, AiFillStar, AiFillCloseSquare } from 'react-icons/ai';
import ImageGallery from 'react-image-gallery';
import Acordion from '../components/AcordionLoader';

import AcordionItemSideBySide from '../components/AcordionItemLoader';

import PollutionSider from '../components/PollutionSider';
import loaderPic from '../images/PicLoader.gif'

@inject('cars', 'singleCar', 'singleCar2')
@observer // REMOVE BRACKETS => @observer
class CarDataSideBySide extends Component {




    componentDidMount() {
        const CarId = this.props.CarId
        this.props.singleCar.UpdateCar1(CarId)
    }



    UpdateAcordions = (event) => {
        this.props.singleCar.UpdeteMenuSideBySide(event)
        this.props.singleCar2.UpdeteMenuSideBySide(event)
    }


    resetVideo() {
        this.props.singleCar.resetVideo()
    }



    onSlide() {
        this.resetVideo()
    }

    resetVideo() {
        this.props.singleCar.resetVideo()
    }

    toggleShowVideo(url) {
        this.props.singleCar.toggleShowVideo(url)
    }



    toggleShowVideo(url) {
        this.showVideo[url] = !Boolean(this.showVideo[url]);
        this.showVideo = this.showVideo

        if (this.showVideo[url]) {
            if (this.showPlayButton) {
                this.showGalleryPlayButton = false
            }

            if (this.showFullscreenButton) {
                this.showGalleryFullscreenButton = false
            }
        }
    }




    render() {


        const Title = <div className={styles.TitleContainer}>
            {/* <div onClick={this.props.Close} className={styles.CloseBt}><AiFillCloseSquare /></div> */}
            {/* <div className={styles.CarTitle}></div>
            <div className={styles.UnderCarTitle}></div> */}
        </div>

        const Gallery = <div className={styles.GallryContainer}>
            <img src={loaderPic} />
        </div>


        const Car1Model = this.props.singleCar.Car1Model.map((car, index) => {
            if (car.Title == 'שווי שימוש רציף')
                return null
            // return <AcordionItemSideBySide Price={true} Title={car.Title} Value={car.Value + ' ₪'} />
            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Engine = this.props.singleCar.Car1Engine.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemSideBySide Title={car.Title} ValueAsIcon={true} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemSideBySide Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            if (car.Title == 'דרגת זיהום')
                return <AcordionItemSideBySide Title={car.Title} Value={<PollutionSider SideBySide={true} Degree={car.Value} />} />
            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Safty = this.props.singleCar.Car1Safty.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemSideBySide Title={car.Title} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemSideBySide Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            if (car.Value == '5stars')
                return <AcordionItemSideBySide Title={car.Title} Value={<div className={styles.Starts}> <AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /> </div>} />
            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Size = this.props.singleCar.Car1Size.map((car, index) => {
            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Multimedia = this.props.singleCar.Car1Multimedia.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemSideBySide Title={car.Title} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemSideBySide Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }
        );

        const Car1Comfort = this.props.singleCar.Car1Comfort.map((car, index) => {
            if (car.Value == 'יש')
                return <AcordionItemSideBySide Title={car.Title} ValueAsIcon={true} Value={<HiCheck className={styles.CheckIcon} />} />
            if (car.Value == 'אין')
                return <AcordionItemSideBySide Title={car.Title} Value={<div className={styles.DontHavaIcon}><AiOutlineClose /></div>} />
            return <AcordionItemSideBySide Title={car.Title} Value={car.Value} />
        }

        );


        const ModelMuneSideBySide = this.props.singleCar.ModelMuneSideBySide
        const EnginMuneSideBySide = this.props.singleCar.EnginMuneSideBySide
        const CarSaftyMuneSideBySide = this.props.singleCar.CarSaftyMuneSideBySide
        const SizesMuneSideBySide = this.props.singleCar.SizesMuneSideBySide
        const MultimediaMuneSideBySide = this.props.singleCar.MultimediaMuneSideBySide
        const ComfortMuneSideBySide = this.props.singleCar.ComfortMuneSideBySide
        const MoreComfortMuneSideBySide = this.props.singleCar.MoreComfortMuneSideBySide
        const MoreComfort2MuneSideBySide = this.props.singleCar.MoreComfort2MuneSideBySide


        const carData = <div>

            <div className={styles.GlleryTitleMedolContainer}>
                {Title}
                <div>{this.props.singleCar.TorenderVideo}</div>
                <div className={styles.SingleImage}><img src={loaderPic} /> </div>
                {Gallery}




                <div className={styles.ItemsContainer + ' LoaderSingleCar'}>
                    <Acordion >
                        <AcordionItemSideBySide />
                        <AcordionItemSideBySide />
                        <AcordionItemSideBySide />
                        <AcordionItemSideBySide />
                        </Acordion>
                </div>
                <div className={styles.ItemsContainer + ' LoaderSingleCar'}>
                    <Acordion >
                        <AcordionItemSideBySide />
                        <AcordionItemSideBySide />
                        <AcordionItemSideBySide />
                        <AcordionItemSideBySide />
                        </Acordion>
                </div>
                <div className={styles.ItemsContainer + ' LoaderSingleCar'}>
                    <Acordion >
                        <AcordionItemSideBySide />
                        <AcordionItemSideBySide />
                        <AcordionItemSideBySide />
                        <AcordionItemSideBySide />
                        </Acordion>
                </div>

            </div>




        </div>

        return (


            <>



                {carData}
            </>

        );


    }
}

export default withRouter(CarDataSideBySide);
