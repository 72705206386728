import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import styles from './FormPopUp.module.css';

import { toJS } from 'mobx';
import moment from 'moment';
import FormCarSearch from '../PopUpForm/FormCarSearch'
import { AiFillCar } from 'react-icons/ai';
import loadingblue from '../../images/loading_blue.gif'

@inject('login', 'siteGlobal', 'formcars')
@observer
class FormPopUp extends Component {




    constructor() {
        super();
        this.state = {
            showcomment: false,
            FormIdNumner: ''


        };
    }


    // componentDidMount() {
    //     this.props.fatchPage.GetLoginPage()

    //     this.setState({ NextPageLink: `/main` })

    //     const URlSearch = this.props.location.search
    //     const IsManager = new URLSearchParams(URlSearch).get('m')

    //     if (IsManager === 'managers') {
    //         this.setState({ NextPageLink: '/managers' })
    //     }

    //     if (IsManager === 'management') {
    //         this.setState({ NextPageLink: '/management' })
    //     }
    // }


    componentDidMount() {
        const IsManager = this.props.IsManager
        this.props.formcars.LoadCars(IsManager)
    }


    HideMe = (type, envent) => {
        this.setState({ FormIdNumner: '', showcomment: false, })
        this.props.siteGlobal.PopUpFomrHide(type)
        this.props.formcars.HandelPopUpClose()
    }


    SendForm = () => {
        const FormIdNumber = this.props.IdNumber
        const FormCarChoice1 = this.props.formcars.FormCarChoice1
        const FormCarChoice2 = this.props.formcars.FormCarChoice2
        const FormData = this.props.formcars.FormData
        const FormSiteLocation = this.props.IsManager

        let IsLeasSite = true
        if (this.props.login.siteRealation == 'tzamotsite') {
            IsLeasSite = false
        }

        this.props.formcars.SendForm(FormIdNumber, FormCarChoice1, FormCarChoice2, FormData, FormSiteLocation, IsLeasSite)
    };

    NotAvtiveClick = () => {
        this.setState({ showcomment: true })
    }

    // onIdChange(e) {
    //     const { name, value } = e.target;
    //     this.setState({ [name]: value });
    //     this.props.formcars.HandleIDValOnChange(e)

    // }

    render() {



        let opacity = this.props.siteGlobal.PopUpFormOpacity
        let display = this.props.siteGlobal.PopUpFormDisplay
        let zIndex = this.props.siteGlobal.PopUpZ
        let left = this.props.siteGlobal.PopUpFormLeft
        let PopUpFormTransition = this.props.siteGlobal.PopUpFormTransition
        const Style = {
            opacity: opacity,
            display: display,
            zIndex: zIndex,
            left: left,
            transition: PopUpFormTransition
        }





        const Car1Search = <FormCarSearch
            Title='רכב בעדיפות ראשונה'
            ManufacturerList={toJS(this.props.formcars.ManufacturersToShow1)}
            ManufacturerValue={this.props.formcars.selectedOption1}
            handleManufacturerChange={this.props.formcars.HandleManufacturerChange1}

            CarsList={toJS(this.props.formcars.ListOfCarsToShow1)}
            CarValue={this.props.formcars.CarselectedOption1}
            handleCarChange={this.props.formcars.HandleCarChange1}
        />



        const Car2Search = <FormCarSearch
            Title='רכב בעדיפות שנייה'
            ManufacturerList={toJS(this.props.formcars.ManufacturersToShow2)}
            ManufacturerValue={this.props.formcars.selectedOption2}
            handleManufacturerChange={this.props.formcars.HandleManufacturerChange2}

            CarsList={toJS(this.props.formcars.ListOfCarsToShow2)}
            CarValue={this.props.formcars.CarselectedOption2}
            handleCarChange={this.props.formcars.HandleCarChange2}
        />

        const comment = {
            opacity: this.state.showcomment ? '1' : '0'
        }

        const Form = <div style={Style} className={styles.FlexContainer}>
            <div className={styles.form}>
                {this.props.formcars.loading && <img className={styles.Loader} src={loadingblue} alt="" />}

                <div onClick={this.HideMe.bind(this, 'desktop')} className={styles.CloseFrom + ' ' + styles.Desktop}>X</div>
                <div onClick={this.HideMe.bind(this, 'mobile')} className={styles.CloseFrom + ' ' + styles.Mobile}>X</div>

                <div onClick={this.HideMe.bind(this, 'desktop')} className={styles.BackgroundCloseClick + ' ' + styles.Desktop}></div>
                <div onClick={this.HideMe.bind(this, 'mobile')} className={styles.BackgroundCloseClick + ' ' + styles.Mobile}></div>

                <div onClick={this.props.logout} className={styles.logout}>התנתק</div>

                <div className={styles.InputContainer}>
                    <div className={styles.idTitle}>הנך מחובר בת.ז</div>
                    <div className={styles.id}>{this.props.IdNumber}</div>

                    {/* <input
                        placeholder="תעודת זהות"
                        type="number"
                        className={styles.FromInput}
                        name="FormIdNumner"
                        value={this.state.FormIdNumner}
                        onChange={this.onIdChange.bind(this)}
                    /> */}
                </div>
                {Car1Search}
                {Car2Search}

                {this.props.formcars.FormSendButtonActive ?
                    <div onClick={this.SendForm} className={styles.SendBtActive}>זהו בחרתי</div>
                    :
                    <>
                        <div style={comment} className={styles.comment}>נא למלא את כל הפרטים</div>
                        <div onClick={this.NotAvtiveClick} className={styles.SendBt}>זהו בחרתי</div>

                    </>
                }
            </div>
        </div>

        const Sent = <div style={Style} className={styles.FlexContainer}>
            <div className={styles.form}>
                <div onClick={this.HideMe.bind(this, 'desktop')} className={styles.CloseFrom + ' ' + styles.Desktop}>X</div>
                <div onClick={this.HideMe.bind(this, 'mobile')} className={styles.CloseFrom + ' ' + styles.Mobile}>X</div>

                <div className={styles.InputContainer}>

                    <div className={styles.ApproveTitle1}>בקשתך נקלטה </div>

                    <AiFillCar size="50" color="#c3922e" />
                    <div className={styles.ApproveTitle2}>תודה<br /> ודרך צלחה!</div>
                </div>

            </div>
        </div>

        return (

            <>


                { this.props.formcars.ShowFromSentText ? Sent : Form}

            </>
        );


    }
}

export default (FormPopUp);
