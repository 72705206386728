import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Slider from 'react-input-slider';

import { withRouter, Link } from 'react-router-dom';
import { TiArrowBack } from 'react-icons/ti';

import Select from 'react-styled-select';

import styles from './HeaderInside.module.css';


@inject("siteLinks")
class HeaderWithSlider extends Component {
    constructor() {
        super();
        this.state = {
            Image: '',
            selectedOption: null
        };
    }






    render() {


        const TitleToshow = () => {
            switch (this.props.x) {

                case 1: return "עד 2500";
                case 2: return "2501-3000";
                case 3: return "3001-3500";
                case 4: return "3501-4000";
                case 5: return "4001-4500";
                case 6: return "מעל 4500";

                default: return "טווח מחירים"
            }
        }

        // const options = [
        //     { value: 'chocolate', label: 'Chocolate' },
        //     { value: 'strawberry', label: 'Strawberry' },
        //     { value: 'vanilla', label: 'Vanilla' },
        // ];

        const PriceSilder = <div className={styles.PriceSlider}>
            <div className={styles.PriceRange}>{TitleToshow()}</div>
            <Slider
                styles={{
                    track: {
                        backgroundColor: 'white'
                    },
                    active: {
                        backgroundColor: '#0F102F'
                    },
                    thumb: {
                        // width: 50,
                        // height: 50
                    },
                    disabled: {
                        opacity: 0.5
                    }
                }}
                axis="x"
                xstep={1}
                xmin={0}
                xmax={6}
                x={this.props.x}
                className={styles.SliderPrice}
                // onChange={({ x }) => this.setState({ sliderx: x })}
                onChange={this.props.onChange}
            />
        </div>


        const SelectManufacturer = <div className={styles.SelectManufacturer}>
            <Select
                value={this.props.selectedOption}
                onChange={this.props.handleManufacturerChange}
                options={this.props.options}
                className={styles.SelectTeam}
                placeholder="בחר יצרן"
            />
        </div>
        const car1 = this.props.Car1Id
        const car2 = this.props.Car2Id
        const SideBySide = <Link to={this.props.SideBySideLink} className={styles.SideBySide}>{this.props.siteLinks.SideBySideIcon}</Link>

        return (

            <>


                <div className={styles.FlexContainer}>


                    <div className={styles.Container}>

                        <div className={styles.LeftLogo}> <Link to={this.props.MainLink}><img src={this.props.siteLinks.RafaelHebBlue} /></Link></div>


                        <div className={styles.CenterDiv} >   {this.props.children} </div>
                        <div className={styles.CenterDivGoBack} onClick={this.props.history.goBack}><TiArrowBack size={40} /></div>

                        <div className={styles.RightLogo}> <Link to={this.props.MainLink}><img src={this.props.siteLinks.CarShowLogo} /></Link></div>



                    </div>

                    <div className={styles.MobileCenterDiv} onClick={this.props.history.goBack}><TiArrowBack size={40} /></div>
                </div>
                {this.props.HideSideBySide ? null : SideBySide}

            </>
        );


    }
}

export default withRouter(HeaderWithSlider);
