import React, { Component } from 'react';
import { observable, configure, runInAction, action, toJS } from 'mobx';
import axios from 'axios';
import clientConfig from '../client-config';
import { GoPlay } from "react-icons/go";
import Vimeo from '@u-wave/react-vimeo';
import AcordionItem from '../components/AcordionItem';
import CarDataSideBySide_1 from '../components/CarDataSideBySide_1'
import ReactPlayer from "react-player"

configure({ enforceActions: true });


class SingleCar {
    @observable loading = false
    @observable FatchEnd = false
    @observable paused = true
    @observable VideoAutoPlay = false

    @observable FadeIn = '0'
    @observable FadeOut = '1'
    @observable showVideo = {}
    @observable TorenderVideo = true
    @observable autoplay = true
    @observable showPlayButton = true
    @observable showGalleryPlayButton = true
    @observable showFullscreenButton = true
    @observable showGalleryFullscreenButton = true
    @observable SingleImage = ''
    @observable CarPrice = ''


    @observable Car_1_id = ''
    @observable Car1data = []
    @observable Car1Gallery = ''
    @observable Car1Title = ''
    @observable Car1SmallText = ''
    @observable Car1Model = []
    @observable Car1Engine = []
    @observable Car1Safty = []
    @observable Car1Size = []
    @observable Car1Multimedia = []
    @observable Car1Comfort = []
    @observable Car1Hybrid = []
    @observable IsHybrid = false
    @observable Car1More = []
    @observable Car1MoreInclude = []
    @observable featured_url = ''

    @observable ModelMuneSideBySide = true
    @observable EnginMuneSideBySide = true
    @observable CarSaftyMuneSideBySide = true
    @observable SizesMuneSideBySide = true
    @observable MultimediaMuneSideBySide = true
    @observable ComfortMuneSideBySide = true
    @observable MoreComfortMuneSideBySide = true
    @observable MoreComfort2MuneSideBySide = true



    @observable Car_2_id = ''

    // @observable ShowModelTab = true
    // @observable ShowEnginlTab = true
    // @observable ShowSaftylTab = true
    // @observable ShowSizelTab = true
    // @observable ShowMultimediaTab = true
    // @observable ShowComfortTab = true

    // Filter Tabs On Side by Side



    @action ShowHideFilter(event) {
        if (event == 'model') {
            this.ModelMuneSideBySide = !this.ModelMuneSideBySide
        }
        if (event == 'engin') {
            this.EnginMuneSideBySide = !this.EnginMuneSideBySide
        }
        if (event == 'safty') {
            this.CarSaftyMuneSideBySide = !this.CarSaftyMuneSideBySide
        }
        if (event == 'size') {
            this.SizesMuneSideBySide = !this.SizesMuneSideBySide
        }
        if (event == 'multimedia') {
            this.MultimediaMuneSideBySide = !this.MultimediaMuneSideBySide
        }
        if (event == 'comfort') {
            this.ComfortMuneSideBySide = !this.ComfortMuneSideBySide
            this.MoreComfortMuneSideBySide = !this.MoreComfortMuneSideBySide
            this.MoreComfort2MuneSideBySide = !this.MoreComfort2MuneSideBySide
        }
    }


    @action
    UpdateFadeIn() {
        this.FadeIn = '1'
        this.FadeOut = '0'
    }
    @action
    UpdeteMenuSideBySide(event) {
        if (event == 'ModelContainer') {
            this.ModelMuneSideBySide = !this.ModelMuneSideBySide
        }
        if (event == 'EnginContainer') {
            this.EnginMuneSideBySide = !this.EnginMuneSideBySide
        }
        if (event == 'SaftyContainer') {
            this.CarSaftyMuneSideBySide = !this.CarSaftyMuneSideBySide
        }
        if (event == 'SizesContainer') {
            this.SizesMuneSideBySide = !this.SizesMuneSideBySide
        }
        if (event == 'MultimediaContainer') {
            this.MultimediaMuneSideBySide = !this.MultimediaMuneSideBySide
        }
        if (event == 'ComfortContainer') {
            this.ComfortMuneSideBySide = !this.ComfortMuneSideBySide
        }
        if (event == 'MoreComfortContainer') {
            this.MoreComfortMuneSideBySide = !this.MoreComfortMuneSideBySide
        }
        if (event == 'MoreComfortContainer2') {
            this.MoreComfort2MuneSideBySide = !this.MoreComfort2MuneSideBySide
        }
    }



    @action UpdateCar1(CarId) {
        this.Car_1_id = CarId

        this.LoadCar('car1')
    }

    @action updatePaused() {
        this.paused = true
    }

    @action updatePlay(a) {
        this.paused = false
    }

    @action
    aaa() {
        console.log('this.paused')

    }
    renderVideo(item) {

        const { paused } = this;

        return (
            <div>
                {
                    this.showVideo[item.embedUrl] ?
                        // this.TorenderVideo ?

                        <div className='video-wrapper'>
                            <a
                                className='close-video'
                                onClick={this.toggleShowVideo.bind(this, item.embedUrl)}
                            >
                            </a>

                            <div class="video_holder">
                                {/* <div>
                                    <ReactPlayer
                                        url={"https://vimeo.com/" + item.embedUrl}
                                        autoplay
                                    />
                                </div> */}

                                <Vimeo
                                    video={item.embedUrl}
                                    className='vimeo_iframe'
                                    responsive={true}
                                    autoplay
                                // autopause={false}
                                // paused={paused}
                                // onPlay={this.updatePlay.bind(this)}
                                // onPaused={this.updatePaused.bind(this)}
                                // onLoaded={this.aaa.bind(this)}

                                />
                            </div>

                        </div>
                        :
                        <a className='GalleryPlay' onClick={this.toggleShowVideo.bind(this, item.embedUrl)}>
                            {/* <div className='play-button'></div> */}
                            <GoPlay className='play-button' />
                            <img className='image-gallery-image' src={item.original} />
                            {
                                item.description &&
                                <span
                                    className='image-gallery-description'
                                    style={{ right: '0', left: 'initial' }}
                                >
                                    {item.description}
                                </span>
                            }
                        </a>
                }
            </div>
        );
    }




    @action
    onSlide() {
        // עדכון מידע אחרת לא מנגן
        this.TirggerRender()
        // this.TorenderVideo = !this.TorenderVideo
        this.resetVideo()
    }



    @action
    resetVideo() {
        this.TirggerRender()
        this.TorenderVideo = !this.TorenderVideo
        this.showVideo = {}

        if (this.showPlayButton) {
            this.showGalleryPlayButton = true
        }

        if (this.showFullscreenButton) {
            this.showGalleryFullscreenButton = true
        }
    }


    @action
    TirggerRender() {
        this.TorenderVideo = !this.TorenderVideo
    }


    @action
    toggleShowVideo(url) {
        // const TITLECOPY = this.Car1Title
        // this.Car1Title = "TITLECOPY"

        // עדכון מידע אחרת לא מנגן
        this.TirggerRender()

        this.showVideo[url] = !Boolean(this.showVideo[url]);
        this.showVideo = this.showVideo

        // if (this.showVideo[url]) {
        //     if (this.showPlayButton) {
        //         this.showGalleryPlayButton = false
        //     }

        //     if (this.showFullscreenButton) {
        //         this.showGalleryFullscreenButton = false
        //     }
        // }
    }




    GetVimeoThumbnail(id) {
        // return 'https://rafael-car-show.co.il/db/wp-content/uploads/2020/10/toyota-yaris-2020-gallery-03-full_tcm-3123-1937447-2.jpg'
        // let Vimeo = require('vimeo').Vimeo;
        // let client = new Vimeo("e11627f206866c6d496ce3593a1c994fd3c7550e", "bmz+f1YYgTZUgKoKE4rr3IGzzwNq2A2P/AQvuiDS7Y8kjchYbZoblS1bTi118wMG5uJCpap+H1vrGUbdzAXQn8XNIDIw5C2jr2poZe4Uec4SuvhrpgEVXbLqwOR33IUC", "7ffab5725e9f30941475b8c3cbb9301c");
        // const path = "videos/" + id + "/pictures"
        // client.request({
        //     method: 'GET',
        //     path: path
        // }, function (error, body, status_code, headers) {
        //     if (error) {
        //         console.log(error);
        //     }
        //     // console.log(body.data[0].sizes[9].link);
        //     return body.data[0].sizes[9].link
        // })
    }



    @action
    LoadCar() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/${this.Car_1_id}`)
            .then(res => {
                let CarGallery = []


                const video_frame0 = res.data.media_0_video_frame.url
                const media_0_videoid = res.data.metadata.media_0_videoid

                // console.log(video_frame0)
                if (media_0_videoid && media_0_videoid[0] !== '') {
                    let obj = {};
                    obj['original'] = video_frame0;
                    obj['thumbnail'] = video_frame0;
                    obj['embedUrl'] = 'https://player.vimeo.com/video/' + media_0_videoid[0];
                    obj['renderItem'] = this.renderVideo.bind(this);
                    CarGallery.push(obj);
                }
                const media_0_gallery = res.data.media_0_gallery
                if (media_0_gallery) {
                    for (let i = 0; i < media_0_gallery.length; i++) {
                        let obj = {};
                        obj['original'] = media_0_gallery[i];
                        obj['thumbnail'] = media_0_gallery[i];
                        CarGallery.push(obj);
                    }
                }

                // console.log(res.data)
                const video_frame2 = res.data.media_1_video_frame.url
                const media_2_videoid = res.data.metadata.media_1_videoid
                // console.log(res.data)
                if (media_2_videoid && media_2_videoid[0] !== '') {
                    let obj = {};
                    obj['original'] = video_frame2 ? video_frame2 : GenericFrame;
                    obj['thumbnail'] = video_frame2 ? video_frame2 : GenericFrame;
                    obj['embedUrl'] = 'https://player.vimeo.com/video/' + media_2_videoid[0];
                    obj['renderItem'] = this.renderVideo.bind(this);
                    CarGallery.push(obj);
                }

                const media_2_gallery = res.data.media_2_gallery
                if (media_2_gallery) {
                    for (let i = 0; i < media_2_gallery.length; i++) {
                        let obj = {};
                        obj['original'] = media_2_gallery[i];
                        obj['thumbnail'] = media_2_gallery[i];
                        CarGallery.push(obj);
                    }
                }




                // דגם
                const ModelItems = res.data.metadata.model[0]
                let ModelData = []
                for (let i = 0; i < ModelItems; i++) {
                    let obj = {};
                    obj['Title'] = res.data.metadata["model_" + i + "_item_title"][0];
                    obj['Value'] = res.data.metadata["model_" + i + "_item_valu"][0];
                    ModelData.push(obj)
                }



                // מנוע וביצועים
                const EngineItems = res.data.metadata.engine[0]
                let EngineData = []
                for (let i = 0; i < EngineItems; i++) {
                    let obj = {};
                    obj['Title'] = res.data.metadata["engine_" + i + "_item_title"][0];
                    obj['Value'] = res.data.metadata["engine_" + i + "_item_valu"][0];
                    EngineData.push(obj)
                }

                // בטיחות רכב
                const CarSafetyItems = res.data.metadata.car_safety[0]
                let CarSafety = []
                for (let i = 0; i < CarSafetyItems; i++) {
                    let obj = {};
                    obj['Title'] = res.data.metadata["car_safety_" + i + "_item_title"][0];
                    obj['Value'] = res.data.metadata["car_safety_" + i + "_item_valu"][0];
                    CarSafety.push(obj)
                }

                // מידות
                const CarMeasurementsItems = res.data.metadata.measurements[0]
                let Measurements = []
                for (let i = 0; i < CarMeasurementsItems; i++) {
                    let obj = {};
                    obj['Title'] = res.data.metadata["measurements_" + i + "_item_title"][0];
                    obj['Value'] = res.data.metadata["measurements_" + i + "_item_valu"][0];
                    Measurements.push(obj)
                }

                // מולטימדיה ותקשורות
                const CarMultimediaItems = res.data.metadata.multimedia[0]
                let Multimedia = []
                for (let i = 0; i < CarMultimediaItems; i++) {
                    let obj = {};
                    obj['Title'] = res.data.metadata["multimedia_" + i + "_item_title"][0];
                    obj['Value'] = res.data.metadata["multimedia_" + i + "_item_valu"][0];
                    Multimedia.push(obj)
                }


                // אבזור ונוחות
                const ComfortItems = res.data.metadata.comfort[0]
                let Comfort = []
                for (let i = 0; i < ComfortItems; i++) {
                    let obj = {};
                    obj['Title'] = res.data.metadata["comfort_" + i + "_item_title"][0];
                    obj['Value'] = res.data.metadata["comfort_" + i + "_item_valu"][0];
                    Comfort.push(obj)
                }


                // היברידי
                const HybridItems = res.data.metadata.hybrid[0]
                let Hybrid = []
                for (let i = 0; i < HybridItems; i++) {
                    let obj = {};
                    obj['Title'] = res.data.metadata["hybrid_" + i + "_item_title"][0];
                    obj['Value'] = res.data.metadata["hybrid_" + i + "_item_valu"][0];
                    Hybrid.push(obj)
                }

                let isHybrid = false
                // console.log(res.data.metadata.category[0])
                if (res.data.metadata.category[0] == "hybrid") {
                    isHybrid = true
                }

                runInAction(() => {
                    this.loading = false;
                    this.FatchEnd = true;
                    this.Car1data = res.data.metadata
                    this.Car1Gallery = CarGallery;
                    this.Car1Model = ModelData;
                    this.Car1Engine = EngineData;
                    this.Car1Safty = CarSafety;
                    this.Car1Size = Measurements;
                    this.Car1Multimedia = Multimedia;
                    this.Car1Comfort = Comfort;
                    this.Car1Hybrid = Hybrid;
                    this.IsHybrid = isHybrid;
                    this.Car1More = res.data.metadata.additional_equipment;
                    this.Car1MoreInclude = res.data.metadata.additional_equipment_b;
                    this.Car1Title = res.data.title.rendered;
                    this.Car1SmallText = res.data.metadata.smalltitle;
                    this.SingleImage = res.data.media_0_gallery[0]
                    this.CarPrice = res.data.metadata.leasing_price[0]
                    this.featured_url = res.data.featured_url
                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadCar ERR')
                });
            })

    };



};







export default new SingleCar();





