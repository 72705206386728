import React, { Component, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { withRouter, Link } from 'react-router-dom';

import readXlsxFile from 'read-excel-file'


import axios from 'axios';
import clientConfig from '../client-config';


@inject('login')
class HeaderWithSlider extends Component {


    constructor(props) {
        super(props);
        this.state = {
            data: null,
            // selectedFile: null,

        };
    }


    inputchange = (event) => {
        // this.setState({ selectedFile: event.target.files[0] })
        const selectedFile = event.target.files[0]
        console.log(selectedFile)
        let data = [{
            "name": "jayanth",
            "data": "scd",
            "abc": "sdef"
        }]
        XLSX.utils.json_to_sheet(data, 'out.xlsx');
        if (selectedFile) {
            let fileReader = new FileReader();
            fileReader.readAsBinaryString(selectedFile);
            fileReader.onload = (event) => {
                let data = event.target.result;
                let workbook = XLSX.read(data, { type: "binary" });

                let dataStr = []
                workbook.SheetNames.forEach(sheet => {
                    let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);

                    dataStr.push(rowObject)

                });
                console.log(dataStr[0])
                this.setState({ data: dataStr[0] })
            }
        }

    }


    TableData = (data) => {
        return (
            <div>{data}</div>
        )
    }


    // buttonClick = () => {
    //     const selectedFile = this.state.selectedFile
    //     console.log(selectedFile)
    //     let data = [{
    //         "name": "jayanth",
    //         "data": "scd",
    //         "abc": "sdef"
    //     }]
    //     XLSX.utils.json_to_sheet(data, 'out.xlsx');
    //     if (selectedFile) {
    //         let fileReader = new FileReader();
    //         fileReader.readAsBinaryString(selectedFile);
    //         fileReader.onload = (event) => {
    //             let data = event.target.result;
    //             let workbook = XLSX.read(data, { type: "binary" });

    //             let dataStr = []
    //             workbook.SheetNames.forEach(sheet => {
    //                 let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);

    //                 dataStr.push(rowObject)

    //             });
    //             console.log(dataStr[0])
    //             this.setState({ data: dataStr[0] })
    //         }
    //     }
    // }

    render() {

        const data = this.state.data

        const test = this.state.test
        let TableData = ''
        if (data) {
            TableData = data.map((person, index) => {
                return (
                    <>
                        <div>{person.name}</div>
                        <div>{person.id}</div>
                    </>
                )
            }
            );
        }



        return (

            <>

                <div class="conatiner mt-5">


                    <div class="row">


                        <div class="col-md-3"></div>
                        <div class="col-md-3">
                            <input onChange={this.inputchange.bind(this)} class="form-control" type="file" accept=".xls,.xlsx" />
                        </div>
                        {/* <button class="btn btn-primary" onClick={this.buttonClick}>Convert</button> */}

                        {TableData}


                    </div>


                </div>

            </>
        );

    }
}

export default withRouter(HeaderWithSlider);
