import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Slider from 'react-input-slider';

import { withRouter, Link } from 'react-router-dom';

import Select from 'react-styled-select';

import styles from './Header.module.css';


@inject("siteLinks", "siteGlobal")
@observer
class HeaderWithSlider extends Component {
    constructor() {
        super();
        this.state = {
            Image: '',
            selectedOption: null,
            HoverCar: false
        };
    }





    HoverCar = () => {
        const val = this.state.HoverCar
        this.setState({ HoverCar: !val })
    }



    ShowForm = () => {
        this.props.siteGlobal.ToggleIsOpen()
        this.props.siteGlobal.PopUpFomrShow()
    }
    PopUpFomrShowMobile = () => {
        this.props.siteGlobal.ToggleIsOpen()
        this.props.siteGlobal.PopUpFomrShowMobile()
    }


    render() {



        const TitleToshow = () => {
            switch (this.props.x) {

                case 1: return "עד 2500";
                case 2: return "2501-3000";
                case 3: return "3001-3500";
                case 4: return "3501-4000";
                case 5: return "4001-4500";
                case 6: return "מעל 4500";

                default: return "טווח שווי שימוש"
            }
        }

        // const options = [
        //     { value: 'chocolate', label: 'Chocolate' },
        //     { value: 'strawberry', label: 'Strawberry' },
        //     { value: 'vanilla', label: 'Vanilla' },
        // ];

        const PriceSilder = <div className={styles.PriceSlider}>
            <div className={styles.PriceRange}>{TitleToshow()}</div>
            <Slider
                styles={{
                    track: {
                        backgroundColor: 'white'
                    },
                    active: {
                        backgroundColor: '#0F102F'
                    },
                    thumb: {
                        // width: 50,
                        // height: 50
                    },
                    disabled: {
                        opacity: 0.5
                    }
                }}
                axis="x"
                xstep={1}
                xmin={0}
                xmax={6}
                x={this.props.x}
                className={styles.SliderPrice}
                // onChange={({ x }) => this.setState({ sliderx: x })}
                onChange={this.props.onChange}
            />
        </div>


        const SelectManufacturer = <div className={styles.SelectManufacturer}>
            <Select
                value={this.props.selectedOption}
                onChange={this.props.handleManufacturerChange}
                options={this.props.options}
                className={styles.SelectTeam}
                placeholder="בחר יצרן"
            />
        </div>
        const SideBySide = <Link to={this.props.SideBySideLink} className={styles.SideBySide}>{this.props.siteLinks.SideBySideIcon}</Link>


        let CarSelect = <img src={this.props.siteLinks.CarSelect} />
        this.state.HoverCar ? CarSelect = <img src={this.props.siteLinks.CarSelectHover} /> : CarSelect = <img src={this.props.siteLinks.CarSelect} />

        return (

            <>
                <div className={styles.MobileContainer}>
                    <div className={styles.MobileHeader}>
                        {/* <div className={styles.MobileFlexContainer}>
                            <div className={styles.LeftLogo2}>{this.props.siteLinks.RafaelHeaderLogo}</div>
                            <div className={styles.RightLogo2}>{this.props.siteLinks.CarShowLogo}</div>
                        </div> */}

                        {SelectManufacturer}
                        <div className={styles.PriceAndClear}>
                            <div className={styles.ClearFilter} onClick={this.props.ClearAll}>נקה סינון</div>

                            {PriceSilder}

                        </div>
                        <div className={styles.MobileSideBySideContainer}>
                            <div className={styles.MobileSideBySide}>
                                <div className={styles.SideBySideIcon}>{SideBySide}</div>
                                {/* לבחירת רכב */}
                                <div onClick={this.PopUpFomrShowMobile} onMouseOut={this.HoverCar} onMouseOver={this.HoverCar} className={styles.RightLogo}>{CarSelect}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.FlexContainer}>


                    <div className={styles.Container}>

                        <div className={styles.LeftLogo}><img src={this.props.siteLinks.RafaelHebBlue} /></div>
                        <div className={styles.FilteItems}>
                            {SideBySide}
                            <div className={styles.ClearFilter} onClick={this.props.ClearAll}>נקה סינון</div>
                            {PriceSilder}
                            {SelectManufacturer}
                        </div>

                        {/* לבחירת רכב */}
                        <div onClick={this.ShowForm} onMouseOut={this.HoverCar} onMouseOver={this.HoverCar} className={styles.RightLogo}>{CarSelect}</div>
                        {/* <div className={styles.RightLogo2}><img src={this.props.siteLinks.CarShowLogo} /> </div> */}


                    </div>
                    </div>
            </>
        );


    }
}

export default HeaderWithSlider;
